import React, { useState, useEffect } from "react";

//MetaDecorator
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { getGenricPage } from "../../api/api";
import parse from "html-react-parser";

function Eula() {
  const [eulaData, setEulaData] = useState("");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    document
      .getElementsByTagName("body")[0]
      .classList.add("terms-and-condition-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("terms-and-condition-page");
    };
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    setLoading(true);
    getGenricPage("eula")
      .then((resp) => {
        setLoading(false);
        if (resp.response.ok) {
          setEulaData(resp.json.content);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => controller.abort();
  }, []);

  return (
    <div className="p-grid p-fluid dashboard page-terms-and-conditions page-terms">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.eula} />
      <div className="p-col-12 p-lg-12">
        <div className="card card-w-title">
          {eulaData ? parse(eulaData) : !loading && "No any data available"}
        </div>
      </div>
    </div>
  );
}

export default Eula;
