import React, { useEffect } from 'react'

import store from './redux/store';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { SET_FAVOURITE_POSTS } from './redux/types';

import App from './App'

function AppWrapper() {

    useEffect(() => {
        if (localStorage.getItem('favouritePosts')) {
            store.dispatch({
                type: SET_FAVOURITE_POSTS,
                payload: JSON.parse(localStorage.getItem('favouritePosts'))
            })
        }
    }, [])

    return (
        <App></App>
    )
}


const mapStateToProps = state => ({
    favourite: state.favourite
})

export default withRouter(connect(mapStateToProps, {})(AppWrapper))
