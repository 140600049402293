import React from "react";
import { Form } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { doUnsubscribe } from "../../api/api";
import { toast } from "react-toastify";

function Unsubscribe(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);

	let searchParams = new URLSearchParams(props.location.search);
	let email = searchParams.get("email");
	let un_id = searchParams.get("unsubscribed_id");

	const _unsubscribe = () => {
		let requestJson = {
			email: email ? email : "",
			unsubscribed_id: un_id ? un_id : ""
		};
		doUnsubscribe(requestJson, ``)
			.then((res) => {
				if (res.response.ok) {
					toast.dark(<b style={{ width: "400px" }}>Successfully unsubscribed!</b>, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
						hideProgressBar: true,
						style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
					});
					props.history.push("/");
				} else {
					toast.dark(<b style={{ width: "400px" }}>Failed!</b>, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
						hideProgressBar: true,
						style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
					});
				}
			})
			.catch((err) => {
				toast.dark(<b style={{ width: "400px" }}>{err}</b>, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
					hideProgressBar: true,
					style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
				});
			});
	};

	return (
		<div className="unsubscribe">
			<span>Are you sure you want to unsubscribe ?</span>
			<div style={{ display: "flex" }}>
				<Form.Button
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "0px",
						backgroundColor: "#DB2828"
					}}
					primary
					onClick={_unsubscribe}
				>
					Yes, Unsubscribe
				</Form.Button>
				<Form.Button
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "0px",
						backgroundColor: "black"
					}}
					primary
					onClick={() => {
						props.history.push("/");
					}}
				>
					Cancel
				</Form.Button>
			</div>
		</div>
	);
}

export default withRouter(Unsubscribe);
