import { SET_AUTH_RANDOM_LINK_CATEGORIES, SET_CATEGORIES_ARR, SET_AUTH_CAT_MENU_LIST, UPDATE_SUBSCRIBED_CATEGORIES, SET_RANDOM_LINK_CATEGORIES } from "../types";

const initialState = {
	loading: false,
	categories: [],
	authCategories: [],
	subscribedCategories: [],
	randomLinkCategories: [],
	authRandomLinkCategories: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_CATEGORIES_ARR:
			let catIds = action.payload.map((data) => {
				return data.label !== "All Categories" ? data.term_id : null;
			});
			let withoutNull = catIds.filter((dat) => {
				return dat !== null;
			});
			return {
				...state,
				categories: action.payload,
				randomLinkCategories: withoutNull,
				loading: false
			};
		case SET_AUTH_RANDOM_LINK_CATEGORIES:
			return { ...state, authRandomLinkCategories: action.payload };
		case SET_RANDOM_LINK_CATEGORIES:
			return { ...state, randomLinkCategories: action.payload };
		case UPDATE_SUBSCRIBED_CATEGORIES:
			return {
				...state,
				subscribedCategories: action.payload
			};
		case SET_AUTH_CAT_MENU_LIST:
			let catIds2 = action.payload.map((data) => {
				return data.label !== "All Categories" ? data.term_id : null;
			});
			let withoutNull2 = catIds2.filter((dat) => {
				return dat !== null;
			});
			return {
				...state,
				authCategories: action.payload,
				subscribedCategories: action.payload,
				authRandomLinkCategories: withoutNull2
			};

		default:
			return state;
	}
};
