import React, { useRef } from "react";
import { Modal, Icon, Popup, Input, Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { popWindow } from "../../utils/manageShare";
import TagsBar from "../../layout/TagsBar/TagsBar";
import { withRouter } from "react-router-dom";
import PostCard from "../../components/PostCard/PostCard";
import store from "../../redux/store";
import { getSinglePost } from "../../api/api";
import { RESET_TAGS, UPDATE_TAGS } from "../../redux/types";
import { IoClose } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { isEmpty } from "../../validation/validation";
import Loader from "../../components/Loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";

function PostModal(props) {
	const [postData, setPostData] = React.useState([]);
	const darkMode = useSelector((state) => state.settings.darkMode);
	const iconMode = useSelector((state) => state.settings.iconMode);
	const categories = useSelector((state) => state.categories.categories);
	const [allPosts, setAllPosts] = React.useState([]);
	const [shareBlockShowing, setShareBlockShowing] = React.useState(false);
	const [linkCopied, setLinkCopied] = React.useState(false);
	const [seoData, setSeoData] = React.useState({});
	const [modalTags, setModalTags] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	let isMobile = window.innerWidth <= 992;

	const [touchStart, setTouchStart] = React.useState(0);
	const [touchEnd, setTouchEnd] = React.useState(0);

	const handleTouchStart = (e) => {
		setTouchStart(e.targetTouches[0].clientX);
	};

	const handleTouchMove = (e) => {
		setTouchEnd(e.targetTouches[0].clientX);
	};

	const prevRef = useRef(null);
	const nextRef = useRef(null);

	React.useEffect(() => {
		setPostData(props.postData);
		setAllPosts(props.postData.allPosts);
	}, [props.postData]);

	const modalRef = useRef(null);
	let postTitle = props.postData && props.postData.categories && props.postData.categories[0] && props.postData.categories[0].name ? props.postData.categories[0].name : "";
	let postCategorySlug = "";
	if (props.postData && props.postData.categories && props.postData.categories[0]) {
		let postCategory = categories.filter((cat, ind) => cat.term_id === props.postData.categories[0]);
		if (!isEmpty(postCategory)) {
			postCategorySlug = postCategory[0].slug;
			postTitle = postCategory[0].name;
		}
	}

	const _nextPost = () => {
		if (!isEmpty(allPosts) && !isEmpty(postData)) {
			let currentPost = postData;
			let currentPostIndex = allPosts.findIndex((ap) => ap.ID.toString() === currentPost.ID.toString());
			let nextPost = allPosts[currentPostIndex + 1];
			if (nextPost) {
				setPostData(nextPost);
			}
		}
	};

	const _prevPost = () => {
		if (!isEmpty(allPosts) && !isEmpty(postData)) {
			let currentPost = postData;
			let currentPostIndex = allPosts.findIndex((ap) => ap.ID.toString() === currentPost.ID.toString());
			let prevPost = allPosts[currentPostIndex - 1];
			if (prevPost) {
				setPostData(prevPost);
			}
		}
	};

	const handleTouchEnd = () => {
		if (touchStart - touchEnd > 150) {
			_nextPost();
		}

		if (touchStart - touchEnd < -150) {
			_prevPost();
		}
	};

	React.useEffect(() => {
		if (props.open) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "unset";
		}
	}, [props.open]);

	React.useEffect(() => {
		(async () => {
			// store.dispatch({ type: RESET_TAGS, payload: "" });
			setLoading(true);
			setModalTags([]);
			getSinglePost(postData.ID)
				.then((res) => {
					setLoading(false);
					setSeoData(res.json.seo);
					setModalTags(postData.tags);
					setPostData(res.json);
					document.getElementById("app-hue-bar").style.background = res.json.category_color;
				})
				.catch((err) => {
					console.log(err);
				});
		})();
	}, [postData.ID]);

	// useEffect(()=>{
	// document.addEventListener("swiped", function(e) {
	// 	console.log("swipe event A", e.target); // the element that was swiped
	// 	console.log("swipe event B", e.detail.dir); // swiped direction
	// });
	// })

	if (document.getElementById("main-swiper-modal")) {
		document.getElementById("main-swiper-modal").addEventListener("swiped", function(e) {
			console.log(e); // the element that was swiped
		});
	}

	const _pop = (type) => {
		let ID = 31;
		popWindow(type, "Test Title", `https://${window.location.host}/link?L=${ID}`);
	};

	const isTagsBarFixedStatus = props.location.pathname === "/" || props.location.pathname === "/favorites" || props.location.pathname === "/submit-link";

	React.useEffect(() => {
		// if (modalRef) {
		props.setModalRef(modalRef);
		// }
	}, []);

	const _handleKeyboardPress = (e) => {
		if (e.keyCode === 37) {
			_prevPost();
		} else if (e.keyCode === 39) {
			_nextPost();
		} else {
			return;
		}
	};

	const _onShareClick = () => {
		if (navigator.share || navigator.canShare) {
			try {
				navigator
					.share({ title: "PikiLinks", url: `https://${window.location.host}/link?L=${postData.ID}` })
					.then(() => console.log("Hooray! Your content was shared to tha world"))
					.catch((err) => {
						// alert("navigator.share catch", err);
					});
			} catch (error) {
				console.log(`Oops! Link couldnt be shared: ${error}`);
			}
		} else {
			setShareBlockShowing(true);
		}
	};

	React.useEffect(() => {
		document.addEventListener("keydown", _handleKeyboardPress);
		return () => document.removeEventListener("keydown", _handleKeyboardPress);
	}, [_handleKeyboardPress]);

	let newPath2 = props.history.location.pathname.split("/");
	let fin2 = "/" + newPath2[1];

	return (
		<>
			<Modal
				basic
				size="fullscreen"
				onClose={() => {
					props.onClose();
				}}
				ref={modalRef}
				onOpen={props.onClose}
				open={props.open}
				id="main-swiper-modal"
				className="main-swiper-modal"
			>
				{/* darkMode ? "post-modal-dark" : "post-modal-light" */}
				<div id="swipe-test" className={darkMode ? "post-modal-slider-dark" : "post-modal-slider-light"}>
					{!isMobile && (
						<div className="left-swipe">
							<div onClick={() => props.onClose()} style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}></div>
							{!isEmpty(allPosts) && <IoIosArrowBack onClick={_prevPost} style={{ fontSize: "25px", position: "absolute", zIndex: 100, cursor: "pointer" }} />}
						</div>
					)}
					{!isMobile ? (
						<div className="swiper-body" style={{ flex: 0.4 }}>
							<div className={darkMode ? "post-modal-head-dark" : "post-modal-head-light"} style={{ display: "flex", flex: 1 }}>
								<div>
									<span
										onClick={() => {
											props.onClose();
											props.history.push(`/category/${postCategorySlug}`);
										}}
										style={{ cursor: "pointer" }}
									>
										{postTitle}
									</span>
								</div>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Popup
										content={
											<span className="hideShareOption list-hideShareOption " style={{ backgroundColor: darkMode ? "#2E3035" : "#EDF0F5", color: "#8A8A8A" }}>
												<span>
													<div>
														<span className="icon-con-social" onClick={() => _pop("linkedin")}>
															<i className="flaticon-linkedin"></i> &nbsp;
															<b>LinkedIn</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("reddit")}>
															<i className="flaticon-reddit-1"></i> &nbsp;
															<b>Reddit</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("twitter")}>
															<i className="flaticon-twitter"></i> &nbsp;
															<b>Twitter</b>
														</span>
													</div>
													<div>
														<span className="icon-con-social" onClick={() => _pop("facebook")}>
															<i className="flaticon-facebook"></i> &nbsp;
															<b>Facebook</b>
														</span>

														<span className="icon-con-social" onClick={() => _pop("messenger")}>
															<i className="flaticon-messenger"></i> &nbsp;
															<b>Messenger</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("gmail")}>
															<i className="flaticon-email-1"></i> &nbsp;
															<b>Email</b>
														</span>
													</div>
												</span>
												<span
													className="icon-con-social"
													onClick={() => {
														_pop("link");
														setLinkCopied(true);
													}}
												>
													<div>
														<Input
															size="mini"
															action={{
																labelPosition: "right",
																icon: "copy",
																content: linkCopied ? "Copied" : "Copy Link"
															}}
															defaultValue={`https://${window.location.host}/link?L=${postData.ID}`}
														/>
													</div>
												</span>
											</span>
										}
										on="click"
										className="tags-popup share-popup"
										onClose={() => {
											setShareBlockShowing(false);
											setLinkCopied(false);
										}}
										onOpen={() => _onShareClick()}
										open={shareBlockShowing}
										basic
										trigger={
											<Label
												style={{
													marginRight: "8px",
													cursor: "pointer",
													color: darkMode ? "gray" : "black",
													maxWidth: "100px",
													minWidth: "100px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}
											>
												<Icon name="share alternate" />
												Share
											</Label>
										}
									/>
								</div>
								<IoClose onClick={() => props.onClose()} style={{ color: "#8A8A8A", cursor: "pointer", fontSize: "30px", padding: "5px" }} />
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
									borderRadius: "0px",
									padding: "5px",
									display: "flex",
									width: "100%",
									overflowX: "hidden",
									flexDirection: "column"
								}}
							>
								<div>
									<TagsBar modalTags={modalTags || []} postsModal={true} darkMode={darkMode} iconMode={iconMode} staticMenuInactive={true} isTagsBarFixedStatus={isTagsBarFixedStatus} />
								</div>
								{!loading && (
									<div
										className={darkMode ? "post-modal-body-dark" : "post-modal-body-light"}
										style={{ marginTop: isEmpty(postData.link_types) ? "0px" : fin2 === "/tags" ? "0px" : "0px", position: "relative" }}
									>
										<PostCard forModal={true} forSingle={true} upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === postData.ID)} postData={postData} />
									</div>
								)}
								{loading && (
									<div className={darkMode ? "post-modal-body-dark" : "post-modal-body-light"} style={{ marginTop: isEmpty(postData.link_types) ? "0px" : fin2 === "/tags" ? "0px" : "-20px" }}>
										<Loader />
									</div>
								)}
							</div>
						</div>
					) : (
						<div className="swiper-body" style={{ flex: 1 }}>
							<div className={darkMode ? "post-modal-head-dark" : "post-modal-head-light"} style={{ display: "flex", flex: 1 }}>
								<div>
									<span>{postTitle}</span>
								</div>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									<Popup
										content={
											<span className="hideShareOption list-hideShareOption " style={{ backgroundColor: darkMode ? "#3c3c3c" : "#EDF0F5", color: "#8A8A8A" }}>
												<span>
													<div>
														<span className="icon-con-social" onClick={() => _pop("linkedin")}>
															<i className="flaticon-linkedin"></i> &nbsp;
															<b>LinkedIn</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("reddit")}>
															<i className="flaticon-reddit-1"></i> &nbsp;
															<b>Reddit</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("twitter")}>
															<i className="flaticon-twitter"></i> &nbsp;
															<b>Twitter</b>
														</span>
													</div>
													<div>
														<span className="icon-con-social" onClick={() => _pop("facebook")}>
															<i className="flaticon-facebook"></i> &nbsp;
															<b>Facebook</b>
														</span>

														<span className="icon-con-social" onClick={() => _pop("messenger")}>
															<i className="flaticon-messenger"></i> &nbsp;
															<b>Messenger</b>
														</span>
														<span className="icon-con-social" onClick={() => _pop("gmail")}>
															<i className="flaticon-email-1"></i> &nbsp;
															<b>Email</b>
														</span>
													</div>
												</span>
												<span
													className="icon-con-social"
													onClick={() => {
														_pop("link");
														setLinkCopied(true);
													}}
												>
													<div>
														<Input
															size="mini"
															action={{
																labelPosition: "right",
																icon: "copy",
																content: linkCopied ? "Copied" : "Copy Link"
															}}
															defaultValue={`https://${window.location.host}/link?L=${postData.ID}`}
														/>
													</div>
												</span>
											</span>
										}
										on="click"
										className="tags-popup share-popup"
										onClose={() => {
											setShareBlockShowing(false);
											setLinkCopied(false);
										}}
										onOpen={() => _onShareClick()}
										open={shareBlockShowing}
										basic
										trigger={
											<Label
												style={{
													marginRight: "8px",
													cursor: "pointer",
													color: darkMode ? "gray" : "black",
													maxWidth: "100px",
													minWidth: "100px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center"
												}}
											>
												<Icon name="share alternate" />
												Share
											</Label>
										}
									/>
								</div>
								<IoClose onClick={() => props.onClose()} style={{ color: "#8A8A8A", cursor: "pointer", fontSize: "30px", padding: "5px" }} />
							</div>
							<div
								onTouchMove={handleTouchMove}
								onTouchStart={handleTouchStart}
								onTouchEnd={handleTouchEnd}
								style={{
									display: "flex",
									justifyContent: "space-between",
									backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
									borderRadius: "0px",
									padding: "5px",
									display: "flex",
									width: "100%",
									overflowX: "hidden",
									flexDirection: "column"
								}}
							>
								<div>
									<TagsBar modalTags={modalTags || []} postsModal={true} darkMode={darkMode} iconMode={iconMode} staticMenuInactive={true} isTagsBarFixedStatus={isTagsBarFixedStatus} />
								</div>
								{!isEmpty(postData.link_types) && !loading ? (
									<div
										className="postLinkTypes"
										style={{
											marginTop: fin2 === "/tags" ? "15px" : "70px",
											paddingBottom: "20px",
											paddingTop: "10px",
											marginTop: "0px",
											minHeight: "50px",
											lineHeight: "20px",
											display: "flex",
											flexWrap: "wrap",
											width: "100%"
										}}
									>
										{postData.link_types.map((data, ind) => {
											return <span>{data.name}</span>;
										})}
									</div>
								) : null}
								{!loading && (
									<div className={darkMode ? "post-modal-body-dark" : "post-modal-body-light"} style={{ marginTop: isEmpty(postData.link_types) ? "0px" : fin2 === "/tags" ? "0px" : "0px" }}>
										<PostCard forModal={true} forSingle={true} upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === postData.ID)} postData={postData} />
									</div>
								)}
								{loading && (
									<div className={darkMode ? "post-modal-body-dark" : "post-modal-body-light"} style={{ marginTop: isEmpty(postData.link_types) ? "0px" : fin2 === "/tags" ? "0px" : "-20px" }}>
										<Loader />
									</div>
								)}
							</div>
						</div>
					)}
					{/* )} )} */}
					{!isMobile && (
						<div className="right-swipe">
							<div onClick={() => props.onClose()} style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}></div>
							{!isEmpty(allPosts) && <IoIosArrowForward onClick={_nextPost} style={{ fontSize: "25px", position: "absolute", zIndex: 100, cursor: "pointer" }} />}
						</div>
					)}
					{/* <Modal.Header className={darkMode ? "post-modal-head-dark" : "post-modal-head-light"} style={{ display: "flex", flex: 1 }}>
						<div>
							<span>{postTitle}</span>
						</div>
						<div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
							<Popup
								content={
									<span className="hideShareOption list-hideShareOption " style={{ backgroundColor: darkMode ? "#2E3035" : "#EDF0F5", color: "#8A8A8A" }}>
										<span>
											<div>
												<span className="icon-con-social" onClick={() => _pop("linkedin")}>
													<i className="flaticon-linkedin"></i> &nbsp;
													<b>LinkedIn</b>
												</span>
												<span className="icon-con-social" onClick={() => _pop("reddit")}>
													<i className="flaticon-reddit-1"></i> &nbsp;
													<b>Reddit</b>
												</span>
												<span className="icon-con-social" onClick={() => _pop("twitter")}>
													<i className="flaticon-twitter"></i> &nbsp;
													<b>Twitter</b>
												</span>
											</div>
											<div>
												<span className="icon-con-social" onClick={() => _pop("facebook")}>
													<i className="flaticon-facebook"></i> &nbsp;
													<b>Facebook</b>
												</span>

												<span className="icon-con-social" onClick={() => _pop("messenger")}>
													<i className="flaticon-messenger"></i> &nbsp;
													<b>Messenger</b>
												</span>
												<span className="icon-con-social" onClick={() => _pop("gmail")}>
													<i className="flaticon-email-1"></i> &nbsp;
													<b>Email</b>
												</span>
											</div>
										</span>
										<span
											className="icon-con-social"
											onClick={() => {
												_pop("link");
												setLinkCopied(true);
											}}
										>
											<div>
												<Input
													size="mini"
													action={{
														labelPosition: "right",
														icon: "copy",
														content: linkCopied ? "Copied" : "Copy Link"
													}}
													defaultValue={`https://${window.location.host}/link?L=${postData.ID}`}
												/>
											</div>
										</span>
									</span>
								}
								on="click"
								className="tags-popup share-popup"
								onClose={() => {
									setShareBlockShowing(false);
									setLinkCopied(false);
								}}
								onOpen={() => setShareBlockShowing(true)}
								open={shareBlockShowing}
								basic
								trigger={
									<Label style={{ marginRight: "8px", cursor: "pointer", color: darkMode ? "gray" : "black" }}>
										<Icon name="share alternate" />
										Share
									</Label>
								}
							/>
						</div>
						<IoClose onClick={() => props.onClose()} style={{ color: "#8A8A8A", cursor: "pointer" }} />
					</Modal.Header>
					<Modal.Content
						style={{
							display: "flex",
							justifyContent: "space-between",
							backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
							borderRadius: "0px",
							padding: "5px",
							display: "flex",
							flexDirection: "column"
						}}
					>
						<div>
							<TagsBar modalTags={modalTags || []} postsModal={true} darkMode={darkMode} iconMode={iconMode} staticMenuInactive={true} isTagsBarFixedStatus={isTagsBarFixedStatus} />
						</div>
						{postData.link_types && !isEmpty(postData.link_types) ? (
							<div className="postLinkTypes" style={{ marginTop: fin2 === "/tags" ? "15px" : "70px", paddingBottom: "20px", minHeight: "50px", display: "flex", flexWrap: "wrap", width: "100%" }}>
								{postData.link_types.map((data, ind) => {
									return <span>{data.name}</span>;
								})}
							</div>
						) : null}
						<div className={darkMode ? "post-modal-body-dark" : "post-modal-body-light"} style={{ marginTop: !isEmpty(postData.link_types) ? "-20px" : fin2 === "/tags" ? "0px" : "50px" }}>
							<PostCard forModal={true} forSingle={true} upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === postData.ID)} postData={postData} />
						</div>
					</Modal.Content> */}
				</div>
			</Modal>
		</>
	);
}

export default withRouter(PostModal);
