export const EmailValidation = (email) => {
    if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    ) {
        return false
    } else {
        return true
    }
}

export const PasswordValidation = (password) => {
    if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{11,}$/.test(password)
    ) {
        return false
    } else {
        return true
    }
}