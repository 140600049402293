const isEmpty = (data) => {
	return data === undefined || data === null || (typeof data === "object" && Object.keys(data).length === 0) || (typeof data === "string" && data.trim().length === 0);
};

const validateAddPostInputs = (addPostData) => {
	const errors = {};
	if (isEmpty(addPostData.email)) {
		errors.email = "Email cannot be empty!";
	}
	if (addPostData.email && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(addPostData.email)) {
		errors.email = "Email not valid!";
	}
	if (isEmpty(addPostData.title)) {
		errors.title = "Post title cannot be empty!";
	}

	if (addPostData.title.length > 200) {
		errors.title = "Post title cannot be longer than 200 characters!";
	}
	if (isEmpty(addPostData.url)) {
		errors.url = "URL cannot be empty!";
	}

	if (isEmpty(addPostData.category)) {
		errors.category = "Select a category!";
	}

	if (addPostData.tags.length > 5) {
		errors.tags = "You can only add up to 5 tags!";
	}

	//Will be valid if the errors are empty
	const isValid = isEmpty(errors);
	return { isValid, errors };
};

const validateUpdatePostInputs = (addPostData) => {
	const errors = {};
	if (isEmpty(addPostData.email)) {
		errors.email = "Email cannot be empty!";
	}
	if (addPostData.email && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(addPostData.email)) {
		errors.email = "Email not valid!";
	}
	if (isEmpty(addPostData.title)) {
		errors.title = "Post title cannot be empty!";
	}

	if (addPostData.title.length > 200) {
		errors.title = "Post title cannot be longer than 200 characters!";
	}
	if (isEmpty(addPostData.url)) {
		errors.url = "URL cannot be empty!";
	}

	if (isEmpty(addPostData.category)) {
		errors.category = "Select a category!";
	}

	if (addPostData.tags.length > 5) {
		errors.tags = "You can only add up to 5 tags!";
	}

	//Will be valid if the errors are empty
	const isValid = isEmpty(errors);
	return { isValid, errors };
};

const validateFeedbackInputs = (feedbackData) => {
	const errors = {};
	if (isEmpty(feedbackData.feedback)) {
		errors.feedback = "Feedback message cannot be empty!";
	}

	//Will be valid if the errors are empty
	const isValid = isEmpty(errors);
	return { isValid, errors };
};

const validateSubscribeInputs = (subscribeData) => {
	const errors = {};

	if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(subscribeData.email)) {
		errors.email = "Email not valid!";
	}

	//Will be valid if the errors are empty
	const isValid = isEmpty(errors);
	return { isValid, errors };
};

export { isEmpty, validateAddPostInputs, validateFeedbackInputs, validateSubscribeInputs, validateUpdatePostInputs };
