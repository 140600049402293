import React from "react";
import Error from "../../layout/TopBar/ErrorMessage";
import { Icon, Popup } from "semantic-ui-react";
import store from "../../redux/store";

const UsernameField = ({ label, edit, close, usernameError, usernameErrorMessage, usernameValue, fieldChange, saveUsername }) => {
	const darkMode = store.getState().settings.darkMode;

	return (
		<div className="textfield">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<label style={{ color: darkMode ? "gray" : "black" }} for="email" className="title">
					{label}
				</label>
				{usernameError ? <Error message={usernameErrorMessage} /> : null}
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<input type="text" id="username" value={usernameValue} className={usernameError ? "error" : null} onChange={() => fieldChange("username")} disabled />

				<div
					style={{
						width: 53,
						paddingTop: 5,
						textAlign: "center"
					}}
				>
					{saveUsername ? (
						<Popup
							trigger={
								<span>
									<Icon name="check" onClick={() => close("username", document.getElementById("username").value)} />
								</span>
							}
							content="save"
							inverted
						/>
					) : (
						<Popup
							trigger={
								<span>
									<Icon name="edit" onClick={() => edit("username")} />
								</span>
							}
							content="edit username"
							inverted
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default UsernameField;
