import React, { useState, useEffect } from "react";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import AskModal from "./AskModal";
import { connect } from "react-redux";
import isEmpty from "../../utils/isEmpty";
import { getFaqsData } from "../../api/api";
import Loader from "../../components/Loader/Loader";

const faqData = [
	{
		title: "Title#1",
		id: 1,
		questions: [
			{
				id: 1,
				title: "Question1A ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 2,
				title: "Question1B ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 3,
				title: "Question1C ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 4,
				title: "Question1D ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			}
		]
	},
	{
		title: "Title#2",
		id: 2,
		questions: [
			{
				id: 1,
				title: "Question2A ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 2,
				title: "Question2B ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 3,
				title: "Question2C ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			},
			{
				id: 4,
				title: "Question2D ?",
				ans:
					"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit,"
			}
		]
	}
];

function Faq(props) {
	const [count, setCount] = useState(0);
	const [activeQsn, setActiveQsn] = useState({ title: null, qsn: null });
	const [askModalStatus, setAskModalStatus] = useState(false);
	const [faqDataList, setFaqDataList] = useState([]);
	const [loading, setLoading] = useState(false);
	let isMobile = window.innerWidth <= 992;

	React.useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("about-page");
		document.getElementsByTagName("body")[0].removeAttribute("id");

		return () => {
			document.getElementsByTagName("body")[0].classList.remove("about-page");
		};
	}, []);

	const _getFaqs = (signal) => {
		setLoading(true);
		getFaqsData("", signal)
			.then((res) => {
				setLoading(false);
				if (res.response.ok) {
					setFaqDataList(res.json);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		const controller = new AbortController();

		_getFaqs(controller.signal);

		return () => controller.abort();
	}, []);

	const _click = async (e) => {
		await setCount(() => count + 1);
		await setCount(() => count + 1);
	};

	const _openQuestion = (titleId, qsnId) => {
		if (activeQsn.title === titleId && activeQsn.qsn === qsnId) {
			setActiveQsn({ title: null, qsn: null });
			return;
		}
		setActiveQsn({ title: titleId, qsn: qsnId });
	};
	const _askModel = () => {
		setAskModalStatus(true);
	};

	const _closeAskModal = () => {
		setAskModalStatus(false);
	};

	return (
		<div className="p-grid p-fluid dashboard page-about">
			<MetaDecorator metaDataPerRoute={metaDecoratorData.faq} />
			<div className="p-col-12 p-lg-12">
				<div className="card card-w-title">
					<div style={{ display: "flex", width: "100%", alignItems: "center", borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "lightgray", paddingBottom: "0px" }}>
						<div style={{ display: "flex", flex: 0.8 }}>
							<h1 style={{ borderStyle: "none", fontSize: "18px" }}>Frequently Asked Questions</h1>
						</div>
						{isEmpty(props.user) ? null : (
							<div style={{ display: "flex", flex: 0.2, justifyContent: "flex-end" }}>
								<span onClick={_askModel} style={{ paddingLeft: "10px", cursor: "pointer" }}>
									Ask?
								</span>
							</div>
						)}
					</div>
					<br />
					{!isEmpty(faqDataList) &&
						!loading &&
						faqDataList.map((data, index) => {
							return (
								<>
									<br />
									<div key={index} style={{ display: "flex", flexDirection: "column", alignItems: isMobile ? "flex-start" : "center", justifyContent: "center", marginTop: "10px" }}>
										<div style={{ width: isMobile ? "90%" : "60%", display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}>
											<h3>{data.title}</h3>
											<br />
											{data.faqs.map((qsn, ind) => {
												return (
													<>
														<div key={ind} style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
															<div
																onClick={() => {
																	_openQuestion(data.id, qsn.id);
																}}
																style={{ display: "flex", cursor: "pointer" }}
															>
																{activeQsn.title === data.id && activeQsn.qsn === qsn.id ? <IoIosArrowDown style={{ fontSize: "16px" }} /> : <IoIosArrowForward style={{ fontSize: "16px" }} />}
																<span style={{ marginLeft: "10px", fontSize: "15px" }}>{qsn.question}</span>
															</div>
															{activeQsn.title === data.id && activeQsn.qsn === qsn.id ? (
																<div style={{ padding: "15px 0px 10px 30px" }}>
																	<span>{qsn.answer}</span>
																</div>
															) : null}
														</div>
													</>
												);
											})}
										</div>
									</div>
								</>
							);
						})}
					{loading && <Loader />}
				</div>
			</div>
			<AskModal open={askModalStatus} onClose={() => _closeAskModal()} />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	};
};

export default connect(mapStateToProps)(Faq);
