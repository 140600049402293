import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import SlidingMenu from "../../components/SlidingMenu";

function AccountTagsBar(props) {
	const [activeRoute, setActiveRoute] = React.useState("");
	const darkMode = useSelector((state) => state.settings.darkMode);

	const Items = [
		{
			id:0,
			name: "ACCOUNT",
			href: "/account"
		  },
		  {
			id:1,
			name: "SUBSCRIPTION",
			href: "/subscription"
		  },
		  {
			id:2,
			name: "ACTIVITY",
			href: "/submissions"
		  }
	]

	React.useEffect(() => {
		setActiveRoute(props.pathname);
	}, [props.pathname]);

	return (
		<div className={`tags-bar ${window.innerWidth > 1024 ? (props.iconMode ? "DesktopTagsBar TagsBarOnIconMode" : "DesktopTagsBar") : "MobileTagsBar"}`}>
			<div style={{ display: "flex", height: "100%", display: "flex", alignItems: "center",width:'100%' }}>
			<SlidingMenu items={Items} />
			</div>
			{/* <div style={{ display: "flex", height: "100%", display: "flex", alignItems: "center" }}>
				<div style={{ padding: "15px" }}>
					<NavLink className={darkMode ? "top-bar-link-dark" : "top-bar-link-light"} to="/account">
						<span className={`page-title ${activeRoute === "/account" ? "top-bar-active" : ""}`} style={{ fontWeight: 600, fontSize: "13px" }}>
							ACCOUNT
						</span>
					</NavLink>
				</div>
				<div style={{ padding: "15px" }}>
					<NavLink className={darkMode ? "top-bar-link-dark" : "top-bar-link-light"} to="/subscription">
						<span className={`page-title ${activeRoute === "/subscription" ? "top-bar-active" : ""}`} style={{ fontWeight: 600, fontSize: "13px" }}>
							SUBSCRIPTION
						</span>
					</NavLink>
				</div>
				<div style={{ padding: "15px" }}>
					<NavLink className={darkMode ? "top-bar-link-dark" : "top-bar-link-light"} to="/submissions">
						<span className={`page-title ${activeRoute === "/submissions" ? "top-bar-active" : ""}`} style={{ fontWeight: 600, fontSize: "13px" }}>
							SUBMISSIONS
						</span>
					</NavLink>
				</div>
			</div> */}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		tagsByIconPosts: state.world.tagsByIconPosts
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(AccountTagsBar));
