import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { IconButton } from "@material-ui/core";
import { BsThreeDots } from "react-icons/bs";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Slider from "@material-ui/core/Slider";
import { CgMenuGridR } from "react-icons/cg";
import { HiViewList } from "react-icons/hi";
import store from "../../redux/store";
import { Dropdown, Label, Popup } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { SET_UNCHECKED_LINKS } from "../../redux/types";
import { BiChevronRight } from "react-icons/bi";
import { MdTune } from "react-icons/md";
import { FiCheck } from "react-icons/fi";
import { GiCheckMark } from "react-icons/gi";
import PropTypes from "prop-types";
import _ from "lodash";
import isEmpty from "../../utils/isEmpty";
import Glass from "../../components/Glass";

const Filters = [
  {
    label: "Web",
    id: 0,
    submenu: [
      { label: "Online", id: 0, checked: true },
      { label: "SaaS", id: 1, checked: false },
      { label: "DAPP", id: 2, checked: true },
    ],
  },
  {
    label: "Browser",
    id: 1,
    submenu: [
      { label: "Chrome", id: 0, checked: false },
      { label: "Brave", id: 1, checked: true },
      { label: "Opera", id: 2, checked: false },
      { label: "Firefox", id: 3, checked: false },
    ],
  },
  {
    label: "Desktop",
    id: 2,
    submenu: [
      { label: "Window", id: 0, checked: true },
      { label: "Mac", id: 1, checked: true },
      { label: "Linux", id: 2, checked: true },
    ],
  },
  {
    label: "Mobile",
    id: 3,
    submenu: [
      { label: "Android", id: 0, checked: true },
      { label: "iPhone", id: 1, checked: true },
      { label: "Windows Phone", id: 2, checked: false },
    ],
  },
  {
    label: "Wearable",
    id: 4,
    submenu: [
      { label: "Apple Watch", id: 0, checked: false },
      { label: "Android Wear", id: 1, checked: false },
    ],
  },
];

function FilterBar(props) {
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [filterData, setFilterData] = React.useState([...Filters]);
  let newPath = props.history.location.pathname.split("/");
  let fin = "/" + newPath[1];

  const isFilterBarFixed = props.location.pathname === "";
  // props.location.pathname === "/" || props.location.pathname === "/favorites";
  // || fin === "/category" || fin === "/tags";
  const excludeRoutes = [
    "/account",
    "/info",
    "/subscription",
    "/submissions",
    "/privacy",
    "/terms",
    "/faq",
    "/platforms",
    "/lists",
    "/submit-link",
    "/how-to",
    "/disclaimer",
    "/cookies",
    "/eula",
    "/use",
  ];
  // const excludedRoutes = ["/terms", "/privacy", "/send-feedback", "/add-post", "/lists", "/subscription", "/account", "/about", "/add-link", "/info", "/faq", "/platforms"];
  const isMobile = window.innerWidth < 450;

  if (excludeRoutes.includes(props.location.pathname)) {
    return null;
  }

  const _filterChange = (parentId, data, isActive) => {
    let unchecked = [];
    if (isActive) {
      unchecked = [...props.unCheckedLinks, data.term_id];
    } else {
      unchecked = [...props.unCheckedLinks].filter(
        (d, i) => d !== data.term_id
      );
    }
    store.dispatch({ type: SET_UNCHECKED_LINKS, payload: unchecked });
  };

  const _filterDropdown = (parentId, data, index, darkMode) => {
    return (
      <div
        style={{
          backgroundColor: darkMode
            ? "rgba(60,60,60,1)"
            : "rgba(237, 240, 245,1)",
          padding: "10px 0px 0px 0px",
          overflowY: "scroll",
          paddingTop: [..._.values(data.children)].length > 4 ? "10px" : "10px",
          paddingBottom:
            [..._.values(data.children)].length > 4 ? "10px" : "10px",
          maxHeight: "200px",
          overflowY:
            _.values(data.children) && _.values(data.children).length < 5
              ? "hidden"
              : "scroll",
        }}
        className={`${darkMode ? "blurred-box" : "blurred-box-light"}`}
      >
        <ul
          style={{
            listStyle: "none",
            color: "#8A8A8A",
            display: "flex",
            margin: "0px",
            alignItems: "space-between",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "0px",
            width: "100%",
            height: "100%",
            zIndex: 10000,
          }}
        >
          {[..._.values(data.children)].map((sub, ind) => {
            let isFilterActive = !props.unCheckedLinks.find(
              (li, ind) => li === sub.term_id
            );

            return (
              <li
                key={ind}
                onClick={() => _filterChange(parentId, sub, isFilterActive)}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "space-between",
                  justifyContent: "space-between",
                  margin: "13px",
                  cursor: "pointer",
                  opacity: 1,
                  zIndex: 10000,
                  backgroundColor: "transparent",
                  // borderStyle: "solid"
                }}
              >
                <span style={{ maxWidth: "100px", display: "flex" }}>
                  {sub.url ? (
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                      src={sub.url}
                    />
                  ) : (
                    ""
                  )}
                  {sub.name}
                </span>
                {isFilterActive ? (
                  <GiCheckMark
                    style={{
                      marginLeft: "30px",
                      fontSize: "15px",
                      color: "#8A8A8A",
                    }}
                  />
                ) : null}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div
      className={`filter-bar ${isFilterBarFixed ? "fixed" : ""} ${
        isMobile ? "remove-padding" : ""
      } ${
        isFilterBarFixed && window.innerWidth > 1024
          ? props.iconMode
            ? "DesktopFilterBar FilterBarOnIconMode"
            : "DesktopFilterBar"
          : "MobileFilterBar"
      }`}
      style={{ zIndex: 1 }}
    >
      <div
        style={{
          display: "flex",
          height: "115px",
          width: "100%",
          alignItems: "center",
          paddingLeft: "17px",
        }}
      >
        <ScrollMenu
          arrowLeft={
            !isMobile ? (
              <i className={`glyph-icon flaticon-left-arrow-1`}></i>
            ) : undefined
          }
          arrowRight={
            !isMobile ? (
              <i className={`glyph-icon flaticon-right-arrow-1`}></i>
            ) : undefined
          }
          alignCenter={false}
          dragging={true}
          data={[...props.linkTypes].map((data, index) => {
            return (
              <div
                className="filter-bar-comp"
                style={{ marginLeft: index === 0 ? "0px" : "10px" }}
                key={index}
              >
                <Popup
                  style={{
                    padding: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderStyle: "none",
                    marginTop: "20px",
                    background: "transparent",
                  }}
                  className={`${
                    darkMode ? "popupAddonsDark" : "popupAddonsLight"
                  }`}
                  position="bottom center"
                  content={() =>
                    _filterDropdown(data.term_id, data, index, darkMode)
                  }
                  on="click"
                  // basic
                  trigger={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        maxHeight: "85px",
                        padding: "0px 0px 0px 0px",
                        margin: "0px 0px 0px 0px",
                        // borderStyle: "solid"
                      }}
                    >
                      <div
                        className={
                          darkMode ? `FilterIconContDark` : `FilterIconCont`
                        }
                        style={{
                          maxWidth: "44px",
                          maxHeight: "44px",
                          minWidth: "44px",
                          minHeight: "44px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={data.url ? data.url : ""}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      <span style={{ fontSize: "8px", marginTop: "2px" }}>
                        {data.name}
                      </span>
                    </div>
                  }
                />
              </div>
            );
          })}
        />
        {/* {[...props.linkTypes].map((data, index) => {
					return (
						<div className="filter-bar-comp" key={index}>
							<Popup
								style={{
									padding: "0px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									borderStyle: "none",
									marginTop: "15px",
									background: "transparent"
								}}
								className={`${darkMode ? "popupAddonsDark" : "popupAddonsLight"}`}
								position="bottom center"
								content={() => _filterDropdown(data.term_id, data, index, darkMode)}
								on="click"
								// basic
								trigger={
									<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", padding: "0px 0px 0px 0px" }}>
										<div
											className={darkMode ? `FilterIconContDark` : `FilterIconCont`}
											style={{ width: "44px", height: "44px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}
										>
											<img
												src={
													index === 0
														? `https://dev.app.pikilinks.com/wp-content/uploads/2021/01/icons8-shopping-cart-1.png`
														: index === 1
														? "https://dev.app.pikilinks.com/wp-content/uploads/2021/01/icons8-music.png"
														: index === 2
														? "https://dev.app.pikilinks.com/wp-content/uploads/2021/01/icons8-cinema.png"
														: index === 3
														? "https://dev.app.pikilinks.com/wp-content/uploads/2021/01/icons8-physics.png"
														: "https://dev.app.pikilinks.com/wp-content/uploads/2021/01/icons8-car-roof-box-1.png"
												}
												style={{ width: "50%", height: "50%" }}
											/>
										</div>
										<span style={{ fontSize: "8px", marginTop: "2px" }}>{data.name}</span>
									</div>
								}
							/>
						</div>
					);
				})} */}
      </div>
    </div>
  );
}

FilterBar.prototypes = {
  linkTypes: PropTypes.array,
  unCheckedLinks: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    filterBar: state.settings.filterBar,
    linkTypes: state.world.linkTypes,
    unCheckedLinks: state.world.unCheckedLinks,
  };
};

export default connect(mapStateToProps)(withRouter(FilterBar));
