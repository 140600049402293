import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import isEmpty from "../../utils/isEmpty";
import { RiBankLine } from "react-icons/ri";
import LoadingGif from "../../assets/img/main_loading.gif";
import "./tagsinput.scss";
import { getAutoCompleteTags } from "../../api/api";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import store from "../../redux/store";

const Tag = (props) => (
	<span className="tag" style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} {...props}>
		{props.children}
	</span>
);
const Delete = (props) => (
	<IoMdClose
		className="delete"
		style={{ color: "white", padding: "2px", backgroundColor: "rgba(0,0,0,0.3)", borderRadius: "50%", fontSize: "16px", cursor: "pointer", marginLeft: "5px" }}
		{...props}
	/>
);

class TagsInput extends React.Component {
	constructor() {
		super();
		this.state = {
			newTag: "",
			autoCompleteTags: [],
			tagsLoader: true,
			focused: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleRemoveTag = this.handleRemoveTag.bind(this);
		this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
		this.showAutoComplete = this.showAutoComplete.bind(this);
		this.hideAutoComplete = this.hideAutoComplete.bind(this);
		this.selectAutoCompleteTag = this.selectAutoCompleteTag.bind(this);
	}

	showAutoComplete = () => {
		let autoCom = document.getElementById("autoComplete");

		if (!this.props.isAutoCompleteOn) {
			autoCom.style.display = "block";
			autoCom.style.position = "absolute";
			this.props.setIsAutoCompleteOn(true);
		}
		// else {
		// 	autoCom.style.display = "none";
		// 	this.props.setIsAutoCompleteOn(false);
		// }
	};

	hideAutoComplete = () => {
		let autoCom = document.getElementById("autoComplete");

		if (this.props.isAutoCompleteOn) {
			autoCom.style.display = "none";
			this.props.setIsAutoCompleteOn(false);
		}
	};

	handleChange(e) {
		this.setState({ ...this.state, newTag: e.target.value, tagsLoader: true });
		if (isEmpty(e.target.value)) {
			this.hideAutoComplete();
		} else {
			this.showAutoComplete();
		}
		// get tags for auto complete
		getAutoCompleteTags(`?query=${e.target.value}`)
			.then((res) => {
				if (res.response.ok) {
					this.setState({ ...this.state, autoCompleteTags: [...res.json.tags], tagsLoader: false });
				}
			})
			.catch((err) => {
				throw err;
			});
	}

	selectAutoCompleteTag(data, index) {
		let newTag = data.name.trim();

		if (this.props.value.indexOf(newTag) === -1) {
			this.props.value.push(newTag);
			this.props.onChange(this.props.value);
			this.setState({ ...this.state, newTag: "" });
			this.hideAutoComplete();
		}
	}

	handleKeyDown(e) {
		if (e.keyCode === 13 && e.target.value !== "") {
			if (this.props.value.length >= 10) {
				toast.dark(<b style={{ width: "400px" }}>Tags length limited to 10!</b>, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
					hideProgressBar: true,
					style: { backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5" }
				});
				return;
			}
			let newTag = this.state.newTag.trim();

			if (this.props.value.indexOf(newTag) === -1) {
				this.props.value.push(newTag);
				this.props.onChange(this.props.value);
				this.setState({ ...this.state, newTag: "" });
			}
			e.target.value = "";
		}

		if (e.keyCode === 8 && e.target.value === "") {
			this.props.value.splice(this.props.value.length - 1, 1);
			this.setState({ ...this.state, newTag: "" });
			this.props.onChange(this.props.value);
		}
	}

	handleRemoveTag(e, indexToBeDeleted) {
		let tag = e.target.parentNode.textContent.trim();
		let index = this.props.value.indexOf(tag);
		this.props.value.splice(indexToBeDeleted, 1);
		this.setState({ ...this.state, newTag: "" });
		this.props.onChange(this.props.value);
	}

	handleOnDragEnd(result) {
		if (!result.destination) return;
		const items = Array.from(this.props.value);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		this.props.setTags(items);
	}

	render() {
		return (
			<>
				<div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
					<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", flex: 0.9 }}>
						<div className="tags-input" style={{ width: "99%" }}>
							<Icon name="tag" className="tags-input-icon" style={{ color: this.state.focused ? "rgba(0,0,0,0.8)" : "gray" }} />
							{isEmpty(this.props.value) ? null : (
								<div className="grid">
									{[...this.props.value].map((tag, index) => {
										return (
											<div
												className={`box box-${index}${index === this.props.to && this.props.to !== this.props.from ? " to" : ""}`}
												data-index={index}
												key={tag}
												draggable="true"
												style={{ resize: "none" }}
												onDragStart={(e) => this.props.setFrom(Number(e.currentTarget.dataset.index))}
												onDragOver={(e) => {
													e.preventDefault();
													this.props.setTo(Number(e.currentTarget.dataset.index));
												}}
												onDragEnd={() => {
													let tagss = [...this.props.value];
													tagss.splice(this.props.to, 0, tagss.splice(this.props.from, 1)[0]);
													this.props.onChange(tagss);
													this.props.setFrom(null);
													this.props.setTo(null);
												}}
											>
												<Tag key={index}>
													<span className="tag-label">{tag.charAt(0).toUpperCase() + tag.slice(1)}</span>
													<Delete onClick={(e) => this.handleRemoveTag(e, index)} />
												</Tag>
											</div>
										);
									})}
								</div>
							)}
							<input
								type="text"
								onFocus={(e) => {
									this.setState({ ...this.state, focused: true });
								}}
								onBlur={(e) => {
									this.setState({ ...this.state, focused: false });
								}}
								onChange={this.handleChange}
								onKeyDown={this.handleKeyDown}
								value={this.state.newTag}
								placeholder="Write tag-name and hit enter"
							/>
						</div>
						<div style={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
							<span style={{ marginRight: "0px", fontStyle: "italic", padding: "10px", fontSize: "11px" }}>
								Last tag input displays an icon from the library
								{/* <Link className={this.props.darkMode ? "icon-lib-text-dark" : "icon-lib-text"} to="/icon-library">
									<RiBankLine style={{ margin: "0px 7px -3px 7px", fontSize: "17px" }} />
									<span className={this.props.darkMode ? "icon-lib-text-dark" : "icon-lib-text"}>icon Library</span>
								</Link> */}
							</span>
						</div>
					</div>
					<div
						style={{
							minWidth: "90px",
							maxWidth: "90px",
							height: "70px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							borderStyle: "solid",
							borderWidth: "1px",
							flex: 0.2,
							borderColor: "#D5D6D7",
							cursor: "pointer"
						}}
						onClick={() => {
							store.dispatch({ type: "SET_ICONS_LIBRARY_MODAL", payload: true });
						}}
					>
						{this.props.tagImageLoader ? (
							<div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px" }}>
								<img style={{ width: "80%", height: "100%" }} src={LoadingGif} />
							</div>
						) : this.props.lastTagImage ? (
							<img src={`${this.props.lastTagImage}`} style={{ width: "80%", height: "80%", objectFit: "contain" }} />
						) : null}
					</div>
				</div>
				<div id="autoComplete" style={{ backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5", maxHeight: "300px", overflowY: "scroll" }}>
					{this.state.tagsLoader ? (
						<div style={{ width: "100%", height: "70px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<img src={LoadingGif} style={{ width: "50px", height: "50px" }} />
						</div>
					) : (
						this.state.autoCompleteTags.map((data, index) => {
							return (
								<div
									onClick={() => this.selectAutoCompleteTag(data, index)}
									className="autoCompleteTag"
									key={index}
									style={{ width: "100%", padding: "7px 0px 7px 20px", borderBottomStyle: "solid", borderBottomWidth: "1px" }}
								>
									<span>{data.name}</span>
								</div>
							);
						})
					)}
				</div>
			</>
		);
	}
}

export default TagsInput;
