import React from "react";

//packages
import { toast } from "react-toastify";
import { Label } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Message, TextArea } from "semantic-ui-react";

//api
import { sendFeedback } from "../../api/api";

//utils
import store from "../../redux/store";
import { validateFeedbackInputs } from "../../validation/validation";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";

const ErrorBody = (props) => {
	if (!props.children) return null;
	return (
		<Label basic color="red" pointing>
			{props.children}
		</Label>
	);
};

const dataModel = {
	feedback: "",
	recaptchaVerified: false,
	"g-recaptcha-response": ""
};

function AddPost(props) {
	const captchaRef = React.useRef(null);

	const [feedbackData, setFeedbackData] = React.useState({ ...dataModel });

	const [submitted, setSubmitted] = React.useState(false);
	const [errors, setErrors] = React.useState({});
	const darkmode = store.getState().settings.darkMode;

	React.useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("send-feedback-page");
		document.getElementsByTagName("body")[0].removeAttribute("id");
		return () => {
			document.getElementsByTagName("body")[0].classList.remove("send-feedback-page");
		};
	}, []);

	const _feedbackDataChange = (e, { value }) => {
		setErrors({ ...errors, [e.target.name]: "" });
		setFeedbackData({
			...feedbackData,
			[e.target.name]: value || ""
		});
		setSubmitted(false);
	};

	const _showResponse = (res) => {
		setErrors({ ...errors, "g-recaptcha-response": "" });
		setFeedbackData({
			...feedbackData,
			recaptchaVerified: true,
			"g-recaptcha-response": res
		});
	};

	const _expireRecaptcha = (res) => {
		setFeedbackData({
			...feedbackData,
			recaptchaVerified: false,
			"g-recaptcha-response": ""
		});
	};

	const _submit = () => {
		const { isValid, errors } = validateFeedbackInputs(feedbackData);
		if (!isValid) {
			console.log(errors);
			setErrors(errors);
			return;
		}

		if (!localStorage.getItem("userToken")) {
			toast.dark("You must be Logged in to Submit Feedback", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				style: { backgroundColor: darkmode ? "#3C3C3C" : "#EDF0F5" }
			});
			return;
		}

		sendFeedback({
			feedback: feedbackData.feedback,
			"g-recaptcha-response": feedbackData["g-recaptcha-response"]
		})
			.then((res) => {
				if (res.response.ok) {
					toast.info(<b style={{ width: "400px" }}>Feedback Successfully Submitted</b>, {
						position: toast.POSITION.BOTTOM_LEFT,
						autoClose: 2000,
						hideProgressBar: true
					});
					setFeedbackData({ ...dataModel });
				} else {
					setErrors(
						res.json.errors || {
							...errors,
							"g-recaptcha-response": res.json.message
						}
					);
					setFeedbackData({ ...feedbackData, recaptchaVerified: false });
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error(<b>{err}</b>, {
					position: toast.POSITION.BOTTOM_LEFT,
					autoClose: 2000,
					hideProgressBar: true
				});
			})
			.then(() => {
				captchaRef && captchaRef.current.reset();
			});
	};

	return (
		<div className="p-grid p-fluid dashboard page-contact">
			<MetaDecorator metaDataPerRoute={metaDecoratorData.send_feedback} />

			<div className="p-col-12 p-lg-12">
				<div className="card card-w-title">
					<h1>Send Feedback</h1>
					<div className="p-grid ">
						<div className="p-lg-8 add-post-page">
							<Form>
								{submitted ? (
									<Message info>
										<Message.Header>Feedback successfully submitted!</Message.Header>
									</Message>
								) : null}
								<br />
								<div className="p-col-12 p-md-12">
									<Form.Field>
										<label>Feedback</label>
										<TextArea fluid="true" value={feedbackData.feedback} placeholder="Write Feedbacks here..." onChange={_feedbackDataChange} name="feedback" />
									</Form.Field>
									<ErrorBody>{errors.feedback}</ErrorBody>
								</div>

								<div className="p-col-12 p-md-12">
									<div style={{ width: "300px", margin: "auto" }}>
										<ReCAPTCHA theme={darkmode ? "dark" : "light"} ref={captchaRef} sitekey={`6LeT7R4aAAAAANggb_D-U_4DQIHo0DIgFpZ07984`} onChange={_showResponse} onExpired={_expireRecaptcha} />
										<ErrorBody>{errors["g-recaptcha-response"]}</ErrorBody>
									</div>
								</div>

								<div className="p-col-12 p-md-12">
									<Form.Button disabled={!feedbackData.recaptchaVerified} primary fluid onClick={_submit}>
										<b>Submit</b>
									</Form.Button>
								</div>
							</Form>
						</div>
					</div>
					<div className="centered">
						<NavLink to="/privacy">
							<b>Privacy Policy</b>
						</NavLink>{" "}
						<b>|</b>
						<NavLink to="/terms">
							{" "}
							<b>Terms and Conditions</b>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddPost;
