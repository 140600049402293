import React from "react";

function FlDropdown(props) {
	return (
		<div className="flDropdown">
			<select className="selectMenu">
				<option selected>Random</option>
				<option>Popularity</option>
				<option>Newest</option>
				<option>Oldest</option>
			</select>
		</div>
	);
}

export default FlDropdown;
