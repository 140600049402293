import React from 'react'
import Skeleton,{SkeletonTheme} from 'react-loading-skeleton'

export default function MenuSkeleton(props) {
    return (
        // <div style={{display:'flex',justifyContent:'center',flexDirection:'column',width:'100%',paddingLeft:'5px',paddingRight:'5px',paddingBottom:'10px'}} >
            <SkeletonTheme color={props.color} highlightColor={props.contentColor} >  
               <Skeleton height={50} style={{marginTop:'5px'}} width="100%" count={12} />
            </SkeletonTheme>  
        // </div>
    )
}
