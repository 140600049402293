import React from "react";
import { Icon } from "semantic-ui-react";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import VerificationModal from "./VerificationModal";
import { toast } from "react-toastify";

const AuthModal = ({ previousModal, whichModal, modalShow, switchModal, forgotPassword, titleVisibility, userData, history }) => {
	const [modalWarning, setModalWarning] = React.useState({ status: false, msg: "" });
	const [activeSide, setActiveSide] = React.useState("login");

	const _showModalWarning = (msg) => {
		setModalWarning({ status: true, warning: msg });
	};

	const _hideModalWarning = () => {
		setModalWarning({ status: false, msg: "" });
	};

	const hideModal = () => {
		if (whichModal === "verify" && !localStorage.getItem("userToken") && previousModal !== "login") {
			notify(" Registration successful !!");
		}
		modalShow();
	};
	const darkMode = useSelector((state) => state.settings.darkMode);

	const notify = (message) =>
		toast.dark(message, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	const switchToCard = (type) => {
		let innerCard = document.getElementById("card-3d-inner");
		if (type === "register") {
			innerCard.style.transform = "rotateY(180deg)";
		} else {
			innerCard.style.transform = "rotateY(0deg)";
		}
		setActiveSide(type);
	};

	React.useEffect(() => {
		if (whichModal === "register") {
			let innerCard = document.getElementById("card-3d-inner");
			if (innerCard) {
				innerCard.style.transform = "rotateY(180deg)";
			}
		}
	}, []);

	return (
		<div className={darkMode ? "modal-container-dark" : "modal-container"}>
			<div className="backdrop" onClick={hideModal}></div>
			<div class="card-3d-wrapper">
				<div id="card-3d-inner" class="card-3d-inner">
					<div className="card-front">
						<div className="modal-header">
							<h3 style={{ marginBottom: 0 }}>{whichModal == "login" ? "Login" : whichModal === "verify" ? "Verify" : ""}</h3>
							<Icon
								name="close"
								style={{
									cursor: "pointer",
									fontSize: "18px",
									color: darkMode ? "gray" : "gray"
								}}
								onClick={() => hideModal()}
							/>
						</div>
						<div className="modal-body">
							{whichModal === "login" ? (
								<LoginForm
									modalWarning={modalWarning}
									showModalWarning={_showModalWarning}
									hideModalWarning={_hideModalWarning}
									switchModal={switchModal}
									switchToCard={switchToCard}
									modalShow={modalShow}
									forgotPassword={forgotPassword}
									titleVisibility={titleVisibility}
									userData={userData}
									history={history}
									darkMode={darkMode}
								/>
							) : whichModal === "verify" ? (
								<VerificationModal daekMode={darkMode} switchModal={switchModal} modalShow={modalShow} />
							) : null}

							<div className="modal-links">
								<Link to="/privacy" className="title" onClick={hideModal}>
									Privacy Policy
								</Link>
								<div style={{ width: "2px", height: "15px", backgroundColor: darkMode ? "gray" : "gray", margin: "0px 8px 5px 8px" }} />
								<Link to="/terms" className="title" onClick={hideModal}>
									Terms and Conditions
								</Link>
							</div>
						</div>
					</div>
					<div className="card-back">
						<div className="modal-header">
							<h3 style={{ marginBottom: 0 }}>{whichModal == "register" ? "Register" : whichModal === "verify" ? "Verify" : ""}</h3>
							<Icon
								name="close"
								style={{
									cursor: "pointer",
									fontSize: "18px",
									color: darkMode ? "gray" : "gray"
								}}
								onClick={() => hideModal()}
							/>
						</div>
						<div className="modal-body">
							{whichModal === "register" ? (
								<RegisterForm
									switchToCard={switchToCard}
									modalWarning={modalWarning}
									showModalWarning={_showModalWarning}
									hideModalWarning={_hideModalWarning}
									switchModal={switchModal}
									modalShow={modalShow}
									darkMode={darkMode}
								/>
							) : whichModal === "verify" ? (
								<VerificationModal darkMode={darkMode} switchModal={switchModal} modalShow={modalShow} />
							) : null}

							<div className="modal-links">
								<Link to="/privacy" className="title" onClick={hideModal}>
									Privacy Policy
								</Link>
								<div style={{ width: "2px", height: "15px", backgroundColor: darkMode ? "gray" : "gray", margin: "0px 8px 5px 8px" }} />
								<Link to="/terms" className="title" onClick={hideModal}>
									Terms and Conditions
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="modal-box" style={{ borderStyle: "solid" }}>
				<div className="modal-header">
					<h3 style={{ marginBottom: 0 }}>{whichModal == "login" ? "Login" : whichModal === "verify" ? "Verify Email" : "Sign Up"}</h3>
					<Icon
						name="close"
						style={{
							cursor: "pointer",
							fontSize: "18px",
							color: darkMode ? "gray" : "gray"
						}}
						onClick={() => hideModal()}
					/>
				</div>

				<div className="modal-body">
					{whichModal == "login" ? (
						<LoginForm
							modalWarning={modalWarning}
							showModalWarning={_showModalWarning}
							hideModalWarning={_hideModalWarning}
							switchModal={switchModal}
							modalShow={modalShow}
							forgotPassword={forgotPassword}
							titleVisibility={titleVisibility}
							userData={userData}
							history={history}
						/>
					) : whichModal === "verify" ? (
						<VerificationModal switchModal={switchModal} modalShow={modalShow} />
					) : (
						<RegisterForm modalWarning={modalWarning} showModalWarning={_showModalWarning} hideModalWarning={_hideModalWarning} switchModal={switchModal} modalShow={modalShow} />
					)}

					<div className="modal-links">
						<Link to="/privacy-policy" className="title" onClick={hideModal}>
							Privacy Policy
						</Link>
						<div style={{ width: "2px", height: "15px", backgroundColor: darkMode ? "gray" : "gray", margin: "0px 8px 5px 8px" }} />
						<Link to="/terms-and-conditions" className="title" onClick={hideModal}>
							Terms and Conditions
						</Link>
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default withRouter(AuthModal);
