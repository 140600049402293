import { listSortOptions } from "../../utils/commons";
import { SET_LISTS, UPDATE_LISTS } from "../types";



const initialState = {
    preloaded: false,
    listsArr: [],
    isFetching: false,
    isFinished: false,
    nextPage: 1,
    sortBy: JSON.parse(localStorage.getItem('listsSortBy')) || {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LISTS:
            return {
                ...state,
                preloaded: true,
                listsArr: action.payload,
                nextPage: 2,
            };

        case UPDATE_LISTS:
            //update localStorage if sortBy changed
            if (Object.keys(action.payload).sortBy) {
                localStorage.setItem('listsSortBy', action.payload.sortBy)
            }
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};
