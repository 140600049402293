import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function SubmissionPagination(props) {
	let { itemsPerPage, activePage, setActivePage, submissionData, totalCount } = props;

	let buttonClass = "pagination-btn-light";
	if (props.darkMode) {
		buttonClass = "pagination-btn-dark";
	}

	let totalPageCount = activePage > totalCount / itemsPerPage ? activePage : totalCount / itemsPerPage;

	return (
		<div className={`submission-table-container${props.isMobile ? "-mobile" : ""}__pagination`}>
			<div
				onClick={() => {
					setActivePage(1);
				}}
				className={`${buttonClass}`}
			>
				First
			</div>
			<div
				onClick={() => {
					if (activePage !== 1) {
						setActivePage(activePage - 1);
					}
				}}
				className={`${buttonClass}`}
			>
				<IoIosArrowBack />
			</div>
			<div className={`${buttonClass}`}>
				Page {activePage} of {Math.ceil(totalPageCount)}
			</div>
			<div
				onClick={() => {
					if (activePage !== Math.ceil(totalPageCount)) {
						setActivePage(activePage + 1);
					}
				}}
				className={`${buttonClass}`}
			>
				<IoIosArrowForward />
			</div>
			<div
				onClick={() => {
					setActivePage(Math.ceil(totalCount / itemsPerPage));
				}}
				className={`${buttonClass}`}
			>
				Last
			</div>
		</div>
	);
}

export default SubmissionPagination;
