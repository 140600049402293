import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

//from packages
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";

//components
import ListItem from "../Lists/ListItem";
import Loader from "../../components/Loader/Loader";

//api
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//hooks
import store from "../../redux/store";

//apis
import { getLists } from "../../api/api";

//utils
import { usePrevious } from "../../hooks/usePrevious";
import { RESET_TAGS, UPDATE_LISTS, UPDATE_TAGS } from "../../redux/types";

const sortByOptions = [{ name: "Random", code: "random" }, { name: "Newest", code: "newest" }, { name: "Oldest", code: "oldest" }];

function Lists(props) {
	const { listsArr, isFetching, isFinished, nextPage } = props.lists;

	const [sortBy, setSortBy] = useState(sortByOptions[0]);
	const loader = useRef(null);

	const GetLists = () => {
		!isFinished &&
			store.dispatch({
				type: UPDATE_LISTS,
				payload: { ...props.lists, isFetching: true }
			});
		getLists(`paged=${nextPage}&per_page=10`)
			.then((res) => {
				const lengthNow = listsArr.length + res.json.series.length;
				if (lengthNow >= res.response.headers.get("x-wp-total")) {
					// if (lengthNow >= 19) {
					props.updateLists({ isFinished: true });
				}
				res = res.json;
				// console.log('here is teh response from the server hai ta', res.series)
				// props.updateLists({
				//     isFetching: false,
				//     nextPage: nextPage + 1,
				//     listsArr: [...listsArr, ...res.series]
				// });

				store.dispatch({
					type: UPDATE_LISTS,
					payload: {
						isFetching: false,
						nextPage: nextPage + 1,
						listsArr: [...listsArr, ...res.series]
					}
				});
			})
			.catch((err) => {
				console.log(err);
				// setIsFinished(true);
			});
	};

	// on getting lists
	useEffect(() => {
		(async () => {
			// console.log("here is the lists data from the redux store", props.lists.listsArr);
		})();
	}, [props.lists]);

	//did mount
	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("lists-page");
		if (localStorage.getItem("listsSortBy")) {
			setSortBy(JSON.parse(localStorage.getItem("listsSortBy")));
		}
		return () => {
			document.getElementsByTagName("body")[0].classList.remove("lists-page");
		};
	}, []);

	//For observer
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (!isFetching) {
				// console.log("Is data finished: ", isFinished);
			}
			!isFetching && !isFinished && GetLists();
		}
	};
	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0
		};
		// Create observer
		const observer = new IntersectionObserver(handleObserver, options);
		// observe the loader
		if (loader && loader.current) {
			observer.observe(loader.current);
		}
		// clean up on willUnMount
		return () => observer.unobserve(loader.current);
	}, [props.lists]);

	const _sortByChange = (e) => {
		// setSortBy(e.target.value);
		// setNextPage(1)
		// setListsArr([])
		// localStorage.setItem('listsSortby', JSON.stringify(e.target.value));
	};

	const ListsList = useMemo(() => {
		return (listsArr || []).map((post, index) => {
			return <ListItem key={index} listData={post} />;
		});
	}, [listsArr, props.activeTag, props.lists.listsArr]);

	return (
		<div className={`p-grid p-fluid dashboard page-list`}>
			<div className="p-col-12 p-lg-12" style={{ display: "flex", justifyContent: "space-between" }}>
				<div className={`p-col-6 p-md-3 underlined-border`}>
					<b>Lists</b>
				</div>
				<div className="p-col-6 p-md-3 post-filter">
					<Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={sortByOptions} value={sortBy} />
				</div>
			</div>

			{ListsList}
			<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
				{isFetching && !isFinished && <Loader loadingMore={true} />}
				{isFinished && listsArr.length < 1 && (
					<div className="text-centered">
						<b>No Links found</b>
					</div>
				)}
			</div>
		</div>
	);
}

Lists.propTypes = {
	lists: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	lists: state.lists
});

const mapDispatchToProps = (dispatch) => {
	return {
		// dispatching plain actions
		updateLists: (payload) => dispatch({ type: UPDATE_LISTS, payload })
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Lists)
);
