import React from "react";
import "./upvoteLoader.scss";

export default function UpvoteLoader({ bcolor }) {
	return (
		<div class="lds-ringg">
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
		</div>
	);
}
