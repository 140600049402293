import GLOBALS from "../constants";
import history from "../utils/history";

export const wrapper = async (url, method, signal, body, notoken) => {
  try {
    if (window.navigator.onLine) {
      // always call main call api
      // "Bearer " + localStorage.getItem("userToken")
      let headerVar = {
        "Content-Type": "application/json",
        // "Cache-Control": "no-cache"
      };
      if (localStorage.getItem("userToken")) {
        headerVar = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        };
      }
      // console.log("HEADER VAR", headerVar);
      const mainCall = async () => {
        return await fetch(`${GLOBALS.SERVER_URL}${url}`, {
          method: method,
          signal: signal,
          headers: headerVar,
          body: JSON.stringify(body),
        });
      };
      var response = await mainCall();
      var json = await response.json();
      if (json.message === "Expired token") {
        localStorage.clear();
      }
      return { json, response };
    } else {
      //no internet connection
      // console.log('the app now is offline okay!')
      history.push("/offline");
    }
  } catch (e) {
    throw e;
  }
};

export const unauthWrapper = async (url, method, signal, body) => {
  try {
    if (window.navigator.onLine) {
      // always call main call api
      // "Bearer " + localStorage.getItem("userToken")
      let headerVar = {
        "Content-Type": "application/json",
        // "Cache-Control": "no-cache"
      };
      if (localStorage.getItem("userToken")) {
        headerVar = {
          "Content-Type": "application/json",
        };
      }

      const mainCall = async () => {
        return await fetch(`${GLOBALS.SERVER_URL}${url}`, {
          method: method,
          signal: signal,
          headers: headerVar,
          body: JSON.stringify(body),
          credentials: "include",
        });
      };
      var response = await mainCall();
      var json = await response.json();
      if (json.message === "Expired token") {
        localStorage.clear();
      }
      return { json, response };
    } else {
      //no internet connection
      // console.log('the app now is offline okay!')
      history.push("/offline");
    }
  } catch (e) {
    throw e;
  }
};
