import React from "react";
import Subscription from "./Subscription";
import { Button, Input, Modal, Icon, ModalContent } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

function SubscriptionModal(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);

	return (
		<Modal
			onClose={() => {
				props.onClose();
			}}
			onOpen={props.onClose}
			open={props.open}
			className={darkMode ? "subscribe-notification-modal-dark" : "subscribe-notification-modal-light"}
		>
			<ModalContent style={{ backgroundColor: darkMode ? "#3c3c3c" : "#EDF0F5" }}>
				<div className="subs-close">
					<IoCloseSharp
						onClick={() => {
							props.onClose();
						}}
					/>
				</div>
				<Subscription subsModal={true} />
			</ModalContent>
		</Modal>
	);
}

export default SubscriptionModal;
