import React, { Component } from "react";
import Axios from "axios";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import { Redirect, Route } from "react-router-dom";
import EmailField from "./EmailField";
import PasswordField from "./PasswordField";
import RePasswordField from "./RePassword";
import "react-toastify/dist/ReactToastify.css";
import "./account.scss";
import Del from "../../components/Toast/DeleteToast/Del";
import "sweetalert2/src/sweetalert2";
import store from "../../redux/store";
import GLOBALS from "../../constants";
import UsernameField from "./UsernameField";
import { RiDeleteBinLine } from "react-icons/ri";

export default class Account extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailError: {
				status: false,
				message: "User must provide a valid email address"
			},
			usernameError: {
				status: false,
				message: "Username cannot be empty"
			},
			accountLoader: false,
			passwordError: false,
			rePasswordError: false,
			passwordVisibility: false,
			repasswordVisibility: false,
			showRePassword: false,
			emailValue: "",
			usernameValue: "",
			passwordValue: "",
			saveEmail: false,
			saveUsername: false,
			savePassword: false,
			saveRepassword: false
		};
	}

	componentDidMount() {
		this.setState({ emailValue: localStorage.getItem("userEmail"), usernameValue: localStorage.getItem("userName") });
	}

	notify = (message) =>
		toast.dark(message, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	edit = (field) => {
		switch (field) {
			case "username":
				document.getElementById("username").removeAttribute("disabled");
				this.setState({ saveUsername: true });
				break;
			case "email":
				document.getElementById("email").removeAttribute("disabled");
				this.setState({ saveEmail: true });
				break;
			case "password":
				document.getElementById("password").removeAttribute("disabled");
				this.setState({ passwordValue: "" });
				if (document.getElementById("rePassword")) {
					document.getElementById("rePassword").removeAttribute("disabled");
				}
				if (document.getElementById("repassword")) {
					document.getElementById("repassword").removeAttribute("disabled");
				}
				this.setState({
					showRePassword: true,
					savePassword: true,
					saveRepassword: true,
					passwordValue: "",
					repasswordVisibility: true
				});
				break;
			default:
				return;
		}
	};

	close = (field, value) => {
		switch (field) {
			case "username":
				document.getElementById("username").setAttribute("disabled", "true");
				this.setState({ saveUsername: false });
				this.changeUsername(value);
				break;
			case "email":
				document.getElementById("email").setAttribute("disabled", "true");
				this.setState({ saveEmail: false });

				if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
					this.setState((prevState) => ({
						emailError: {
							...prevState.emailError,
							status: true,
							message: this.state.emailError.message
						}
					}));
				} else {
					this.setState({ emailError: false });
					this.changeEmail(value);
				}
				break;
			case "password":
				if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{11,}$/.test(value)) {
					this.setState({ passwordError: true });
				} else {
					this.setState({ passwordError: false });

					if (document.getElementById("rePassword").value != document.getElementById("password").value) {
						this.setState({ rePasswordError: true });
					} else {
						this.setState({ rePasswordError: false });
						document.getElementById("password").setAttribute("disabled", "true");
						document.getElementById("rePassword").setAttribute("disabled", "true");
						this.setState({ savePassword: false, saveRepassword: false });
						this.changePassword(value);
					}
				}
				break;
			default:
				return;
		}
	};

	closeFields = (field) => {
		switch (field) {
			case "password":
				document.getElementById("password").setAttribute("disabled", "true");
				document.getElementById("rePassword").setAttribute("disabled", "true");
				this.setState({ savePassword: false, passwordValue: "***********", saveRepassword: false, repasswordVisibility: false });
				this.setState({ passwordError: false });
				this.setState({ rePasswordError: false });
				break;
			default:
				return;
		}
	};

	changeUsername = (uname) => {
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/change-username`,
			method: "POST",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("userToken")
			},
			data: {
				username: uname
			}
		}).then(
			(res) => {
				this.setState({ usernameValue: uname });
				localStorage.setItem("userName", uname);
				this.notify(res.data.message);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	changeEmail = (email) => {
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/change-email`,
			method: "POST",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("userToken")
			},
			data: {
				email: email
			}
		}).then(
			(res) => {
				this.setState({ emailValue: email });
				localStorage.setItem("userEmail", email);
				this.notify(res.data.message);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	changePassword = (password) => {
		this.setState({
			accountLoader: true
		});
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/change-password`,
			method: "POST",
			headers: {
				Authorization: "Bearer " + localStorage.getItem("userToken")
			},
			data: {
				password: password
			}
		}).then(
			(res) => {
				if (res.status === 200) {
					this.setState({
						accountLoader: false,
						passwordError: false,
						rePasswordError: false,
						passwordVisibility: false,
						repasswordVisibility: false,
						showRePassword: false,
						saveEmail: false,
						savePassword: false,
						saveRepassword: false
					});
					this.notify(res.data.message);
				} else {
					this.setState({
						accountLoader: false
					});
					this.notify("Failed to update");
				}
			},
			(err) => {
				this.setState({
					accountLoader: false
				});
				this.notify("Failed to update");
				console.log(err);
			}
		);
	};

	fieldChange = (field) => {
		switch (field) {
			case "username":
				this.setState({
					usernameValue: document.getElementById("username").value
				});
			case "email":
				this.setState({
					emailValue: document.getElementById("email").value
				});
			case "password":
				this.setState({
					passwordValue: document.getElementById("password").value
				});
			default:
				break;
		}
	};

	deleteAccount_ = () => {
		store.dispatch({ type: "SET_DELETE_MODAL", payload: true });
	};

	render() {
		let isMobile = window.innerWidth < 700;
		return (
			<>
				{!localStorage.getItem("userToken") ? (
					<Route>
						<Redirect to="/" />
					</Route>
				) : (
					<div className="account-wrapper">
						<div className="account-form" style={{ width: isMobile ? "90%" : "" }}>
							<div className="textfield-container">
								<UsernameField
									label={"Username"}
									usernameError={this.state.usernameError.status}
									usernameValue={this.state.usernameValue}
									usernameErrorMessage={this.state.usernameError.message}
									fieldChange={this.fieldChange}
									saveUsername={this.state.saveUsername}
									edit={this.edit}
									close={this.close}
								/>

								<EmailField
									label={"Email"}
									emailError={this.state.emailError.status}
									emailValue={this.state.emailValue}
									emailErrorMessage={this.state.emailError.message}
									fieldChange={this.fieldChange}
									saveEmail={this.state.saveEmail}
									edit={this.edit}
									close={this.close}
								/>

								<PasswordField
									label={"Password"}
									passwordError={this.state.passwordError}
									passwordValue={this.state.passwordValue}
									fieldChange={this.fieldChange}
									savePassword={this.state.savePassword}
									edit={this.edit}
									visiblity={this.state.repasswordVisibility}
									closeFields={this.closeFields}
									close={this.close}
								/>

								{this.state.repasswordVisibility ? (
									<RePasswordField
										label={"Re-enter Password"}
										rePasswordError={this.state.rePasswordError}
										rePasswordValue={this.state.rePasswordValue}
										rePasswordVisibility={this.state.repasswordVisibility}
										fieldChange={this.fieldChange}
										saveRePassword={this.state.saveRePassword}
										edit={this.edit}
										close={this.close}
										accountLoader={this.state.accountLoader}
									/>
								) : null}
								<div style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "30px" }}>
									<Button style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={this.deleteAccount_} color="red">
										<RiDeleteBinLine style={{ fontSize: "18px", marginRight: "5px" }} />
										Delete Account
									</Button>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
