import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Label, Form } from "semantic-ui-react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { sendFeedback } from "../../api/api";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { toast } from "react-toastify";

const ErrorBody = (props) => {
	if (!props.children) return null;
	return (
		<Label basic color="red" pointing>
			{props.children}
		</Label>
	);
};

const dataModel = {
	feedback: "",
	"g-recaptcha-response": ""
};

function SendFeedbackModal(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);

	const notify = (msg) =>
		toast.dark(msg, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	const captchaRef = useRef(null);
	const [errors, setErrors] = React.useState({});
	const [feedbackData, setFeedbackData] = React.useState({ ...dataModel });
	const [submitting, setSubmit] = React.useState(false);
	const [recaptchaResponse, setRecaptchaResponse] = React.useState("");
	const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);

	const _changeFeedback = (e) => {
		setErrors({ ...errors, feedback: "" });
		setFeedbackData({
			...feedbackData,
			feedback: e.target.value
		});
	};

	React.useEffect(() => {
		setErrors({});
		setFeedbackData({
			feedback: ""
		});
		setRecaptchaResponse("");
		setRecaptchaVerified(false);
	}, []);

	const _showResponse = (res) => {
		setErrors({ ...errors, "g-recaptcha-response": "" });
		setRecaptchaResponse(res);
		setRecaptchaVerified(true);
		setFeedbackData({
			...feedbackData,
			"g-recaptcha-response": res
		});
	};

	const _expireRecaptcha = (res) => {
		setRecaptchaVerified(false);
		setRecaptchaResponse("");
	};

	const _submit = () => {
		if (!localStorage.getItem("userToken")) {
			toast.dark("You must be Logged in to Submit Feedback", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
			});
			return;
		}

		if (feedbackData.feedback.length <= 0) {
			setErrors({ ...errors, feedback: "Field cant be empty" });
			return;
		}

		if (!recaptchaVerified) {
			setErrors({ ...errors, "g-recaptcha-response": "failed!!" });
			setRecaptchaResponse("");
			setRecaptchaVerified(false);
			return;
		}
		setSubmit(true);
		sendFeedback({
			feedback: feedbackData.feedback,
			"g-recaptcha-response": recaptchaResponse
		})
			.then((res) => {
				setSubmit(false);
				if (res.response.ok) {
					toast.info(<b style={{ width: "400px" }}>Feedback Successfully Submitted</b>, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
						hideProgressBar: true,
						style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
					});
					setFeedbackData({ ...dataModel });
					props.onClose();
				} else {
					setErrors(
						res.json.errors || {
							...errors,
							"g-recaptcha-response": res.json.message
						}
					);
					setFeedbackData({ ...feedbackData, recaptchaVerified: false });
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error(<b>{err}</b>, {
					position: toast.POSITION.BOTTOM_LEFT,
					autoClose: 2000,
					hideProgressBar: true
				});
			});
	};

	return (
		<Modal
			onClose={() => {
				setSubmit(false);
				setFeedbackData({ ...dataModel });
				setRecaptchaResponse("");
				setRecaptchaVerified(false);
				props.onClose();
			}}
			onOpen={props.onClose}
			open={props.open}
			style={{ height: "90%" }}
			className={darkMode ? "feedback-modal-dark" : "feedback-modal-light"}
		>
			<Modal.Header className={darkMode ? "feedback-modal-head-dark" : "feedback-modal-head-light"} style={{ display: "flex", flex: 1 }}>
				<div style={{ display: "flex", flex: 0.5 }}>
					<span style={{ fontSize: "18px" }}>Send Feedback</span>
				</div>
				<div style={{ display: "flex", flex: 0.5, alignItems: "flex-end", justifyContent: "flex-end" }}>
					<MdClose style={{ color: darkMode ? "lightgray" : "black", cursor: "pointer" }} onClick={props.onClose} />
				</div>
			</Modal.Header>
			<Modal.Content
				style={{
					display: "flex",
					justifyContent: "space-between",
					backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
					borderRadius: "0px",
					padding: "5px",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", padding: "0px 10px 0px 10px" }}>
					<textarea
						value={feedbackData.feedback}
						onChange={_changeFeedback}
						rows={8}
						style={{ width: "100%", backgroundColor: "lightgray", borderStyle: "none", borderRadius: "1px", padding: "10px" }}
						placeholder="Write Feedback here..."
					/>
					<ErrorBody>{errors.feedback}</ErrorBody>
					<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
						<ReCAPTCHAV2 theme={darkMode ? `dark` : `light`} ref={captchaRef} sitekey={`6LeT7R4aAAAAANggb_D-U_4DQIHo0DIgFpZ07984`} onChange={_showResponse} onExpired={_expireRecaptcha} />
						<ErrorBody>{errors["g-recaptcha-response"]}</ErrorBody>
					</div>
					<div style={{ marginTop: "20px" }}>
						<button className="theme-btn" onClick={_submit} disabled={submitting || feedbackData.feedback.length < 1 || !recaptchaVerified}>
							Submit
						</button>
					</div>
					<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", padding: "10px" }}>
						<NavLink to="/privacy">
							<b>Privacy Policy</b>
						</NavLink>{" "}
						<b style={{ color: "gray" }}>|</b>
						<NavLink to="/terms">
							{" "}
							<b>Terms and Conditions</b>
						</NavLink>
					</div>
				</div>
			</Modal.Content>
		</Modal>
	);
}

export default SendFeedbackModal;
