import React, { useState } from "react";
import { useSelector } from "react-redux";
import VerificationInput from "./VerificationInput";
import { sendVerificationCode, resendCode } from "../../api/api";
import store from "../../redux/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function VerificationModal(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);
	const [error, setError] = useState({ emailError: { messagge: "Provide valid email!!", status: false }, codeError: { status: false, messagge: "Code doesnt match!!" } });
	const [verifyLoader, setVerifyLoader] = useState(false);
	const [reSendLoader, setReSendLoader] = useState(false);

	const handleKeyDown = (e) => {
		// console.log(" key down : ", e);
	};

	const hideModal = () => {
		props.modalShow();
	};

	const notify = (message) =>
		toast.dark(message, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	const VerifyEmail = async (code) => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		const email = store.getState().auth.registeredEmail;
		setError((prevv) => {
			return { ...prevv, codeError: { message: "", status: false } };
		});
		setVerifyLoader(true);
		await sendVerificationCode(code, email, signal)
			.then((res) => {
				setVerifyLoader(false);
				if (res.response.status === 200) {
					props.modalShow();
					notify("You are now verified !! Try loging in again");
				} else {
					notify("Verification failed !! Try again");
					setError((prev) => {
						return { ...prev.emailError, codeError: { message: res.json.message || "Failed to verify", status: true } };
					});
				}
			})
			.catch((err) => {
				setError((prev) => {
					return { ...prev.emailError, codeError: { message: "Failed to verify", status: true } };
				});
				setVerifyLoader(false);
			});

		setTimeout(function() {
			abortController.abort();
		}, 60000);
	};

	const ResendCode = async () => {
		const email = store.getState().auth.registeredEmail;
		const abortController = new AbortController();
		const signal = abortController.signal;
		setError((prevv) => {
			return { ...prevv, codeError: { message: "", status: false } };
		});
		setReSendLoader(true);
		await resendCode(email, signal)
			.then((res) => {
				if (res.status === 200) {
					setReSendLoader(false);
				} else {
					setReSendLoader(false);
				}
			})
			.catch((err) => {
				setReSendLoader(false);
			});
		setTimeout(function() {
			abortController.abort();
		}, 60000);
	};

	return (
		<>
			<div className="textfield" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				<VerificationInput darkMode={darkMode} verifyLoader={verifyLoader} reSendLoader={reSendLoader} codeError={error.codeError} ResendCode={ResendCode} VerifyEmail={VerifyEmail} />
			</div>
		</>
	);
}

export default VerificationModal;
