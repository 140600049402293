import React, { useState,useEffect } from "react";
import Error from "./ErrorMessage";
import DotLoader from "../../components/Loader/DotLoader";

export default function VerificationInput(props) {
	const [code1, setCode1] = useState("");
	const [code2, setCode2] = useState("");
	const [code3, setCode3] = useState("");
	const [code4, setCode4] = useState("");
	const [code5, setCode5] = useState("");

	useEffect(()=>{
		if(code1.length !==0 && code2.length === 0 && code3.length === 0 && code4.length ===0 && code5.length ===0){
		document.getElementById("code2").focus()
		}
		if(code1.length !==0 && code2.length !== 0 && code3.length === 0 && code4.length ===0 && code5.length ===0){
			document.getElementById("code3").focus()
			}
			if(code1.length !==0 && code2.length !== 0 && code3.length !== 0 && code4.length ===0 && code5.length ===0){
				document.getElementById("code4").focus()
				}
				if(code1.length !==0 && code2.length !== 0 && code3.length !== 0 && code4.length !==0 && code5.length ===0){
					document.getElementById("code5").focus()
					}
	},[code1,code2,code3,code4,code5])

	return (
		<>
			<div style={{ display: "flex", flexDirection: "column", flex: 1, paddingTop: "10px", paddingBottom: "10px" }}>
				<span style={{ fontSize: "13px", paddingBottom: "10px" }}>*check email and enter verification code (might take some time)</span>
				<div style={{ display: "flex", flex: 1, paddingTop: "10px", paddingBottom: "10px" }}>
					<div style={{ display: "flex", flex: 0.2}}>
						<input
							maxLength="1"
							onChange={(e) => setCode1(e.target.value)}
							value={code1}
							id="code1"
							
							className={props.darkMode ? "otp-input":"otp-input-light"}

							type="text"
							placeholder="*"
							style={{
								color: "gray"
							}}
						/>
					</div>
					<div style={{ display: "flex", flex: 0.2 }}>
						<input
							
							id="code2"
							maxLength="1"
							onChange={(e) => {
								setCode2(e.target.value);
								document.getElementById("code3").focus();
							}}
							value={code2}
							// style={{
							// 	width: "75%",
							// 	height: "50px",
							// 	borderStyle: "solid",
							// 	borderWidth: "1px",
							// 	borderColor: "rgba(0,0,0,0.2)",
							// 	display: "flex",
							// 	alignItems: "center",
							// 	justifyContent: "center",
							// 	textAlign: "center",
							// 	fontSize: "25px",
							// 	color: "gray"
							// }}
							className={props.darkMode ? "otp-input":"otp-input-light"}

							type="text"
							placeholder="*"
						/>
					</div>
					<div style={{ display: "flex", flex: 0.2 }}>
						<input
							maxLength="1"
							onChange={(e) => setCode3(e.target.value)}
							
							value={code3}
							id="code3"
							// style={{
							// 	width: "75%",
							// 	height: "50px",
							// 	borderStyle: "solid",
							// 	borderWidth: "1px",
							// 	borderColor: "rgba(0,0,0,0.2)",
							// 	display: "flex",
							// 	alignItems: "center",
							// 	justifyContent: "center",
							// 	textAlign: "center",
							// 	fontSize: "25px",
							// 	color: "gray"
							// }}
							className={props.darkMode ? "otp-input":"otp-input-light"}
							type="text"
							placeholder="*"
						/>
					</div>
					<div style={{ display: "flex", flex: 0.2 }}>
						<input
							maxLength="1"
							onChange={(e) => setCode4(e.target.value)}
							
							value={code4}
							id="code4"
							// style={{
							// 	width: "75%",
							// 	height: "50px",
							// 	borderStyle: "solid",
							// 	borderWidth: "1px",
							// 	borderColor: "rgba(0,0,0,0.2)",
							// 	display: "flex",
							// 	alignItems: "center",
							// 	justifyContent: "center",
							// 	textAlign: "center",
							// 	fontSize: "25px",
							// 	color: "gray"
							// }}
							className={props.darkMode ? "otp-input":"otp-input-light"}

							type="text"
							placeholder="*"
						/>
					</div>
					<div style={{ display: "flex", flex: 0.2 }}>
						<input
							maxLength="1"
							onChange={(e) => setCode5(e.target.value)}
							value={code5}
							id="code5"
							className={props.darkMode ? "otp-input":"otp-input-light"}
							// style={{
							// 	width: "70%",
							// 	height: "50px",
							// 	borderStyle: "solid",
							// 	borderWidth: "1px",
							// 	borderColor: "rgba(0,0,0,0.2)",
							// 	display: "flex",
							// 	alignItems: "center",
							// 	justifyContent: "center",
							// 	textAlign: "center",
							// 	fontSize: "25px",
							// 	color: "gray"
							// }}
							type="text"
							placeholder="*"
						/>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", marginLeft: "-25px", paddingBottom: "30px" }}>
					{props.codeError.status ? <Error message={props.codeError.message} /> : null}
				</div>
				<div className="auth-btns" style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "30px" }}>
					<a className="purple" style={{cursor:'pointer'}}
					onClick={() => {
						if(props.verifyLoader || props.reSendLoader){
							return
						}
						props.VerifyEmail(code1.toString() + code2.toString() + code3.toString() + code4.toString() + code5.toString())}}
					>
						{props.verifyLoader ? <DotLoader /> : "Verify"}
					</a>
					<a
					className="blue"
					onClick={() => {
						if(props.verifyLoader || props.reSendLoader){
							return
						}
						props.ResendCode();
						setCode1("");
						setCode2("");
						setCode3("");
						setCode4("");
						setCode5("");
					}}
					 >
						{props.reSendLoader ? <DotLoader /> : "Re-send"}
					 </a>
					{/* <button
						disabled={props.verifyLoader || props.reSendLoader}
						onClick={() => props.VerifyEmail(code1.toString() + code2.toString() + code3.toString() + code4.toString() + code5.toString())}
						style={{ borderStyle: "none", padding: "15px", width: "90px",maxHeight:"45px",backgroundColor: props.verifyLoader ? "lightgray" : "#50D370", color: "white", cursor: "pointer", margin: "5px" }}
					    
					>
						{props.verifyLoader ? <DotLoader /> : "Verify"}
					</button>
					<button
						disabled={props.verifyLoader || props.reSendLoader}
						onClick={() => {
							props.ResendCode();
							setCode1("");
							setCode2("");
							setCode3("");
							setCode4("");
							setCode5("");
						}}
						style={{ borderStyle: "none", padding: "15px", width: "90px", backgroundColor: props.reSendLoader ? "lightgray" : "#F73830", color: "white", cursor: "pointer", margin: "5px",maxHeight:"45px" }}
					>
						{props.reSendLoader ? <DotLoader /> : "Re-send"}
					</button> */}
				</div>
			</div>
		</>
	);
}
