import React, { useState } from "react";
import Error from "../../layout/TopBar/ErrorMessage";
import { Icon, Popup } from "semantic-ui-react";
import store from "../../redux/store";
import AccountLoader from "../../components/Loader/AccountLoader";

const RePasswordField = ({ label, edit, close, rePasswordError, rePasswordValue, fieldChange, saveRePassword, rePasswordVisibility, accountLoader }) => {
	const [rePasswordVisibilityState, setRePasswordVisibilityState] = useState(false);

	const seePassword = () => {
		setRePasswordVisibilityState(!rePasswordVisibilityState);
		if (document.getElementById("rePassword").type == "password") {
			document.getElementById("rePassword").type = "text";
		} else {
			document.getElementById("rePassword").type = "password";
		}
	};
	const darkMode = store.getState().settings.darkMode;
	return (
		<div className="textfield">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<label style={{ color: darkMode ? "gray" : "black" }} for="rePassword" className="title">
					{label}
				</label>
				{rePasswordError ? <Error message="Passwords do not match" /> : null}
			</div>
			<div style={{ display: "flex", justifyContent: "flex-start" }}>
				<div
					className="passwordField-container"
					style={{
						width: document.getElementById("password").offsetWidth
					}}
				>
					<input type="password" id="rePassword" placeholder="Re-enter Password" className={rePasswordError ? "error" : null} onChange={() => fieldChange("rePassword")} />

					<div className="eye-icon">
						{rePasswordVisibilityState ? (
							<Icon name="eye slash" style={{ color: darkMode ? "gray" : "black" }} onClick={seePassword} />
						) : (
							<Icon name="eye" style={{ color: darkMode ? "gray" : "black" }} onClick={seePassword} />
						)}
					</div>
				</div>
				<div
					style={{
						width: 53,
						paddingTop: 5,
						textAlign: "center"
					}}
				>
					<Popup
						trigger={
							<span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
								{accountLoader ? <AccountLoader bcolor={darkMode ? "lightgray" : "black"} /> : <Icon name="check" onClick={() => close("password", document.getElementById("password").value)} />}
							</span>
						}
						content="save"
						inverted
					/>
				</div>
			</div>
		</div>
	);
};

export default RePasswordField;
