import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import Error from "./ErrorMessage";
import Axios from "axios";
import { PasswordValidation } from "./validations";
import { toast } from "react-toastify";
import DotLoader from "../../components/Loader/DotLoader";
import GLOBALS from "../../constants";
import { BiLock } from "react-icons/bi";
import { IoIosFingerPrint } from "react-icons/io";
import NewLoader from "../../assets/img/808080loader.gif";
import { isEmpty } from "../../validation/validation";

export default class ResetCodeSentModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordVisibility: false,
			codeError: {
				status: false,
				message: ""
			},
			passwordError: false,
			repasswordError: false,
			resendCodeLoader: false,
			sendNewPasswordLoader: false
		};
	}

	passwordVisibility = () => {
		this.setState((prevState) => ({
			passwordVisibility: !prevState.passwordVisibility
		}));
	};

	validate = (code, password, repassword) => {
		const passwordStatus = PasswordValidation(password);
		const doesPasswordMatch = password.toString() === repassword.toString();
		if (passwordStatus) {
			if (doesPasswordMatch) {
				this.setState({ passwordError: false, repasswordError: false });
				this.sendNewPassword(code, password);
			} else {
				this.setState({ repasswordError: true, passwordErrMessage: "password doesn't match" });
			}
		} else {
			this.setState({ passwordError: true });
		}
	};

	resendCode = (email) => {
		this.setState({ resendCodeLoader: true });
		if (isEmpty(email)) {
			this.notify("Failed! Reload Page & Try Again");
			return;
		}
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/bdpwr/v1/reset-password`,
			method: "POST",
			data: {
				email: email
			}
		})
			.then((res) => {
				this.setState({ resendCodeLoader: false });
			})
			.catch((err) => {
				this.setState({ resendCodeLoader: false });
				console.log(err);
			});
	};

	sendNewPassword = () => {
		this.setState({ sendNewPasswordLoader: true });
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/bdpwr/v1/set-password`,
			method: "POST",
			data: {
				email: this.props.email,
				code: document.getElementById("code").value,
				password: document.getElementById("password").value
			}
		}).then(
			(res) => {
				this.setState({ sendNewPasswordLoader: false });
				this.notify(res.data.message);
				this.props.resetCode();
			},
			(err) => {
				this.setState({ sendNewPasswordLoader: false });
				if (err.response.data.data.status === 500) {
					this.setState((prevState) => ({
						codeError: {
							...prevState.codeError,
							status: true,
							message: err.response.data.message
						}
					}));
				}
			}
		);
	};

	notify = (message) =>
		toast.dark(message, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	render() {
		return (
			<div className={this.props.darkMode ? "modal-container-dark" : "modal-container"}>
				<div className="backdrop" onClick={this.props.resetCode}></div>
				<div className="modal-box">
					<div className="modal-body">
						<div className="modal-header" style={{ padding: "0px 0px 30px 0px", flex: 1 }}>
							<h2 style={{ marginBottom: 0, fontSize: "17px", display: "flex", flex: 0.8, fontWeight: "bold", lineHeight: 1.2 }}>
								A password reset code
								<br /> has been sent to your email.
							</h2>
							<Icon
							onClick={this.props.resetCode}
								name="close"
								style={{
									display: "flex",
									alignSelf: "center",
									cursor: "pointer",
									fontSize: "18px",
									color: this.props.darkMode ? "gray" : "gray"
								}}
							/>
						</div>

						<div className="textfield-container">
							<div className="textfield">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
										<label for="code" style={{ padding: "3px" }} className="title"></label>

										{this.state.codeError.status ? <Error message={this.state.codeError.message} /> : null}
									</div>
								</div>
								<div class="form-group">
									<IoIosFingerPrint className="input-icon" />
									<input type="text" id="code" placeholder="Enter the code" />
								</div>
							</div>

							<div className="textfield">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									<label for="password" style={{ padding: "3px" }} className="title"></label>
									{this.state.passwordError ? <Error message="Password must contain min 11 characters, at least 1 letter, 1 number and 1 special character" /> : null}
								</div>
								<div className="passwordField-container">
									<input type={this.state.passwordVisibility ? "text" : "password"} id="password" placeholder="Create a Password" />
									<BiLock className="input-icon" />
									<div className="eye-icon">{this.state.passwordVisibility ? <Icon name="eye slash" onClick={this.passwordVisibility} /> : <Icon name="eye" onClick={this.passwordVisibility} />}</div>
								</div>
							</div>

							<div className="textfield">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									<label for="password" style={{ padding: "3px" }} className="title"></label>
									{this.state.repasswordError ? <Error message="password doesnt match!" /> : null}
								</div>
								<div className="passwordField-container">
									<input type={this.state.passwordVisibility ? "text" : "password"} id="re-password" placeholder="Create a Password" />
									<BiLock className="input-icon" />
									<div className="eye-icon">{this.state.passwordVisibility ? <Icon name="eye slash" onClick={this.passwordVisibility} /> : <Icon name="eye" onClick={this.passwordVisibility} />}</div>
								</div>
							</div>
						</div>
						<div className="auth-btns" style={{ padding: "30px 0px 15px 0px" }}>
							<a className="black" onClick={() => this.resendCode(this.props.email)}>
								{this.state.resendCodeLoader ? (
									<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<img style={{ width: "100px", height: "100px", marginTop: "5px" }} src={NewLoader} />
									</span>
								) : (
									"RE-SEND CODE"
								)}
							</a>
							<a
								id="submitBtn"
								disabled={this.state.sendNewPasswordLoader}
								onClick={() => this.validate(document.getElementById("code").value, document.getElementById("password").value, document.getElementById("re-password").value)}
								className={this.state.sendNewPasswordLoader ? "black-disabled" : "black"}
							>
								{this.state.sendNewPasswordLoader ? (
									<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
										<img style={{ width: "100px", height: "100px", marginTop: "5px" }} src={NewLoader} />
									</span>
								) : (
									"SUBMIT"
								)}
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
