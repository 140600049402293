// import { stat } from "fs/promises";
import { SET_AUTH_USER, SET_REGISTERED_EMAIL } from "../types";

const INITIAL_STATE = {
	user: {},
	registeredEmail: ""
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_AUTH_USER:
			return { ...state, user: action.payload };
		case SET_REGISTERED_EMAIL:
			return { ...state, registeredEmail: action.payload };
		default:
			return { ...state };
	}
};
