import React, { useRef, useMemo, useState, useEffect, useCallback } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Icon, Input, Label, Popup } from "semantic-ui-react";

//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

//hooks

//apis
import { getSingleList } from "../../api/api";

//utils
import store from "../../redux/store";
import { popWindow } from "../../utils/manageShare";

//types
import { SET_POST_CARD_DATA_MODAL, RESET_TAGS, SET_ACTIVE_TAG, UPDATE_TAGS } from "../../redux/types";
import { isEmpty } from "../../validation/validation";
import { DynamicMetaDecorator } from "../../components/MetaDecorator/DynamicMetaDecorator";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const sortByOptions = [
	{ name: "Random", code: "Random", index: 1 },
	{ name: "Popularity", code: "Popularity", index: 2 },
	{ name: "Newest", code: "Newest", index: 3 },
	{ name: "Oldest", code: "Oldest", index: 4 }
];

const List = (props) => {
	const [postsArr, setPostsArr] = useState([]);
	const [listData, setListData] = useState({});
	const [sortBy, setSortBy] = useState(sortByOptions[1]);
	const loader = useRef(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const [nextPage, setnextPage] = useState(1);
	const [linkCopied, setLinkCopied] = useState(false);

	const [shareBlockShowing, setShareBlockShowing] = React.useState(false);

	const [seoData, setSeoData] = useState({});
	const darkMode = useSelector((state) => state.settings.darkMode);

	const GetSingleList = () => {
		setIsFetching(true);
		getSingleList(`series=${props.match.params.listSlug}&per_page=10&paged=${nextPage}&sort_by=${sortBy && sortBy.code.toLowerCase()}`)
			.then((res) => {
				const lengthNow = postsArr.length + res.json.posts.length;
				if (lengthNow >= res.response.headers.get("x-wp-total")) {
					setIsFinished(true);
				}
				res = res.json;
				setSeoData(res.seo);
				setPostsArr([...postsArr, ...res.posts]);
				setListData(res);
				setIsFetching(false);
				setnextPage(nextPage + 1);
			})
			.catch((err) => {
				console.log(err);
				setIsFinished(true);
			});
	};

	//did mount
	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("single-list-page");
		// if (localStorage.getItem('listSortBy')) {
		//     setSortBy(JSON.parse(localStorage.getItem('listSortBy')));
		// }
		return () => {
			document.getElementsByTagName("body")[0].classList.remove("single-list-page");
		};
	}, []);

	//For observer
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (!isFetching) {
				// console.log("Is data finished: ", isFinished);
			}
			!isFetching && !isFinished && GetSingleList();
		}
	};
	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0
		};
		// Create observer
		const observer = new IntersectionObserver(handleObserver, options);
		// observe the loader
		if (loader && loader.current) {
			observer.observe(loader.current);
		}
		// clean up on willUnMount
		return () => observer.unobserve(loader.current);
	}, [loader, handleObserver]);

	// update the tags on load
	useEffect(() => {
		postsArr.forEach((post) => {
			store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
		});
	}, [postsArr]);

	//reset tags when sortby changes
	useEffect(() => {
		store.dispatch({ type: RESET_TAGS });
		store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
	}, [sortBy]);

	const _sortByChange = (e) => {
		setSortBy({ name: e, code: e });
		setnextPage(1);
		setIsFinished(false);
		setIsFetching(false);
		setPostsArr([]);
		localStorage.setItem("listSortBy", JSON.stringify(e));
	};

	useEffect(()=>{
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({'piki_page':'lists'})
	  },[props.history])

	const _onShareClick = () => {
		if (navigator.share || navigator.canShare) {
			try {
				navigator
					.share({ title: "PikiLinks", url: `https://${window.location.host}/lists/${listData.slug}` })
					.then(() => console.log("Hooray! Your content was shared to tha world"))
					.catch((err) => {
						// alert("navigator.share catch", err);
					});
			} catch (error) {
				console.log(`Oops! Link couldnt be shared: ${error}`);
			}
		} else {
			setShareBlockShowing(true);
			setLinkCopied(false);
		}
	};

	const _openPostModal = (id) => {
		store.dispatch({ type: SET_POST_CARD_DATA_MODAL, payload: { postCardModal: true, activePostCardModal: id } });
	};

	const _pop = (type) => {
		popWindow(type, listData.name, `https://${window.location.host}/list/${listData.slug}`);
	};

	const PostsList = useMemo(() => {
		return postsArr
			.filter((post) => {
				return props.activeTag ? post.tags.find((myTag) => myTag.term_id === props.activeTag.term_id) : true;
			})
			.map((post, index) => {
				return (
					<PostCard
						postsArr={postsArr}
						onOpen={() => _openPostModal(post.ID)}
						key={index}
						upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === post.ID)}
						postData={post}
						postId={index}
					/>
				);
			});
	}, [postsArr, props.activeTag]);

	document.getElementsByTagName("body")[0].id = `list-page-${listData.term_id}`;

	return (
		<div className="p-grid p-fluid dashboard list-page">
			{!isEmpty(seoData) && <DynamicMetaDecorator metaDataPerRoute={seoData} />}
			<div className="p-col-12 p-lg-12">
				<div className="p-col-12 underlined-border" style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
					<b style={{ flex: 0.95 }}>{listData.name}</b>
					<Popup
						content={
							<span className="hideShareOption list-hideShareOption " style={{ backgroundColor: darkMode ? "#2E3035" : "#EDF0F5", color: "#8A8A8A" }}>
								<span>
									<div>
										<span className="icon-con-social" onClick={() => _pop("linkedin")}>
											<i className="flaticon-linkedin"></i> &nbsp;
											<b>LinkedIn</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("reddit")}>
											<i className="flaticon-reddit-1"></i> &nbsp;
											<b>Reddit</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("twitter")}>
											<i className="flaticon-twitter"></i> &nbsp;
											<b>Twitter</b>
										</span>
									</div>
									<div>
										<span className="icon-con-social" onClick={() => _pop("facebook")}>
											<i className="flaticon-facebook"></i> &nbsp;
											<b>Facebook</b>
										</span>

										<span className="icon-con-social" onClick={() => _pop("messenger")}>
											<i className="flaticon-messenger"></i> &nbsp;
											<b>Messenger</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("gmail")}>
											<i className="flaticon-email-1"></i> &nbsp;
											<b>Email</b>
										</span>
									</div>
								</span>
								<span
									className="icon-con-social"
									onClick={() => {
										_pop("link");
										setLinkCopied(true);
									}}
								>
									<div>
										<Input
											size="mini"
											action={{
												labelPosition: "right",
												icon: "copy",
												content: linkCopied ? "Copied" : "Copy Link"
											}}
											defaultValue={`https://${window.location.host}/list/${listData.slug}`}
										/>
									</div>
								</span>
							</span>
						}
						on="click"
						className="tags-popup share-popup"
						onClose={() => setShareBlockShowing(false)}
						onOpen={() => {
							_onShareClick();
						}}
						open={shareBlockShowing}
						basic
						trigger={
							<Label style={{ marginRight: "8px", cursor: "pointer", color: "#8A8A8A", maxWidth: "100px", minWidth: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
								<Icon name="share alternate" />
								Share
							</Label>
						}
					/>
				</div>
				<div className="p-col-12 post-filter d-flex-end">
					<span>
						<CustomDropdown handleOnClick={_sortByChange} sortByValue={sortBy.code ? sortBy.code : "Sort By"} dropdownName="Sort By" dropdownOptions={sortByOptions} />
						{/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={sortByOptions} value={sortBy} /> */}
					</span>
				</div>
			</div>
			{PostsList}

			<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
				{isFetching && !isFinished && <Loader loadingMore={true} />}
				{isFinished && postsArr.length < 1 && (
					<div className="text-centered">
						<b>No Links found</b>
					</div>
				)}
			</div>
		</div>
	);
};

List.propsTypes = {
	activeTag: PropTypes.object
};

const mapStateToProps = (state) => ({
	activeTag: state.world.activeTag
});

export default withRouter(
	connect(
		mapStateToProps,
		{}
	)(List)
);
