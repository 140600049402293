import React from 'react';
import "./error.scss";

const ErrorMessage = ({message}) => {
    return (
        <div className="error">{message}</div>
    )
}

export default ErrorMessage
