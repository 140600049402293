import React, { useRef, useMemo, useState, useEffect, useCallback, useSelector } from "react";

//from packages
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import PostModal from "../Post/PostModal";

//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";
import CoverSlider from "../../components/CoverSlider/CoverSlider";
//hooks
import { usePrevious } from "../../hooks/usePrevious";

//apis
import { getPostsByCategory, getBanners } from "../../api/api";

//utils
import store from "../../redux/store";

//types
import { RESET_TAGS, SET_POST_CARD_DATA_MODAL, SET_ACTIVE_TAG, UPDATE_TAGS, SET_TAGS_BY_CATEGORY_POSTS_ORDER } from "../../redux/types";
import { isEmpty } from "../../validation/validation";
import { DynamicMetaDecorator } from "../../components/MetaDecorator/DynamicMetaDecorator";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import DefaultCover from "../../assets/img/default-cover.png";
import { withRouter } from "react-router-dom";

const sortByOptions = [
	{ name: "Random", code: "Random", index: 1 },
	{ name: "Popularity", code: "Popularity", index: 2 },
	{ name: "Newest", code: "Newest", index: 3 },
	{ name: "Oldest", code: "Oldest", index: 4 }
];

const Category = (props) => {
	const slides = [
		{
			image: props.settings.darkMode ? DefaultCover : DefaultCover,
			default: true
		},
		{
			image: props.settings.darkMode ? DefaultCover : DefaultCover,
			default: true
		},
		{
			image: props.settings.darkMode ? DefaultCover : DefaultCover,
			default: true
		}
	];
	const [postsArr, setPostsArr] = useState([]);
	const [coverBannerData, setCoverBannerData] = useState([...slides]);
	const [sortBy, setSortBy] = useState(sortByOptions[0].code);
	const loader = useRef(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const [nextPage, setNextPage] = useState(1);
	const [catName, setCatName] = useState("");
	const [catId, setCatId] = useState("");
	const darkMode = props.settings.darkMode;

	const [categoryColor, setCategoryColor] = useState("linear-gradient(#0040ff, #8000ff, #ff00bf, #ff0000, #ff8000, #40ff00, #00ffbf)");

	const [seoData, setSeoData] = useState({});

	useEffect(()=>{
      window.dataLayer = window.dataLayer || []
	  window.dataLayer.push({'piki_page':'category'})
	},[props.history])

	useEffect(() => {
		const controller = new AbortController();
		let pathA = props.history.location.pathname.split("/");
		let catslug = pathA[2] ? pathA[2] : "";
		getBanners(`?group=${catslug}`, controller.signal)
			.then((res) => {
				if (res.response.ok) {
					setCoverBannerData([...res.json]);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		return () => controller.abort();
	}, []);

	//api call
	const GetPostsByCategory = () => {
		setIsFetching(true);

		getPostsByCategory(`category=${props.match.params.catSlug}&per_page=10&paged=${nextPage}&sort_by=${sortBy.toLowerCase()}`)
			.then((res) => {
				const lengthNow = postsArr.length + res.json.posts.length;
				if (lengthNow >= res.response.headers.get("x-wp-total")) {
					setIsFinished(true);
				}
				res = res.json;
				setCatName(res.cat_name);
				setCatId(res.cat_ID);
				setSeoData(res.seo);
				setPostsArr([...postsArr, ...res.posts]);
				setIsFetching(false);
				setNextPage(nextPage + 1);
			})
			.catch((err) => {
				console.log(err);
				setIsFinished(true);
			});
	};

	//did mount
	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("category-page");
		document.getElementsByTagName("body")[0].removeAttribute("id");

		if (localStorage.getItem("categorySortBy")) {
			setSortBy(JSON.parse(localStorage.getItem("categorySortBy")));
		}

		return () => {
			document.getElementsByTagName("body")[0].classList.remove("category-page");
			document.getElementById("app-hue-bar").style.background = "linear-gradient(#0040ff, #8000ff, #ff00bf, #ff0000, #ff8000, #40ff00, #00ffbf)";
		};
	}, []);

	//when the category list is loaded
	useEffect(() => {
		const activeCategory = props.categories.categories.filter((cat) => cat.slug === props.match.params.catSlug)[0];
		document.getElementById("app-hue-bar").style.background = activeCategory && activeCategory.color;
	}, [props.location.pathname, props.categories]);

	//For observer
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (!isFetching) {
				// console.log("Is data finished: ", isFinished);
			}
			!isFetching && !isFinished && GetPostsByCategory();
		}
	};

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0
		};
		// Create observer
		const observer = new IntersectionObserver(handleObserver, options);
		// observe the loader
		if (loader && loader.current) {
			observer.observe(loader.current);
		}
		// clean up on willUnMount
		return () => observer.unobserve(loader.current);
	}, [loader, handleObserver]);

	//update the tags on load
	useEffect(() => {
		let postTags = [];
		postsArr.forEach((post) => {
			store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
			for (let newTag of post.tags || []) {
				if (!postTags.find((tag) => tag.term_id === newTag.term_id)) {
					postTags.push(newTag);
				}
			}
		});
		store.dispatch({ type: SET_TAGS_BY_CATEGORY_POSTS_ORDER, payload: postTags });
	}, [postsArr]);

	//reset tags when sortby changes
	useEffect(() => {
		store.dispatch({ type: RESET_TAGS });
		store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
	}, [sortBy]);

	const _sortByChange = async (e) => {
		setNextPage(1);
		setPostsArr([]);
		setSortBy(e);
		setIsFetching(false);
		setIsFinished(false);
		localStorage.setItem("categorySortBy", JSON.stringify(e));
	};

	const _openPostModal = (id) => {
		store.dispatch({ type: SET_POST_CARD_DATA_MODAL, payload: { postCardModal: true, activePostCardModal: id } });
	};

	const _closePostModal = () => {
		store.dispatch({ type: SET_POST_CARD_DATA_MODAL, payload: { postCardModal: false, activePostCardModal: null } });
		// setPostModalData({
		// 	showModal: false,
		// 	id: null
		// });
	};

	const PostsList = useMemo(() => {
		return [...new Set([...postsArr])]
			.filter((post) => {
				return props.activeTag ? post.tags.find((myTag) => myTag.term_id === props.activeTag.term_id) : true;
			})
			.map((post, index) => {
				return (
					<PostCard
						postsArr={postsArr}
						onOpen={() => _openPostModal(post.ID)}
						key={index}
						upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === post.ID)}
						postData={post}
						postId={index}
					/>
				);
			});
	}, [postsArr, props.activeTag]);

	const activeMenuitem = document.getElementsByClassName("active-menuitem")[0];
	try {
		if (activeMenuitem) {
			var s = activeMenuitem.childNodes[0].innerHTML.split("span")[1];
			const a = ">";
			const b = "</";
			var p = s.indexOf(a) + a.length;
			var categoryName = s.substring(p, s.indexOf(b, p));

			var categoryId = activeMenuitem.className.split(" ")[2].split("-")[2];
		}
	} catch (e) {
		console.log(e);
	}

	const _setActiveSlide = (slide, curCat) => {
		if (localStorage.getItem("userToken")) {
			let currentActiveCatInfo = [...props.settings.activeSlideCategoryArr];
			let curPath = props.history.location.pathname.split("/");
			let curPath2 = curPath[2] ? curPath[2] : "";
			let filteredActiveSlide = currentActiveCatInfo.filter((data, index) => data.cat !== curPath2);
			store.dispatch({ type: "SET_ACTIVE_SLIDE_CATEGORY", payload: slide, catArr: [{ cat: curCat, active: slide }, ...filteredActiveSlide] });
		} else {
			let currentActiveCatInfo = [...props.settings.unauthActiveSlideCategoryArr];
			let curPath = props.history.location.pathname.split("/");
			let curPath2 = curPath[2] ? curPath[2] : "";
			let filteredActiveSlide = currentActiveCatInfo.filter((data, index) => data.cat !== curPath2);
			store.dispatch({ type: "SET_UNAUTH_ACTIVE_SLIDE_CATEGORY", payload: slide, catArr: [{ cat: curCat, active: slide }, ...filteredActiveSlide] });
		}
	};

	let categoriesInfo = localStorage.getItem("userToken")
		? props.categories.authCategories
			? [...props.categories.authCategories]
			: []
		: props.categories.categories
		? [...props.categories.categories]
		: [];
	let curPath = props.history.location.pathname.split("/");
	let curPath2 = curPath[2] ? curPath[2] : "";
	let filteredCategory = categoriesInfo.filter((data, index) => data.slug === curPath2);

	if (store.getState().world.menuItems.length > 0) {
		const categoryId = store.getState().world.menuItems.find((cat) => {
			return cat.slug === props.match.params.id;
		});
		document.getElementsByTagName("body")[0].id = `category-page-${categoryId}`;
	}
	let isMobile = window.innerWidth <= 992;

	let newPath2 = props.history.location.pathname.split("/");
	let fin2 = newPath2[2] ? newPath2[2] : "";
	let activeSlide = localStorage.getItem("userToken")
		? (!isEmpty(props.settings.activeSlideCategoryArr) ? props.settings.activeSlideCategoryArr : [{ cat: "", active: 0 }]).filter((da, ind) => da.cat === fin2)
		: (!isEmpty(props.settings.unauthActiveSlideCategoryArr) ? props.settings.unauthActiveSlideCategoryArr : [{ cat: "", active: 0 }]).filter((da, ind) => da.cat === fin2);

	return (
		<div className={`p-grid p-fluid dashboard page-category`} id={`page-category-${props.match.params.id}`}>
			{!isEmpty(seoData) && <DynamicMetaDecorator metaDataPerRoute={seoData} />}
			{props.settings.bannersMode ? (
				<div
					className={`${isMobile ? "CoverBannerMobile" : "CoverBanner"} CatBanner banner-category-${catId}`}
					style={{
						paddingTop: isEmpty(coverBannerData) ? "20px" : "",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: isEmpty(coverBannerData) ? (filteredCategory ? (filteredCategory[0] && filteredCategory[0].color ? filteredCategory[0].color : "#228FE7") : "#228FE7") : "transparent",
						minHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "30vh") : "",
						maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : ""
					}}
				>
					<CoverSlider
						isMobile={isMobile}
						darkMode={darkMode}
						activeSlide={activeSlide && activeSlide[0] ? activeSlide[0].active : 0}
						setActiveSlide={(next) => {
							_setActiveSlide(next, fin2);
						}}
						cattitle={isEmpty(coverBannerData) ? (filteredCategory ? (filteredCategory[0] && filteredCategory[0].name ? filteredCategory[0].name : "Unknown") : "Unknown") : ""}
						slides={coverBannerData}
					/>
				</div>
			) : null}
			<div className="p-col-12 p-lg-12">
				{(!props.settings.bannersMode || !isEmpty(coverBannerData)) && (
					<div className={`p-col-12 category-title-${categoryId}`} style={{ marginTop: coverBannerData.length === 1 ? (props.settings.bannersMode ? "-20px" : "0px") : "" }}>
						<b>{catName}</b>
					</div>
				)}
				<div className="p-col-12 post-filter" style={{display:'flex',alignItems:'center',justifyContent:props.settings.bannersMode ? 'space-between':'flex-end',marginTop:isEmpty(coverBannerData) ? '0px':"-20px"}} >
					{props.settings.bannersMode && <div className="cat-separator"></div>}
					<span>
						<CustomDropdown handleOnClick={_sortByChange} sortByValue={sortBy ? sortBy : "Sort By"} dropdownName="Sort By" dropdownOptions={sortByOptions} />
						{/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={sortByOptions} value={sortBy} /> */}
					</span>
				</div>
			</div>
			{PostsList}
			{/* <PostModal open={props.postCardModal} postData={props.postModalData} onClose={() => _closePostModal()} /> */}

			<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
				{isFetching && !isFinished && <Loader loadingMore={true} />}
				{isFinished && postsArr.length < 1 && (
					<div className="text-centered">
						<b>No links found</b>
					</div>
				)}
			</div>
		</div>
	);
};

Category.propsTypes = {
	activeTag: PropTypes.object,
	categories: PropTypes.object.isRequired,
	tagsByCatPosts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	activeTag: state.world.activeTag,
	categories: state.categories,
	tagsByCatPosts: state.world.tagsByCatPosts,
	postModalData: state.world.postModalData,
	settings: state.settings
});

export default connect(
	mapStateToProps,
	{}
)(withRouter(Category));
