import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
	<div>
		<div className="goHomeRow"></div>

		<div className="notFound">
			<h1>404 - Not Found!</h1>
		</div>
	</div>
);

export default NotFound;
