import React, { useEffect, useState } from "react";

//from packages
import urlRegex from "url-regex";
import PropTypes from "prop-types";
import {
  Popup,
  // Grid, Placeholder, Loader
} from "semantic-ui-react";
import { Label } from "semantic-ui-react";
import { Button } from "primereact/button";
import { NavLink, withRouter } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import "@fortawesome/fontawesome-free/css/all.min.css";

//components
import SimilarPosts from "./SimilarPosts";

//utils
import { connect, useSelector } from "react-redux";
import store from "../../redux/store";
import { popWindow } from "../../utils/manageShare";
import { toast } from "react-toastify";

//apis
import { upvotePost, getUpvotes, getFavIcons } from "../../api/api";
import Axios from "axios";
import GLOBALS from "../../constants";
//types
import {
  SET_FAVOURITE_POSTS,
  SET_AUTH_FAVOURITE_POSTS,
  SEARCH_INPUT_SHOWING,
  SET_UPVOTES,
  SET_SUBSCRIBE_BUTTON_STATUS,
  SET_POST_MODAL_DATA,
} from "../../redux/types";
import loadingGif from "../../assets/img/main_loading.gif";

import { BiWindowOpen } from "react-icons/bi";
import { VscLinkExternal } from "react-icons/vsc";
import isEmpty from "../../utils/isEmpty";
import AccountLoader from "../Loader/AccountLoader";
import UpvoteLoader from "../Loader/UpvoteLoader";
import superVote from "../../assets/img/supervote.png";
import vote from "../../assets/img/upvote.png";
import novote from "../../assets/img/novote.png";
import openwindow from "../../assets/img/openwindow.png";
import openpopup from "../../assets/img/openpopup.png";
import opentabs from "../../assets/img/opentabs.png";
import previewselected from "../../assets/img/previewselected.png";
import previewunselected from "../../assets/img/previewunselected.png";
import tagsselected from "../../assets/img/tagsselected.png";
import tagsunselected from "../../assets/img/tagsunselected.png";
import similarselected from "../../assets/img/similarselected.png";
import similarunselected from "../../assets/img/similarunselected.png";
import shareunselected from "../../assets/img/shareunselected.png";
import shareselected from "../../assets/img/shareselected.png";

function PostCard(props) {
  let refs = null;
  const [isMobile, setIsMobile] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const [upvoteLoader, setUpvoteLoader] = useState(false);
  const [favouritesLoader, setFavouritesLoader] = useState(false);
  const [brokenImage, setBrokenImage] = useState(false);
  const [activesList, setActivesList] = useState({
    open: false,
    preview: false,
    share: false,
    up: false,
    star: false,
    upvote: false,
    tag: false,
  });
  const [postData, setPostData] = useState({});
  const [postVoteCount, setPostVoteCount] = useState();
  const [showDate, setShowDate] = useState(true);
  const [timer, setTimer] = useState(null);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const listMode = useSelector((state) => state.settings.listMode);
  const [popupLeft, setPopupLeft] = useState("top left");
  const [linkClass, setLinkClass] = useState("inpost-link");
  const [favActive, setFavActive] = useState(() => {
    return props.postData.favorited || false;
  });
  const [superVoteChecked, setSuperVoteChecked] = useState(false);
  const [voteChecked, setVoteChecked] = useState(true);
  // const [isUpvoteActive, setIsUpvoteActive] = useState(false);
  // const [isSuperVoteActive, setIsSuperVoteActive] = useState(false);

  useEffect(() => {
    if (!darkMode) {
      setLinkClass("inpost-link-light");
    }

    if (props.location.pathname === "/favourites") {
      if (darkMode) {
        setLinkClass("favourite-link");
      } else {
        setLinkClass("favourite-link-light");
      }
    }
    setPostVoteCount(props.postData.up_vote_count);
    // setIsUpvoteActive(props.postData.voted);
    // setIsSuperVoteActive(props.postData.super_voted);
    setPostData(props.postData);

    if (props.postId) {
      if ((props.postId + 1) % 2 === 0) {
        setPopupLeft("top right");
      }
    }

    if (props.replaceDate === "Newest") {
      setPopupLeft("top right");
    }
  }, [
    props.postData,
    isMobile,
    darkMode,
    props.postData.up_vote_count,
    props.postData.favorited,
  ]);

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setPopupLeft("top center");
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) {
        setPopupLeft("top center");
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    return () => {
      setShowDate(true);
    };
  }, []);
  let favicon = postData.favicon;
  if (brokenImage) {
    favicon = "https://www.comparemovies.info/static/favicon-16x16.png";
  } else {
    favicon = postData.favicon;
  }

  //destructure post data
  var {
    ID,
    post_title,
    user_submit_url,
    post_date,
    categories,
    tags,
    favorited,
    voted,
    category_color,
    color,
    attribute,
  } = postData;

  tags = tags || [];

  if (!props.postData) {
    return null;
  }

  const _pop = (type) => {
    popWindow(type, post_title, `https://${window.location.host}/link?L=${ID}`);
  };

  const _toggleActive = (field) => {
    setActivesList({
      ...activesList,
      [field]: !activesList[field],
    });
  };
  const _upvote = async (postId, voted) => {
    if (localStorage.getItem("userToken")) {
      let upvoteds = (await store.getState().world.upvotedPosts) || [];
      let isVoted = !!upvoteds.find((post) => post.ID === postId);

      let currentPost = upvoteds.find((post) =>
        post.ID === postId ? post : null
      );
      const controller = new AbortController();
      const signal = controller.signal;
      setUpvoteLoader(true);
      let voteType = superVoteChecked ? "super_vote" : "vote";
      let query = `?post_id=${postId}&vote_type=${voteType}`;
      await upvotePost(query, signal)
        .then((res) => {
          if (res && res.json && res.json.error) {
            notify(res.json.error);
            setUpvoteLoader(false);
            return;
          }
          setPostVoteCount(res.json.vote);
          setActivesList({ ...activesList, upvote: !isVoted });
          if (res.json.type === "downvote") {
            const postIdIndex = upvoteds.findIndex(
              (post) => post.ID === postId
            );
            upvoteds.splice(postIdIndex, 1);

            isUpvoteActive = false;
            notify("Unvote success");

            favouriteIsActive = true;
          } else if (res.json.type === "upvote") {
            // const postDataIndex = upvoteds.findIndex((post) => post.ID === postId);
            // let postData = upvoteds.find((post) => post.ID === postId);

            if (voteType === "super_vote") {
              upvoteds.push({
                ID: postId,
                up_vote_count: res.json.vote,
                voted: false,
                super_voted: true,
                new: true,
              });
              isSuperVoteActive = true;
              isUpvoteActive = false;
            } else {
              upvoteds.push({
                ID: postId,
                up_vote_count: res.json.vote,
                voted: true,
                super_voted: false,
                new: true,
              });
              isUpvoteActive = true;
              isSuperVoteActive = false;
            }
            notify("upvote success");
            // localStorage.setItem("upvotedPosts", JSON.stringify(upvoteds));
            favouriteIsActive = false;
          }
          setPostData({
            ...postData,
            up_vote_count: res.json.vote,
          });
          setActivesList({
            ...activesList,
            upvote: false,
          });
          store.dispatch({ type: SET_UPVOTES, payload: upvoteds });
          setUpvoteLoader(false);
        })
        .catch((err) => {
          setUpvoteLoader(false);
        });
    } else {
      notify("User must log in to vote");
    }
  };

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const _favourite = (e) => {
    if (localStorage.getItem("userToken")) {
      setFavouritesLoader(true);
      let formData = new FormData();
      formData.append("post_id", props.postData.ID);

      return Axios({
        url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("userToken"),
        },
        data: formData,
      })
        .then((res) => {
          let savedFavs = store.getState().favourite.authFavPosts || [];
          let foundPost = savedFavs.find((fav) => fav.ID === ID);
          if (res.data.type === "unfavorite") {
            savedFavs.splice(savedFavs.indexOf(foundPost), 1);
            favouriteIsActive = false;
            savedFavs.push({
              ...props.postData,
              favorited: false,
            });
          } else if (res.data.type === "favorite") {
            favouriteIsActive = true;
            savedFavs.unshift({ ...props.postData, favorited: true });
          }
          store.dispatch({
            type: SET_AUTH_FAVOURITE_POSTS,
            payload: savedFavs,
          });
          notify(res.data.message);
          setFavouritesLoader(false);
        })
        .catch((err) => {
          setFavouritesLoader(false);
        });
    } else {
      const savedFavs =
        JSON.parse(localStorage.getItem("favouritePosts")) || [];
      const foundPost = savedFavs.find((fav) => fav.ID === ID);
      if (foundPost) {
        savedFavs.splice(savedFavs.indexOf(foundPost), 1);
      } else {
        savedFavs.unshift(props.postData);
      }
      store.dispatch({ type: SET_FAVOURITE_POSTS, payload: savedFavs });
      notify("Sucessfully updated");
    }
  };

  const _enterMouse = async (e) => {
    setTimer(
      setTimeout(() => {
        setShowDate(false);
      }, 200)
    );
  };

  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const openNewWindow_ = (id, url) => {
    if (url) {
      popupWindow(
        url,
        "name",
        window,
        window.innerWidth - 450,
        window.innerHeight - 150
      );
    }
  };

  const _handleBrokenLink = (ev) => {
    setBrokenImage(true);
  };

  const _setPostModalData = (data) => {
    store.dispatch({
      type: SET_POST_MODAL_DATA,
      payload: { ...data, allPosts: props.postsArr },
    });
  };

  const _leaveMouse = async (e) => {
    clearTimeout(timer);
    setShowDate(true);
    setActivesList({
      // open: false,
      preview: false,
      share: false,
      up: false,
      star: false,
      upvote: false,
      tag: false,
    });
    setLinkCopied(false);
  };

  // localStorage.getItem("userToken") ? favorited :

  // let favouriteIsActive = false;
  // if ((localStorage.getItem("userToken") ? store.getState().favourite.authFavPosts || [] : store.getState().favourite.favPosts || []).find((post) => post.ID === ID)) {
  // 	favouriteIsActive = true;
  // }

  // let favouriteIsActive = localStorage.getItem("userToken") ? favorited : false;
  let favouriteIsActive = false;
  if (props.auth.user && props.auth.user.token) {
    const fav = store
      .getState()
      .favourite.authFavPosts.filter((post) => post.ID === ID);
    if (fav.length !== 0) {
      favouriteIsActive = fav[0].favorited;
    }
  } else {
    const favv = store
      .getState()
      .favourite.favPosts.find((post) => post.ID === ID);
    if (favv) {
      favouriteIsActive = true;
    } else {
      favouriteIsActive = false;
    }
  }

  let isSuperVoteActive = false;
  let isUpvoteActive = false;
  let upVoteCount = 0;
  // props.postData.up_vote_count;
  // const
  const postDataa = (
    (props.auth.user &&
      props.auth.user.token &&
      store.getState().world.upvotedPosts) ||
    []
  ).filter((post) => (post.ID && post.ID.toString()) === (ID && ID.toString()));

  if (postDataa.length !== 0) {
    if (postDataa[0] && postDataa[0].super_voted) {
      isUpvoteActive = false;
      isSuperVoteActive = true;
    } else {
      isUpvoteActive = true;
      isSuperVoteActive = false;
    }
    upVoteCount = postDataa[0].up_vote_count;
  } else {
    isUpvoteActive = false;
    upVoteCount = props.postData.up_vote_count;
  }

  let postIcon = null;
  let tooltip = "Tag";
  var tagLink = props.location.pathname;

  for (let tag of tags || []) {
    if (tag.name) {
      tooltip = tag.name;
    }

    if (tag.url) {
      postIcon = tag.url;
      tagLink = "/tags/" + tag.slug;

      break;
    }
  }

  const TagsBody = tags ? (
    tags.length > 0 ? (
      [...tags].map((tag, index) => {
        if (index < 12) {
          return (
            // <NavLink to={"/tags/" + tag.slug}>
            <Label
              onClick={() => {
                props.history.push("/tags/" + tag.slug);
              }}
              key={index}
              as="a"
              className={darkMode ? "rectangle-tags-dark" : "rectangle-tags"}
            >
              {tag.name}
            </Label>
            // </NavLink>
          );
        }
      })
    ) : (
      <div>No tags found!</div>
    )
  ) : (
    <div>No Tags found!</div>
  );

  let customClass = categories
    ? categories.reduce((accumulator, currentValue) => {
        return accumulator + `category-${currentValue} `;
      }, "")
    : "";

  if (props.location.pathname === "/most-recents") {
    var inRecents = true;
  }

  const customClassForLink = categories
    ? categories.reduce((accumulator, currentValue) => {
        return accumulator + `category-link-${currentValue} `;
      }, "")
    : "";

  const upvotedMe = (store.getState().world.upvotedPosts || []).find(
    (post) => post.ID === ID
  );
  const countV = (store.getState().world.upvotedPosts || []).find(
    (post) => post.ID === ID
  );

  const inListView = props.settings.listMode;

  // const previewImage = `https://api.miniature.io/?url=${user_submit_url}&token=${process.env.REACT_APP_IMAGE_PREVIEW_KEY}`

  const previewImage = `https://api.apiflash.com/v1/urltoimage?access_key=372f45770da54cc2896c36dc2dbded9b&url=${user_submit_url}&ttl=2592000&no_cookie_banners=false`;

  //set the link color to be the color of category
  try {
    const categoryOfPost = props.categories.filter(
      (cat) => cat.term_id === categories[0]
    )[0];
    document.getElementById(`link-${ID}`).style.color = categoryOfPost.color;
  } catch (e) {}

  const handleScroll = () => {};

  let isCardVisible = true;
  if (!isEmpty(postData.link_types)) {
    props.unCheckedLinks.forEach((element) => {
      if (postData.link_types.find((dd, ii) => dd.term_id === element)) {
        isCardVisible = false;
      }
    });
  }

  if (!isCardVisible) {
    return null;
  }
  // }

  const _onError = (err) => {
    // console.log("image error:", err);
  };

  const _onShareClick = () => {
    if (navigator.share || navigator.canShare) {
      try {
        navigator
          .share({
            title: "PikiLinks",
            url: `https://${window.location.host}/link?L=${ID}`,
          })
          .then(() =>
            console.log("Hooray! Your content was shared to tha world")
          )
          .catch((err) => {
            // alert("navigator.share catch", err);
          });
      } catch (error) {
        console.log(`Oops! Link couldnt be shared: ${error}`);
      }
    } else {
      setActivesList({ ...activesList, share: true });
    }
  };

  // if(document.getElementById("post-card-container-id") && document.getElementById("post-card-container-id").clientWidth){
  // 	console.log("post-card-container width",)
  // }

  return (
    <>
      {inListView && !props.forSingle ? (
        <div className="p-md-1 p-lg-2"></div>
      ) : null}
      {props.forModal && !isMobile ? (
        <>
          <div className={`p-col-12 p-lg-12 `}>
            <div
              className="card summary post-card single-preview"
              style={{
                height: "calc(100% - 20px)",
                display: "flex",
                alignItems: "center",
                padding: "0",
                boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
              }}
            >
              {user_submit_url ? (
                <img
                  className="single-preview-image"
                  onClick={() => window.open(user_submit_url, "_blank")}
                  src={previewImage}
                  alt="6"
                  style={{
                    alignSelf: "center",
                    margin: "auto",
                    height: "90%",
                    width: "100%",
                    backgroundImage: `url(${loadingGif})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "50% 50%",
                    backgroundPosition: "center",
                    borderStyle: "none",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div>
                  <div className="noImageText">No Image Available</div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      {props.forModal && isMobile && !inListView && inListView ? (
        <>
          <div className={`p-col-12 p-lg-12 `}>
            <div
              className="card summary post-card single-preview"
              style={{
                height: "calc(100% - 20px)",
                display: "flex",
                alignItems: "center",
                padding: "0",
                boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
              }}
            >
              {user_submit_url ? (
                <img
                  className="single-preview-image"
                  onClick={() => window.open(user_submit_url, "_blank")}
                  src={previewImage}
                  alt="6"
                  style={{
                    alignSelf: "center",
                    margin: "auto",
                    height: "90%",
                    width: "100%",
                    backgroundImage: `url(${loadingGif})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "50% 50%",
                    backgroundPosition: "center",
                    borderStyle: "none",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div>
                  <div className="noImageText">No Image Available</div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
      <div
        className={`p-col-12 ${
          inListView && !props.forSingle ? "p-md-12 p-lg-12" : "p-lg-6"
        } ${
          props.forModal ? "p-md-12 p-lg-12" : ""
        } post-card-container ${customClass}`}
        id={`post-${ID}`}
        style={{ padding: 0, zIndex: 10 }}
      >
        <div className={`p-col-12 p-lg-12 `} id={`post-card-container-id`}>
          <div
            className="card summary post-card"
            onMouseEnter={_enterMouse}
            onMouseLeave={_leaveMouse}
            style={{
              background: "#edf0f5",
              boxShadow: "10px 10px 12px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            {isMobile && inListView ? (
              <div className="post-card-image-listview-mobile">
                {user_submit_url ? (
                  <img
                    onClick={() => window.open(user_submit_url, "_blank")}
                    src={previewImage}
                    alt="1"
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      backgroundImage: `url(${loadingGif})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "30% 30%",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <div className="noImageText__mobile">No Image Available</div>
                )}
              </div>
            ) : null}
            {isMobile && !inListView && props.forSingle ? (
              <div className="post-card-image-listview-mobile">
                {user_submit_url ? (
                  <img
                    onClick={() => window.open(user_submit_url, "_blank")}
                    src={previewImage}
                    alt="1"
                    style={{
                      height: "100%",
                      width: "100%",
                      cursor: "pointer",
                      backgroundImage: `url(${loadingGif})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "30% 30%",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <div className="noImageText__mobile">No Image Available</div>
                )}
              </div>
            ) : null}
            <div className="post-card-body">
              <div
                className="p-grid"
                style={{
                  width: "100%",
                  marginRight: 0,
                  marginLeft: 0,
                }}
              >
                <div className={listMode && !isMobile ? "p-col-3" : "p-col-2"}>
                  <Button
                    className="for-tooltip p-button-link"
                    tooltip={tooltip}
                    tooltipOptions={{ position: "bottom" }}
                  >
                    {postIcon ? (
                      <img
                        onClick={
                          tagLink
                            ? () => {
                                if (props.forModal) {
                                  store.dispatch({
                                    type: "SET_POST_CARD_DATA_MODAL",
                                    payload: {
                                      postCardModal: false,
                                      activePostCardModal: null,
                                    },
                                  });
                                }
                                props.history.push(tagLink);
                                store.dispatch({
                                  type: SEARCH_INPUT_SHOWING,
                                  payload: false,
                                });
                              }
                            : () => {
                                store.dispatch({
                                  type: SEARCH_INPUT_SHOWING,
                                  payload: false,
                                });
                              }
                        }
                        alt="thumbnail card"
                        src={postIcon}
                        className="thumbnail-card"
                        style={{
                          maxWidth: "65px",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </Button>
                </div>
                <div
                  className={listMode && !isMobile ? "p-col-9" : "p-col-10"}
                  style={{ height: "145px" }}
                >
                  <div className="p-grid">
                    <div className="p-col-12">
                      {props.forSingle ? (
                        <NavLink
                          to={`/link?L=${ID}`}
                          onClick={() => {
                            store.dispatch({
                              type: SEARCH_INPUT_SHOWING,
                              payload: false,
                            });
                          }}
                          className={
                            props.forModal
                              ? darkMode
                                ? "post-title-dark"
                                : "post-title-light"
                              : "post-title"
                          }
                          style={{ color: darkMode ? "white" : "black" }}
                        >
                          {post_title}
                        </NavLink>
                      ) : (
                        <div className="post-link">
                          <span
                            onClick={() => {
                              _setPostModalData(postData);
                              props.onOpen();
                              store.dispatch({
                                type: SEARCH_INPUT_SHOWING,
                                payload: false,
                              });
                            }}
                            className="post-title"
                            style={{ color: darkMode ? "white" : "black" }}
                          >
                            {post_title}
                          </span>
                          <span
                            className={
                              darkMode
                                ? `open-new-post-page-dark`
                                : `open-new-post-page`
                            }
                          >
                            <NavLink to={`/link?L=${ID}`}>
                              {/* <BsBoxArrowUpRight
                                style={{ fontSize: "16px", marginTop: "-2px" }}
                              /> */}
                              <img
                                src={openpopup}
                                style={{
                                  marginTop: "-2px",
                                  width: "1.4em",
                                  height: "1.4em",
                                }}
                              />
                            </NavLink>
                          </span>
                        </div>
                      )}
                      <hr />
                      {props.settings.windowMode ? (
                        <span
                          // style={{ display: "flex", flexWrap: "wrap" }}
                          onClick={() => {
                            openNewWindow_(ID, user_submit_url);
                          }}
                          dangerouslySetInnerHTML={{
                            __html: (user_submit_url || "").replace(
                              urlRegex({
                                strict: false,
                              }),
                              function (url) {
                                if (favicon && props.settings.linkIcons) {
                                  return (
                                    '</><span class="favIconClass"><img id="favIconId-' +
                                    ID +
                                    '" src="' +
                                    favicon +
                                    '" onError=' +
                                    "document.getElementById('favIconId-" +
                                    ID +
                                    "').src='https://static.stands4.com/app_common/img/favicon_scrp.ico'" +
                                    " /></span><a " +
                                    // url +
                                    ` target="_blank" class= ${linkClass} ${customClassForLink}"` +
                                    `id="link-${ID}" style="color:${
                                      category_color || color || "#007ad9"
                                    } " >` +
                                    url
                                      .replace(
                                        /^(?:https?:\/\/)?(?:www\.)?/i,
                                        ""
                                      )
                                      .split("/")[0] +
                                    "</a></>"
                                  );
                                } else {
                                  return (
                                    "<a " +
                                    // url +
                                    ` target="_blank" class= ${linkClass} ${customClassForLink}"` +
                                    `id="link-${ID}" style="color:${
                                      category_color || color || "#007ad9"
                                    } " >` +
                                    url
                                      .replace(
                                        /^(?:https?:\/\/)?(?:www\.)?/i,
                                        ""
                                      )
                                      .split("/")[0] +
                                    "</a>"
                                  );
                                }
                              }
                            ),
                          }}
                          className="detail content-details"
                        ></span>
                      ) : (
                        <span
                          // onClick={() => window.open(user_submit_url, "_blank")}
                          dangerouslySetInnerHTML={{
                            __html: (user_submit_url || "").replace(
                              urlRegex({
                                strict: false,
                              }),
                              function (url) {
                                if (favicon && props.settings.linkIcons) {
                                  return (
                                    '</><span class="favIconClass"><img id="favIconId-' +
                                    ID +
                                    '" src="' +
                                    favicon +
                                    '" onError=' +
                                    "document.getElementById('favIconId-" +
                                    ID +
                                    "').src='https://static.stands4.com/app_common/img/favicon_scrp.ico'" +
                                    " /></span><a href='" +
                                    url +
                                    `' target="_blank" class= ${linkClass} ${customClassForLink}"` +
                                    `id="link-${ID}" style="color:${
                                      category_color || color || "#007ad9"
                                    } " >` +
                                    url
                                      .replace(
                                        /^(?:https?:\/\/)?(?:www\.)?/i,
                                        ""
                                      )
                                      .split("/")[0] +
                                    "</a></>"
                                  );
                                } else {
                                  return (
                                    "<a href='" +
                                    url +
                                    `' target="_blank" class= ${linkClass} ${customClassForLink}"` +
                                    `id="link-${ID}" style="color:${
                                      category_color || color || "#007ad9"
                                    } " >` +
                                    url
                                      .replace(
                                        /^(?:https?:\/\/)?(?:www\.)?/i,
                                        ""
                                      )
                                      .split("/")[0] +
                                    "</a>"
                                  );
                                }
                              }
                            ),
                          }}
                          className="detail content-details"
                        ></span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="p-col-12  post-card-footer">
                  <div
                    className={`p-md-3  p-sm-3  p-col-3 post-engagement-upvote `}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Popup
                        trigger={
                          <span>
                            <Popup
                              trigger={
                                <span className="highlight-wrapper">
                                  <span
                                    onClick={() => {
                                      if (isUpvoteActive || isSuperVoteActive) {
                                        setActivesList({
                                          ...activesList,
                                          upvote: false,
                                        });
                                        if (!upvoteLoader) {
                                          _upvote(ID, upvotedMe);
                                          store.dispatch({
                                            type: SEARCH_INPUT_SHOWING,
                                            payload: false,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    {
                                      upvoteLoader ? (
                                        <span style={{ fontSize: 20 }}>
                                          {" "}
                                          <UpvoteLoader bcolor={`#747474`} />
                                        </span>
                                      ) : isSuperVoteActive ? (
                                        <div
                                          style={{
                                            marginRight: "5px",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          <img
                                            src={superVote}
                                            style={{
                                              marginRight: "5px",
                                              width: "1.4em",
                                              height: "1.4em",
                                            }}
                                          />
                                          {/* <svg
                                          stroke="currentColor"
                                          fill="#8A8A8A"
                                          stroke-width="0"
                                          viewBox="0 0 16 16"
                                          height="1.3em"
                                          width="1.3em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M8.065 2.016A.13.13 0 0 0 8.002 2v11.983l6.856.017a.12.12 0 0 0 .066-.017.162.162 0 0 0 .054-.06.176.176 0 0 0-.002-.183L8.12 2.073a.146.146 0 0 0-.054-.057zm-1.043-.45a1.13 1.13 0 0 1 1.96 0l6.856 11.667c.458.778-.091 1.767-.98 1.767H1.146c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"></path>
                                        </svg> */}
                                        </div>
                                      ) : isUpvoteActive ? (
                                        <img
                                          src={vote}
                                          style={{
                                            marginRight: "5px",
                                            width: "1.4em",
                                            height: "1.4em",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={novote}
                                          style={{
                                            marginRight: "5px",
                                            width: "1.4em",
                                            height: "1.4em",
                                          }}
                                        />
                                      )
                                      // <i
                                      //   className={`glyph-icon flaticon-up ${
                                      //     isUpvoteActive ? "active" : ""
                                      //   }`}
                                      // ></i>
                                    }
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "0px",
                                      color: "#9E9E9E",
                                    }}
                                  >
                                    {upvoteLoader ? null : (
                                      <b>{postVoteCount}</b>
                                    )}
                                  </span>
                                </span>
                              }
                              className="tags-popup"
                              position="top center"
                              on="click"
                              basic
                              open={activesList.upvote}
                              onClose={() =>
                                setActivesList({
                                  ...activesList,
                                  upvote: false,
                                })
                              }
                              onOpen={() => {
                                if (!isUpvoteActive) {
                                  setActivesList({
                                    ...activesList,
                                    upvote: true,
                                  });
                                }
                              }}
                            >
                              <div
                                className={
                                  darkMode
                                    ? "upvote-container-dark"
                                    : "upvote-container"
                                }
                              >
                                <div className="upvote-container-item">
                                  <div className="upvote-container-item__icon">
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 16 16"
                                      height="0.9em"
                                      width="0.9em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="upvote-container-item__content">
                                    <span className="text-top">
                                      Upvoting costs 5 points
                                    </span>
                                    <span className="text-bottom">
                                      Do you want to use 5 points?
                                    </span>
                                  </div>
                                  <div className="upvote-container-item__checkbox">
                                    <div className="upvote-input-container">
                                      <input
                                        checked={voteChecked}
                                        type="checkbox"
                                        value="vote"
                                        onChange={(e) => {
                                          setVoteChecked(e.target.checked);
                                          setSuperVoteChecked(false);
                                        }}
                                      />
                                      <label for="customCheckbox"></label>
                                    </div>
                                  </div>
                                </div>
                                <div className="upvote-container-item">
                                  <div className="upvote-container-item__icon">
                                    <img
                                      src={superVote}
                                      style={{
                                        width: "2.2em",
                                        height: "2.2em",
                                      }}
                                    />
                                  </div>
                                  <div className="upvote-container-item__content">
                                    <span className="text-top">
                                      1 Super Vote equals 5 Upvotes
                                    </span>
                                    <span className="text-bottom">
                                      Do you want to use 20 points?
                                    </span>
                                  </div>
                                  <div className="upvote-container-item__checkbox">
                                    <div className="upvote-input-container">
                                      <input
                                        checked={superVoteChecked}
                                        type="checkbox"
                                        value="super-vote"
                                        onChange={(e) => {
                                          setSuperVoteChecked(e.target.checked);
                                          setVoteChecked(false);
                                        }}
                                      />
                                      <label for="customCheckbox"></label>
                                    </div>
                                  </div>
                                </div>
                                <div className="upvote-container-actions">
                                  <div
                                    className={
                                      darkMode ? "sub-btn-dark" : "sub-btn"
                                    }
                                  >
                                    <div className="auth-btns">
                                      <a
                                        className="blue"
                                        onClick={() => {
                                          // deny
                                          setActivesList({
                                            ...activesList,
                                            upvote: false,
                                          });
                                        }}
                                      >
                                        Deny
                                      </a>
                                      <a
                                        className="blue"
                                        onClick={() => {
                                          // allow
                                          if (!upvoteLoader) {
                                            _upvote(ID, upvotedMe);
                                            store.dispatch({
                                              type: SEARCH_INPUT_SHOWING,
                                              payload: false,
                                            });
                                          }
                                        }}
                                      >
                                        Allow
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Popup>
                          </span>
                        }
                        content={
                          upvoteLoader
                            ? "Loading"
                            : isUpvoteActive
                            ? "Unvote"
                            : "Upvote"
                        }
                        inverted
                        position="bottom center"
                        size="mini"
                      />
                    </span>
                  </div>
                  {/* <div className="p-md-7  p-sm-7 p-col-7">
										<span>title</span>
									</div> */}
                  <div className="p-md-7  p-sm-7 p-col-7 post-engagement-icon">
                    {showDate || (!isMobile && props.forSingle) ? (
                      props.replaceDate ? (
                        <b className="mobile-font" style={{ color: "gray" }}>
                          {props.replaceDate}
                        </b>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {props.forModal ||
                          isMobile ||
                          !props.forSingle ? null : !props.settings
                              .windowMode ? (
                            <Popup
                              trigger={
                                <span
                                  className={`icon-con-star-card ${
                                    favouriteIsActive ? "active" : ""
                                  }`}
                                >
                                  <Popup
                                    trigger={
                                      <span
                                        onClick={() => {
                                          openNewWindow_(ID, user_submit_url);
                                        }}
                                      >
                                        {/* <BiWindowOpen
                                          style={{
                                            fontSize: "24px",
                                            color: darkMode
                                              ? "#9E9E9E"
                                              : "#9E9E9E",
                                          }}
                                        /> */}
                                        <img
                                          src={openwindow}
                                          style={{
                                            // marginRight: "5px",
                                            width: "1.85em",
                                            height: "1.85em",
                                          }}
                                        />
                                        {/* <i class={`fas fa-external-link-alt`}></i> */}
                                      </span>
                                    }
                                    className="tags-popup"
                                    position="top center"
                                    on="click"
                                    basic
                                    open={activesList.open}
                                    onClose={() =>
                                      setActivesList({
                                        ...activesList,
                                        open: false,
                                      })
                                    }
                                    onOpen={() =>
                                      setActivesList({
                                        ...activesList,
                                        open: true,
                                      })
                                    }
                                  ></Popup>
                                </span>
                              }
                              content={"Open"}
                              inverted
                              position="bottom center"
                              disabled={activesList.open}
                              size="mini"
                            />
                          ) : (
                            <Popup
                              trigger={
                                <span
                                  className={`icon-con-star-card ${
                                    favouriteIsActive ? "active" : ""
                                  }`}
                                >
                                  <Popup
                                    trigger={
                                      <span>
                                        <a
                                          href={user_submit_url}
                                          target="_blank"
                                        >
                                          <img
                                            src={opentabs}
                                            style={{
                                              // marginRight: "5px",
                                              width: "1.85em",
                                              height: "1.85em",
                                            }}
                                          />
                                          {/* <VscLinkExternal
                                            style={{
                                              fontSize: "19px",
                                              color: darkMode
                                                ? "#9E9E9E"
                                                : "#9E9E9E",
                                            }}
                                          /> */}
                                          {/* <i style={{ fontSize: "17px" }} class={`fas fa-external-link-alt`}></i> */}
                                        </a>
                                      </span>
                                    }
                                    className="tags-popup"
                                    position="top center"
                                    on="click"
                                    basic
                                    open={activesList.open}
                                    onClose={() =>
                                      setActivesList({
                                        ...activesList,
                                        open: false,
                                      })
                                    }
                                    onOpen={() =>
                                      setActivesList({
                                        ...activesList,
                                        open: true,
                                      })
                                    }
                                  ></Popup>
                                </span>
                              }
                              content={"Open"}
                              inverted
                              position="bottom center"
                              disabled={activesList.open}
                              size="mini"
                            />
                          )}
                          <span
                            style={{
                              display: props.forSingle ? "none" : "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            className="datee"
                          >
                            <span
                              className={`highlight-wrapper ${
                                inRecents ? "highlighted" : ""
                              }`}
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className={"date-text"}
                                style={{
                                  color: "rgba(160,160,160,1)",
                                  lineHeight: "16px",
                                  paddingRight: "5px",
                                  fontSize: "0.9em",
                                }}
                              >
                                {new Date(post_date).toDateString()}
                              </div>
                              <i
                                className="pi pi-clock "
                                style={{
                                  opacity: ".8",
                                  fontSize: "1.5em",
                                }}
                              ></i>
                            </span>
                          </span>
                        </div>
                      )
                    ) : (
                      <>
                        {props.forModal ||
                        (isMobile && !props.forSingle) ? null : !props.settings
                            .windowMode ? (
                          <Popup
                            trigger={
                              <span
                                className={`icon-con-star-card ${
                                  favouriteIsActive ? "active" : ""
                                }`}
                              >
                                <Popup
                                  trigger={
                                    <span
                                      onClick={() => {
                                        openNewWindow_(ID, user_submit_url);
                                      }}
                                    >
                                      {/* <BiWindowOpen
                                        style={{
                                          fontSize: "24px",
                                          color: darkMode
                                            ? "#9E9E9E"
                                            : "#9E9E9E",
                                        }}
                                      /> */}
                                      <img
                                        src={openwindow}
                                        style={{
                                          // marginRight: "5px",
                                          width: "1.85em",
                                          height: "1.85em",
                                        }}
                                      />
                                      {/* <i class={`fas fa-external-link-alt`}></i> */}
                                    </span>
                                  }
                                  className="tags-popup"
                                  position="top center"
                                  on="click"
                                  basic
                                  open={activesList.open}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      open: false,
                                    })
                                  }
                                  onOpen={() =>
                                    setActivesList({
                                      ...activesList,
                                      open: true,
                                    })
                                  }
                                ></Popup>
                              </span>
                            }
                            content={"Open"}
                            inverted
                            position="bottom center"
                            disabled={activesList.open}
                            size="mini"
                          />
                        ) : (
                          <Popup
                            trigger={
                              <span
                                className={`icon-con-star-card ${
                                  favouriteIsActive ? "active" : ""
                                }`}
                              >
                                <Popup
                                  trigger={
                                    <span>
                                      <a href={user_submit_url} target="_blank">
                                        {/* <VscLinkExternal
                                          style={{
                                            fontSize: "19px",
                                            color: darkMode
                                              ? "#9E9E9E"
                                              : "#9E9E9E",
                                          }}
                                        /> */}
                                        <img
                                          src={opentabs}
                                          style={{
                                            // marginRight: "5px",
                                            width: "1.85em",
                                            height: "1.85em",
                                          }}
                                        />
                                        {/* <i style={{ fontSize: "17px" }} class={`fas fa-external-link-alt`}></i> */}
                                      </a>
                                    </span>
                                  }
                                  className="tags-popup"
                                  position="top center"
                                  on="click"
                                  basic
                                  open={activesList.open}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      open: false,
                                    })
                                  }
                                  onOpen={() =>
                                    setActivesList({
                                      ...activesList,
                                      open: true,
                                    })
                                  }
                                ></Popup>
                              </span>
                            }
                            content={"Open"}
                            inverted
                            position="bottom center"
                            disabled={activesList.open}
                            size="mini"
                          />
                        )}
                        {(props.forSingle && !isMobile) ||
                        inListView ? null : props.forModal ? null : (
                          <Popup
                            trigger={
                              <span>
                                <Popup
                                  trigger={
                                    <span>
                                      {/* <i
                                        className={`glyph-icon flaticon-square ${
                                          activesList.preview ? "active" : ""
                                        }`}
                                      ></i> */}
                                      <img
                                        src={
                                          activesList.preview
                                            ? previewselected
                                            : previewunselected
                                        }
                                        style={{
                                          // marginRight: "5px",
                                          width: "1.85em",
                                          height: "1.85em",
                                        }}
                                      />
                                    </span>
                                  }
                                  className="tags-popup"
                                  position={popupLeft}
                                  on="click"
                                  basic
                                  open={activesList.preview}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      preview: false,
                                    })
                                  }
                                  onOpen={() =>
                                    setActivesList({
                                      ...activesList,
                                      preview: true,
                                    })
                                  }
                                >
                                  <div
                                    style={
                                      isMobile
                                        ? {
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "90vw",
                                            height: "35vh",
                                          }
                                        : {
                                            width: "600px",
                                            height: "350px",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }
                                    }
                                  >
                                    {user_submit_url ? (
                                      <img
                                        onClick={() =>
                                          window.open(user_submit_url, "_blank")
                                        }
                                        src={previewImage}
                                        alt="2"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          cursor: "pointer",
                                          backgroundImage: `url(${loadingGif})`,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "10% 20%",
                                          backgroundPosition: "center",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : (
                                      <div>
                                        <div className="noImageText">
                                          No Image Available
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Popup>
                              </span>
                            }
                            content={"Preview"}
                            inverted
                            position="bottom left"
                            disabled={activesList.preview}
                            size="mini"
                          />
                        )}

                        {!isMobile &&
                        props.forSingle ? null : props.forModal ? null : (
                          <Popup
                            trigger={
                              <span>
                                <Popup
                                  trigger={
                                    <span>
                                      {/* <i
                                        className={`glyph-icon flaticon-tag ${
                                          activesList.tag ? "active" : ""
                                        }`}
                                      ></i> */}
                                      <img
                                        src={
                                          activesList.tag
                                            ? tagsselected
                                            : tagsunselected
                                        }
                                        style={{
                                          // marginRight: "5px",
                                          width: "1.85em",
                                          height: "1.85em",
                                        }}
                                      />
                                    </span>
                                  }
                                  className="tags-popup"
                                  position="top center"
                                  on="click"
                                  basic
                                  open={activesList.tag}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      tag: false,
                                    })
                                  }
                                  onOpen={() => {
                                    setActivesList({
                                      ...activesList,
                                      tag: true,
                                    });
                                  }}
                                >
                                  <div
                                    style={{
                                      width:
                                        document.getElementById(
                                          "post-card-container-id"
                                        ) &&
                                        document.getElementById(
                                          "post-card-container-id"
                                        ).clientWidth
                                          ? isMobile
                                            ? document.getElementById(
                                                "post-card-container-id"
                                              ).clientWidth -
                                              30 +
                                              "px"
                                            : document.getElementById(
                                                "post-card-container-id"
                                              ).clientWidth -
                                              70 +
                                              "px"
                                          : "100%",
                                      flexWrap: "nowrap",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="tags-scrollbar"
                                  >
                                    <div style={{ padding: "0px" }}>
                                      <Label.Group
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          flexWrap: "nowrap",
                                          overflowX: "auto",
                                          scrollbarWidth: "thin",
                                        }}
                                        className="tags-scrollbar-thin"
                                      >
                                        {TagsBody}
                                      </Label.Group>
                                    </div>
                                    {/* <hr /> */}
                                    {isEmpty(postData.link_types) ? null : (
                                      <>
                                        <div
                                          className="postLinkTypes"
                                          style={{
                                            marginTop: "7px",
                                            padding: "5px 0px 5px 0px",
                                            minHeight: "20px",
                                            maxWidth: "100%",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            backgroundColor: darkMode
                                              ? "#343434"
                                              : "#E8E8E8",
                                            borderRadius: darkMode
                                              ? "5px"
                                              : "7px",
                                          }}
                                        >
                                          {postData.link_types
                                            ? postData.link_types.map(
                                                (data, ind) => {
                                                  return (
                                                    <span>{data.name}</span>
                                                  );
                                                }
                                              )
                                            : ""}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Popup>
                              </span>
                            }
                            content={"Tags"}
                            inverted
                            position="bottom center"
                            disabled={activesList.tags}
                            size="mini"
                          />
                        )}

                        {!isMobile &&
                        props.forSingle ? null : props.forModal ? null : (
                          <Popup
                            trigger={
                              <span
                                className={`icon-con-star-card ${
                                  favouriteIsActive ? "active" : ""
                                }`}
                              >
                                <Popup
                                  trigger={
                                    <span
                                      onClick={() => {
                                        _toggleActive("up");
                                      }}
                                    >
                                      {/* <i
                                        className={`glyph-icon flaticon-up-2 ${
                                          activesList.up ? "active" : ""
                                        }`}
                                      ></i> */}
                                      <img
                                        src={
                                          activesList.up
                                            ? similarselected
                                            : similarunselected
                                        }
                                        style={{
                                          // marginRight: "5px",
                                          width: "1.85em",
                                          height: "1.85em",
                                        }}
                                      />
                                    </span>
                                  }
                                  className="simillar-popup"
                                  style={{
                                    minWidth:
                                      window.innerWidth >= 1025
                                        ? "600px"
                                        : "400px",
                                  }}
                                  position="top center"
                                  on="click"
                                  basic
                                  open={activesList.up}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      up: false,
                                    })
                                  }
                                  onOpen={() =>
                                    setActivesList({
                                      ...activesList,
                                      up: true,
                                    })
                                  }
                                >
                                  <div className="similars-popup">
                                    <SimilarPosts
                                      forModal={props.forModal}
                                      postExcerpt={user_submit_url}
                                      color={color}
                                      categoryColor={category_color}
                                      customClassForLink={customClassForLink}
                                      linkClass={linkClass}
                                      linkIcons={props.settings.linkIcons}
                                      ID={ID}
                                    />
                                  </div>
                                </Popup>
                              </span>
                            }
                            content={"Similar Links"}
                            inverted
                            position="bottom center"
                            disabled={activesList.up}
                            size="mini"
                          />
                        )}

                        {props.forSingle ? null : (
                          <Popup
                            trigger={
                              <span>
                                <Popup
                                  trigger={
                                    <span>
                                      {/* <i
                                        className={`glyph-icon flaticon-share ${
                                          activesList.share ? "active" : ""
                                        }`}
                                      ></i> */}
                                      <img
                                        src={
                                          activesList.share
                                            ? shareselected
                                            : shareunselected
                                        }
                                        style={{
                                          // marginRight: "5px",
                                          width: "1.85em",
                                          height: "1.85em",
                                        }}
                                      />
                                    </span>
                                  }
                                  className="tags-popup share-popup"
                                  position="right center"
                                  on="click"
                                  basic
                                  open={activesList.share}
                                  onClose={() =>
                                    setActivesList({
                                      ...activesList,
                                      share: false,
                                    })
                                  }
                                  onOpen={() => {
                                    _onShareClick();
                                  }}
                                >
                                  <span
                                    className="hideShareOption"
                                    style={{
                                      backgroundColor: darkMode
                                        ? "#3c3c3c"
                                        : "#EDF0F5",
                                      color: "#8A8A8A",
                                    }}
                                  >
                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("linkedin")}
                                    >
                                      <i className="flaticon-linkedin"></i>{" "}
                                      &nbsp;
                                      <b>LinkedIn</b>
                                    </span>
                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("reddit")}
                                    >
                                      <i className="flaticon-reddit-1"></i>{" "}
                                      &nbsp;
                                      <b>Reddit</b>
                                    </span>
                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("twitter")}
                                    >
                                      <i className="flaticon-twitter"></i>{" "}
                                      &nbsp;
                                      <b>Twitter</b>
                                    </span>
                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("facebook")}
                                    >
                                      <i className="flaticon-facebook"></i>{" "}
                                      &nbsp;
                                      <b>Facebook</b>
                                    </span>

                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("messenger")}
                                    >
                                      <i className="flaticon-messenger"></i>{" "}
                                      &nbsp;
                                      <b>Messenger</b>
                                    </span>
                                    <span
                                      className="icon-con-social"
                                      onClick={() => _pop("gmail")}
                                    >
                                      <i className="flaticon-email-1"></i>{" "}
                                      &nbsp;
                                      <b>Email</b>
                                    </span>
                                    <span
                                      className="icon-con-social"
                                      onClick={() => {
                                        setLinkCopied(true);
                                        _pop("link");
                                      }}
                                    >
                                      <i className="flaticon-link"></i> &nbsp;
                                      <b>
                                        {linkCopied ? "Copied" : "Copy Link"}
                                      </b>
                                    </span>
                                  </span>
                                </Popup>
                              </span>
                            }
                            content={"Share"}
                            inverted
                            position="bottom center"
                            disabled={activesList.share}
                            size="mini"
                          />
                        )}
                      </>
                    )}
                  </div>

                  <div className="p-md-2  p-sm-2  p-col-2   post-engagement-favourite">
                    <Popup
                      trigger={
                        <span onClick={!favouritesLoader ? _favourite : null}>
                          {favouritesLoader ? (
                            <span style={{ fontSize: 20 }}>...</span>
                          ) : (
                            <i
                              className={`glyph-icon flaticon-star ${
                                favouriteIsActive ? "active" : ""
                              }`}
                              style={{
                                pointer: "cursor !important",
                              }}
                            ></i>
                          )}
                        </span>
                      }
                      content={favouriteIsActive ? "Unfavourite" : "Favourite"}
                      inverted
                      position="bottom center"
                      size="mini"
                    />
                  </div>
                </div>
              </div>

              {!isMobile && inListView && !props.forSingle ? (
                <div className="post-card-image-listview-desktop">
                  {user_submit_url ? (
                    <img
                      onClick={() => window.open(user_submit_url, "_blank")}
                      src={previewImage}
                      alt="4"
                      style={{
                        cursor: "pointer",
                        backgroundImage: `url(${loadingGif})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "50% 70%",
                        backgroundPosition: "center",
                      }}
                    />
                  ) : (
                    <div>
                      <div className="noImageText">No Image Available</div>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {(props.forSingle || props.forModal) &&
        !isEmpty(postData.link_types) ? (
          <div
            className="postLinkTypes"
            style={{
              paddingBottom: "15px",
              paddingTop: "15px",
              marginTop: "10px",
              marginBottom: "10px",
              minHeight: "50px",
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "95%",
              marginLeft: "15px",
              backgroundColor: darkMode ? "#343434" : "#E8E8E8",
              borderRadius: darkMode ? "5px" : "7px",
            }}
          >
            {postData.link_types.map((data, ind) => {
              return <span>{data.name}</span>;
            })}
          </div>
        ) : null}
        {props.forModal ? (
          <>
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                minWidth: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="card summary post-card"
                style={{
                  width: "100%",
                  boxShadow: darkMode
                    ? "10px 10px 20px 0 rgb(0 0 0 / 10%)"
                    : "",
                }}
              >
                <div className="p-grid"></div>
                <SimilarPosts
                  forModal={props.forModal}
                  postExcerpt={user_submit_url}
                  color={color}
                  categoryColor={category_color}
                  customClassForLink={customClassForLink}
                  linkClass={linkClass}
                  linkIcons={props.settings.linkIcons}
                  ID={ID}
                />
              </div>
            </div>
          </>
        ) : null}
        {props.forModal ? null : props.forSingle ? (
          <>
            <div className={`p-col-12 p-lg-12`}>
              <div className="card summary post-card single-preview">
                <div className="p-grid"></div>
                <SimilarPosts
                  forModal={props.forModal}
                  postExcerpt={user_submit_url}
                  color={color}
                  categoryColor={category_color}
                  customClassForLink={customClassForLink}
                  linkClass={linkClass}
                  linkIcons={props.settings.linkIcons}
                  ID={ID}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      {props.forModal ? null : !isMobile && props.forSingle ? (
        <>
          <div className={`p-col-12 p-lg-6 `}>
            <div
              className="card summary post-card single-preview"
              style={{
                height: "calc(100% - 20px)",
                display: "flex",
                alignItems: "center",
                padding: "0",
                boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0)",
              }}
            >
              {user_submit_url ? (
                <img
                  className="single-preview-image"
                  onClick={() => window.open(user_submit_url, "_blank")}
                  src={previewImage}
                  alt="6"
                  style={{
                    alignSelf: "center",
                    margin: "auto",
                    height: "90%",
                    width: "100%",
                    backgroundImage: `url(${loadingGif})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "50% 50%",
                    backgroundPosition: "center",
                    borderStyle: "none",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div>
                  <div className="noImageText">No Image Available</div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {inListView && !props.forSingle ? (
        <div className="p-md-1 p-lg-2"></div>
      ) : null}
    </>
  );
}

PostCard.propTypes = {
  settings: PropTypes.object.isRequired,
  categories: PropTypes.any.isRequired,
  unCheckedLinks: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  categories: state.categories.categories,
  auth: state.auth,
  unCheckedLinks: state.world.unCheckedLinks,
});

export default withRouter(connect(mapStateToProps)(PostCard));
