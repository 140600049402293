import React from "react";
// import "./DotLoader.scss";
import NewLoader from "../../assets/img/808080loader.gif";

export default function DotLoader() {
	return (
		<span style={{ height: "45px", display: "flex", alignItems: "center", justifyContent: "center",marginTop:'-10px' }}>
			<img style={{ width: "70px", height: "70px"}} src={NewLoader} />
		</span>
	);
}
