import React, { useState, useRef } from "react";

//packages
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Input, Modal, Icon, Label } from "semantic-ui-react";
import { AiFillNotification, AiFillBell } from "react-icons/ai";
import DotLoader from "../../components/Loader/DotLoader";
//apis
import ReCAPTCHAV2 from "react-google-recaptcha";
import { subscribe } from "../../api/api";
import { SET_AUTH_MODAL_CHANGE } from "../../redux/types";
import store from "../../redux/store";
import Error from "../../layout/TopBar/ErrorMessage";
import { SiMaildotru } from "react-icons/si";

const ErrorBody = (props) => {
  if (!props.children) return null;
  return (
    <Label basic color="red" pointing>
      {props.children}
    </Label>
  );
};

function SubscribeModal(props) {
  const [subscribeData, setSubscribeData] = useState({
    email: "",
    isValid: false,
  });

  const captchaRef = useRef(null);
  const [subscribeLoader, setSubscribeLoader] = useState(false);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [recaptchaVerified, setRecaptchaVerified] = useState({
    verified: false,
    "g-recaptcha-response": "",
  });
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [errors, setErrors] = useState({});

  const _subscribeDataChange = (e) => {
    const isEmailValid =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        e.target.value
      );
    if (!isEmailValid) {
      setErrors({ email: "provide valid email!" });
    } else {
      setErrors({});
    }
    setSubscribeData({
      ...subscribeData,
      [e.target.name]: e.target.value,
      isValid: isEmailValid,
    });
  };
  const _showResponse = (res) => {
    setErrors({ ...errors, "g-recaptcha-response": "" });
    setRecaptchaResponse(res);
    setRecaptchaVerified({
      ...recaptchaVerified,
      verified: true,
      "g-recaptcha-response": res,
    });
  };

  const _expireRecaptcha = (res) => {
    setRecaptchaVerified(false);
    setRecaptchaResponse("");
    // setAddPostData({
    //   ...addPostData,
    //   recaptchaVerified: false,
    //   "g-recaptcha-response": "",
    // })
    // alert(" Expire Recaptchac ")
  };

  const notify = (message) =>
    toast.dark(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  const _subscribe = () => {
    if (!subscribeData.isValid) {
      setErrors({ email: "provide valid email!" });
      return;
    }

    if (!recaptchaVerified.verified) {
      toast.dark(<b style={{ width: "400px" }}>Recaptcha not verified!</b>, {
        position: toast.POSITION.TOP_CENTER,

        autoClose: 2000,
        hideProgressBar: true,
        style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
      });
      return;
    }

    setSubscribeLoader(true);

    subscribe({
      email: subscribeData.email,
    })
      .then((res) => {
        if (res.json.status) {
          setSubscribeLoader(false);
          setSubscribeData({ email: "", isValid: false });
          toast.dark(
            <b style={{ width: "400px" }}>Successfully subscribed!</b>,
            {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 2000,
              hideProgressBar: true,
              style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
            }
          );
        } else {
          setSubscribeLoader(false);
          if (res.json.message) {
            toast.dark(<b style={{ width: "400px" }}>{res.json.message}</b>, {
              position: toast.POSITION.TOP_RIGHT,

              autoClose: 2000,
              hideProgressBar: true,
              style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
            });
          }
          setSubscribeLoader(false);
          throw res.json.message;
        }
        setSubscribeData({
          email: "",
          isValid: false,
        });
        setRecaptchaVerified({
          verified: false,
          "g-recaptcha-response": "",
        });
        setSubscribeLoader(false);
        props.onClose();
      })
      .catch((err) => {
        setSubscribeLoader(false);
        setSubscribeData({
          email: "",
          isValid: false,
        });
        setRecaptchaVerified({
          verified: false,
          "g-recaptcha-response": "",
        });
        props.onClose();
        setSubscribeLoader(false);
        // toast.error(<b>{err.toString()}</b>, {
        // 	position: toast.POSITION.BOTTOM_LEFT,
        // 	autoClose: 2000,
        // 	hideProgressBar: true
        // });
      });
  };

  const _goToRegisterPage = () => {
    props.onClose();
    setSubscribeLoader(false);
    store.dispatch({ type: SET_AUTH_MODAL_CHANGE, payload: true });
  };

  return (
    <Modal
      onClose={() => {
        props.onClose();
        setSubscribeLoader(false);
        setSubscribeData({
          email: "",
          isValid: false,
        });
      }}
      onOpen={props.onClose}
      open={props.open}
      className={
        darkMode ? "clear-favourite-modal-dark" : "clear-favourite-modal"
      }
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "transparent",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          Subscribe
          <div style={{ padding: "0px 0px 0px 0px", fontWeight: "lighter" }}>
            <span
              style={{
                fontSize: "12px",
                fontStyle: "italic",
                fontFamily: "Montserrat",
                paddingBottom: "2px",
              }}
            >
              or
              <span
                onClick={_goToRegisterPage}
                className="subscribeText"
                style={{
                  cursor: "pointer",
                  paddingBottom: "2px",
                  fontWeight: "bold",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                Register Here
              </span>
              to manage subscription settings
            </span>
          </div>
        </div>
        <Icon
          name="close"
          style={{ cursor: "pointer" }}
          onClick={() => {
            props.onClose();
            setSubscribeLoader(false);
            setSubscribeData({
              email: "",
              isValid: false,
            });
          }}
        />
      </Modal.Header>

      <Modal.Content style={{ backgroundColor: "transparent" }}>
        <Modal.Description>
          {/* <div style={{ padding: "20px auto", width: "100%" }}>
						<Input style={{ width: "100%" }} placeholder="Email" type="email" name="email" value={subscribeData.email} onChange={_subscribeDataChange} />
					</div> */}
          <div
            className={
              darkMode ? "subscribe-textfield-dark" : "subscribe-textfield"
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {errors.email ? (
                <Error message={errors.email} id="emailError" />
              ) : null}
            </div>
            <div class="form-group">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter Email"
                autoComplete="new-password"
                className={errors.email ? "errorField" : null}
                value={subscribeData.email}
                onChange={_subscribeDataChange}
              />
              <SiMaildotru className="input-icon" />
            </div>
          </div>
        </Modal.Description>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "10px",
            padding: "0px",
          }}
        >
          <ReCAPTCHAV2
            theme={props.darkMode ? `dark` : `light`}
            ref={captchaRef}
            className="g-recaptcha"
            // style={{ width: "100%" }}
            sitekey={`6LeT7R4aAAAAANggb_D-U_4DQIHo0DIgFpZ07984`}
            onChange={_showResponse}
            onExpired={_expireRecaptcha}
            // id="recap"
          />
          <ErrorBody>{errors["g-recaptcha-response"]}</ErrorBody>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className={darkMode ? "sub-btn-dark" : "sub-btn"}>
          <div className="auth-btns">
            <a
              className="blue"
              onClick={() => {
                if (subscribeLoader) {
                  return;
                }
                _subscribe();
              }}
            >
              {subscribeLoader ? (
                <DotLoader />
              ) : (
                <>
                  <AiFillBell
                    style={{ marginRight: "10px", fontSize: "20px" }}
                  />
                  SUBSCRIBE
                </>
              )}
            </a>
          </div>
        </div>
        {/* <Button
					onClick={_subscribe}
					// style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
					size="small"
					icon="bell"
					color="blue"
					labelPosition="left"
					disabled={!subscribeData.isValid || subscribeLoader || !recaptchaVerified.verified}
				>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
						{subscribeLoader ? (
							<DotLoader />
						) : (
							<>
								<AiFillBell style={{ marginRight: "10px" }} />
								Subscribe
							</>
						)}
					</div>
				</Button> */}
      </Modal.Actions>
      <Modal.Content style={{ textAlign: "center" }}>
        <div>
          <NavLink to="/privacy" onClick={() => props.onClose()}>
            <b>Privacy Policy</b>
          </NavLink>{" "}
          <b>|</b>
          <NavLink to="/terms" onClick={() => props.onClose()}>
            {" "}
            <b>Terms and Conditions</b>
          </NavLink>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default SubscribeModal;
