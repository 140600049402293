import {
	SET_RANDOM_LINK_BROWSER_OPEN_MODE,
	SET_SUBSCRIBE_BUTTON_STATUS,
	SET_DARK_MODE,
	SET_LIST_MODE,
	SET_LINK_MODE,
	SET_TAGS_MODE,
	SET_BANNERS_MODE,
	SET_MENU_ICON_MODE,
	CHANGE_TAGS_BAR_FIXED,
	SET_ACTIVE_SLIDE_LISTS,
	SET_ACTIVE_SLIDE_CATEGORY,
	SET_UNAUTH_ACTIVE_SLIDE_CATEGORY,
	SET_UNAUTH_ACTIVE_SLIDE,
	SET_UNAUTH_ACTIVE_SLIDE_LISTS,
	SET_DELETE_MODAL,
	SET_ACTIVE_SLIDE,
	SET_FILTER_BAR,
	SET_WINDOW_MODE,
	SET_ACTIVE_SLIDE_MOST_POPULAR,
	SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR,
	SET_ACTIVE_SLIDE_MOST_RECENT,
	SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT
} from "../types";

const initialState = {
	darkMode: false,
	listMode: false,
	tagsMode: true,
	linkIcons: true,
	filterBar: false,
	windowMode: false,
	bannersMode: true,
	menuIconMode: false,
	subButtonVisible: true,
	deleteModal: false,
	randomLinkBrowserOpenMode: false,
	activeSlide: 0,
	activeSlideLists: 0,
	activeSlideMostPopular: 0,
	unauthActiveSlideMostPopular: 0,
	activeSlideMostRecent: 0,
	unauthActiveSlideMostRecent: 0,
	activeSlideCategory: 0,
	unauthActiveSlideCategory: 0,
	activeSlideCategoryArr: [],
	unauthActiveSlideCategoryArr: [],
	unauthActiveSlide: 0,
	unauthActiveSlideLists: 0
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_WINDOW_MODE:
			return { ...state, windowMode: action.payload };
		case SET_FILTER_BAR:
			return { ...state, filterBar: action.payload };
		case SET_ACTIVE_SLIDE_CATEGORY:
			localStorage.setItem("ACTIVE_SLIDE_CATEGORY", JSON.stringify(action.payload));
			localStorage.setItem("ACTIVE_SLIDE_CATEGORY_ARR", JSON.stringify(action.catArr));
			return { ...state, activeSlideCategory: action.payload, activeSlideCategoryArr: action.catArr };
		case SET_UNAUTH_ACTIVE_SLIDE_CATEGORY:
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE_CATEGORY", JSON.stringify(action.payload));
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE_CATEGORY_ARR", JSON.stringify(action.catArr));
			return { ...state, unauthActiveSlideCategory: action.payload, unauthActiveSlideCategoryArr: action.catArr };
		case SET_UNAUTH_ACTIVE_SLIDE:
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE", JSON.stringify(action.payload));
			return { ...state, unauthActiveSlide: parseInt(action.payload) };
		case SET_UNAUTH_ACTIVE_SLIDE_LISTS:
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE_LISTS", JSON.stringify(action.payload));
			return { ...state, unauthActiveSlideLists: parseInt(action.payload) };
		case SET_ACTIVE_SLIDE_LISTS:
			localStorage.setItem("AUTH_ACTIVE_SLIDE_LISTS", JSON.stringify(action.payload));
			return { ...state, activeSlideLists: parseInt(action.payload) };
		case SET_ACTIVE_SLIDE_MOST_POPULAR:
			localStorage.setItem("AUTH_ACTIVE_SLIDE_MOST_POPULAR", JSON.stringify(action.payload));
			return { ...state, activeSlideMostPopular: parseInt(action.payload) };
		case SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR:
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE_MOST_POPULAR", JSON.stringify(action.payload));
			return { ...state, unauthActiveSlideMostPopular: parseInt(action.payload) };
		case SET_ACTIVE_SLIDE_MOST_RECENT:
			localStorage.setItem("AUTH_ACTIVE_SLIDE_MOST_RECENT", JSON.stringify(action.payload));
			return { ...state, activeSlideMostRecent: parseInt(action.payload) };
		case SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT:
			localStorage.setItem("UNAUTH_ACTIVE_SLIDE_MOST_RECENT", JSON.stringify(action.payload));
			return { ...state, unauthActiveSlideMostRecent: parseInt(action.payload) };
		case SET_ACTIVE_SLIDE:
			localStorage.setItem("AUTH_ACTIVE_SLIDE_HOME", JSON.stringify(parseInt(action.payload)));
			return { ...state, activeSlide: parseInt(action.payload) };
		case SET_DELETE_MODAL:
			return {
				...state,
				deleteModal: action.payload
			};
		case SET_DARK_MODE:
			return {
				...state,
				darkMode: action.payload
			};
		case SET_LIST_MODE:
			return {
				...state,
				listMode: action.payload
			};
		case SET_TAGS_MODE:
			return {
				...state,
				tagsMode: action.payload
			};
		case SET_LINK_MODE:
			return {
				...state,
				linkIcons: action.payload
			};
		case SET_MENU_ICON_MODE:
			return {
				...state,
				menuIconMode: action.payload
			};
		case SET_BANNERS_MODE:
			return {
				...state,
				bannersMode: action.payload
			};
		case SET_SUBSCRIBE_BUTTON_STATUS:
			return {
				...state,
				subButtonVisible: action.payload
			};
		case SET_RANDOM_LINK_BROWSER_OPEN_MODE:
			return {
				...state,
				randomLinkBrowserOpenMode: action.payload
			};
		default:
			return state;
	}
};
