import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { isEmpty } from "../../validation/validation";

const LoginForm = (props) => {
	const [formData, setFormData] = useState({ password: "" });
	const [error, setError] = useState({ passwordError: "" });

	const handleChange = ({ target: { name, value } }) => {
		setFormData({ ...formData, [name]: value });
	};

	const resetError = () => {
		setError({ passwordError: "" });
	};

	const handleSubmit = () => {
		const { password } = formData;

		if (isEmpty(password)) {
			setError({ ...error, passwordError: "Please Enter Your Password" });
		}
		if (!isEmpty(password)) {
			const currentTime = Date.now() / 1000;

			const item = {
				value: password,
				expiry: currentTime + 259200
			};

			localStorage.setItem("password", JSON.stringify(item));

			if (process.env.REACT_APP_PASSWORD === password) {
				resetError();
				props.history.push("/");
			} else {
				setError({ ...error, passwordError: "Please Enter Correct Password" });
			}
		}
	};

	return (
		<Grid textAlign="center" style={{ height: "100vh", minWidth: "100%" }} verticalAlign="middle">
			<Grid.Column style={{ maxWidth: 450 }}>
				<Header as="h2" color="teal" textAlign="center">
					{/* <Image src='/logo.png' />  */}
					Log-in to your account
				</Header>
				<Form size="large">
					<Segment stacked>
						<Form.Input fluid icon="lock" iconPosition="left" placeholder="Password" type="password" onChange={handleChange} name="password" error={error.passwordError ? error.passwordError : null} />

						<Button color="teal" fluid size="large" onClick={handleSubmit}>
							Login
						</Button>
					</Segment>
				</Form>
				{/* <Message>
          New to us? <a href='#'>Sign Up</a>
        </Message> */}
			</Grid.Column>
		</Grid>
	);
};

export default withRouter(LoginForm);
