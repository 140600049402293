import React from 'react'
import { Helmet } from 'react-helmet'

export const MetaDecorator = (props) => {

    let { metaDataPerRoute } = props;
    let originalUrl = window.location.origin;
    let pikiLinksImageUrl = originalUrl + "/icon192.png"

    return (
        <div>
            <Helmet>
                <title>{metaDataPerRoute.meta_title}</title>
                {/* <link rel="canonical" href={metaDataPerRoute.meta_title} /> */}
                <meta name="description" content={metaDataPerRoute.meta_description} />
                <meta name="robots" content={metaDataPerRoute.meta_robots} />
                {/* <link rel="canonical" href={metaDataPerRoute.canonical} /> */}

                <meta property="og:title" content={metaDataPerRoute.og_title ? metaDataPerRoute.og_title : metaDataPerRoute.meta_title} />
                <meta property="og:description" content={metaDataPerRoute.og_description ? metaDataPerRoute.og_description : metaDataPerRoute.meta_description} />
                <meta property="og:url" content={metaDataPerRoute.og_url ? metaDataPerRoute.og_url : originalUrl} />
                <meta property="og:image" content={metaDataPerRoute.og_image ? metaDataPerRoute.og_image : pikiLinksImageUrl} />

                <meta name="twitter:title" content={metaDataPerRoute.twitter_title ? metaDataPerRoute.twitter_title : metaDataPerRoute.meta_title} />
                <meta name="twitter:description" content={metaDataPerRoute.twitter_description ? metaDataPerRoute.twitter_description : metaDataPerRoute.meta_description} />
                <meta name="twitter:image" content={metaDataPerRoute.twitter_image ? metaDataPerRoute.twitter_image : pikiLinksImageUrl} />
            </Helmet>
        </div>
    )
}
