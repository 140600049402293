import { combineReducers } from "redux";
import worldReducer from "./worldReducer";
import tagsReducer from "./tagsReducer";
import settingsReducer from "./settingsReducer";
import favouriteReducer from "./favouriteReducer";
import categoriesReducer from "./categoriesReducer";
import mostRecentsReducer from "./mostRecentsReducer";
import mostPopularsReducer from "./mostPopularsReducer";
import listsReducer from "./listsReducer";
import refreshMenuReducer from "./refreshMenuReducer";
import pwaInstallReducer from "./pwaInstallReducer";
import browserNameRedux from "./browserNameRedux";
import authReducer from "./authReducer";

export default combineReducers({
	auth: authReducer,
	tags: tagsReducer,
	lists: listsReducer,
	world: worldReducer,
	settings: settingsReducer,
	favourite: favouriteReducer,
	categories: categoriesReducer,
	mostRecents: mostRecentsReducer,
	mostPopulars: mostPopularsReducer,
	refreshMenu: refreshMenuReducer,
	pwaInstall: pwaInstallReducer,
	browserName: browserNameRedux
});
