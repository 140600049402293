export const popWindow = (type, content, sharedUrl, emailTo) => {

    //function that generates url url for sharing
    const generateString = () => {

        const redditParams = new URLSearchParams();
        redditParams.set('title', content);
        redditParams.set('url', sharedUrl);

        const messengerParams = new URLSearchParams();
        messengerParams.set('link', sharedUrl)
        messengerParams.set('app_id',1096894830748875 )
        messengerParams.set('redirect_uri', sharedUrl)

        switch (type) {
            case "facebook":
                return `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}&text=${content}`;
            case "twitter":
                return `https://twitter.com/intent/tweet?text=${content}&url=${sharedUrl}`;
            case "reddit":
                return `https://www.reddit.com/submit?${redditParams.toString()}`;
            case "gmail":
                return `https://mail.google.com/mail?view=cm&su=${content}&body=${content} ${sharedUrl}`;
            case "telegram":
                return `https://telegram.me/share/url?url=${'google.com'}&content=${content}`;
            case "linkedin":
                return `https://www.linkedin.com/sharing/share-offsite/?url=${sharedUrl}`;
            case "messenger":
                return `https://www.facebook.com/dialog/send?${messengerParams.toString()}`
            default:
                return `https://twitter.com/intent/tweet/?text=${content}&url=${sharedUrl}`;
        }
    };

    if (type === 'link') {
        const el = document.createElement('textarea');
        el.value = sharedUrl;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        return;
    }

    const myString = generateString();
    windowPopup(myString, 600, 350);

}

const windowPopup = (url, width, height) => {
    // Calculate the position of the popup so
    // it’s centered on the screen.
    var left = window.innerWidth / 2 - width / 2,
        top = window.innerHeight / 2 - height / 2;

    window.open(
        url,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
        width +
        ",height=" +
        height +
        ",top=" +
        top +
        ",left=" +
        left
    );
};

