const GLOBALS = {
  APP_NAME: "PikiLinks",
  BLANK_LINK: "#",
  SERVER_URL:
    process.env.NODE_ENV === "production" ||
    process.env.REACT_APP_MODE === "prod"
      ? `https://app.pikilinks.com/`
      : "https://dev.app.pikilinks.com/",
};
export default GLOBALS;
