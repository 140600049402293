import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
// import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import AppWrapper from "./AppWrapper";
import history from "./utils/history";
import ReactGA from "react-ga";

history.listen((location) => {
	// console.log(" IndexLocation ", location.pathname);
	ReactGA.pageview(location.pathname);
});

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

ReactDOM.render(
	<>
		<Provider store={store}>
			<Router history={history}>
				<AppWrapper />
			</Router>
		</Provider>
	</>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
