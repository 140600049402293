import React from "react";

//from packages
import { Button, Modal } from "semantic-ui-react";

function DeleteModal(props) {
	const [open, setOpen] = React.useState(true);

	return (
		<Modal onClose={props.onClose} onOpen={props.onClose} open={props.open} className="clear-favourite-modal">
			<Modal.Header>Clear All Favorites?</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<p style={{ padding: "20px auto" }}>This action cannot be undone!</p>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button size="small" color="black" onClick={props.onClose}>
					Cancel
				</Button>
				<Button onClick={props._clearFavourites} size="small" color="red">
					Yeah, That's Okay
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default DeleteModal;
