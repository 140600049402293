import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./reducers/index";
import thunk from "redux-thunk";

const initialState = {};

const middleware = [thunk];

const store =
	process.env.NODE_ENV !== "development"
		? createStore(rootReducer, initialState, applyMiddleware(...middleware))
		: createStore(
				rootReducer,
				initialState,
				compose(
					applyMiddleware(...middleware)
					// window.__REDUX_DEVTOOLS_EXTENSION__ &&
					//   window.__REDUX_DEVTOOLS_EXTENSION__()
				)
		  );

export default store;
