import React, { useEffect, useRef, useMemo, useState } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import CoverSlider from "../../components/CoverSlider/CoverSlider";
//components
import ListItem from "./ListItem";
import Loader from "../../components/Loader/Loader";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

//apis
import { getLists, getBanners } from "../../api/api";

//utils
import store from "../../redux/store";
import { listSortOptions } from "../../utils/commons";

//types
import { UPDATE_LISTS } from "../../redux/types";

import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import BannerLists from "../../assets/img/BannerLists.jpg";
import DefaultCover from "../../assets/img/default-cover.png";
import { isEmpty } from "../../validation/validation";

const sortByOptions = [
	{ name: "Newest", code: "Newest", index: 1 },
	{ name: "Random", code: "Random", index: 2 },
	{ name: "Popularity", code: "Popularity", index: 3 },
	{ name: "Oldest", code: "Oldest", index: 4 }
];

function Lists(props) {
	const { listsArr, isFetching, isFinished, nextPage, preloaded } = props.lists;
	const darkMode = useSelector((state) => state.settings.darkMode);
	const [sortBy, setSortBy] = useState(listSortOptions[0]);
	const slides = [
		{
			image: darkMode ? DefaultCover : DefaultCover,
			default: true
		},
		{
			image: darkMode ? DefaultCover : DefaultCover,
			default: true
		},
		{
			image: darkMode ? DefaultCover : DefaultCover,
			default: true
		}
	];
	const [coverBannerData, setCoverBannerData] = useState([...slides]);
	const loader = useRef(null);

	React.useEffect(() => {
		const controller = new AbortController();

		getBanners("?group=list", controller.signal)
			.then((resp) => {
				if (resp.response.ok) {
					setCoverBannerData([...resp.json]);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		return () => controller.abort();
	}, []);

	const GetLists = () => {
		!isFinished &&
			store.dispatch({
				type: UPDATE_LISTS,
				payload: { ...props.lists, isFetching: true }
			});
		getLists(`paged=${nextPage}&per_page=10&sort_by=${sortBy && sortBy.code.toLowerCase()}`)
			.then((res) => {
				const lengthNow = listsArr.length + res.json.series.length;
				if (lengthNow >= res.response.headers.get("x-wp-total")) {
					props.updateLists({ isFinished: true });
				}
				res = res.json;
				props.updateLists({
					isFetching: false,
					nextPage: nextPage + 1,
					listsArr: [...listsArr, ...res.series]
				});
			})
			.catch((err) => {
				props.updateLists({ isFinished: true });
			});
	};

	useEffect(()=>{
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({'piki_page':'lists'})
	  },[props.history])

	//did mount
	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("lists-page");
		if (localStorage.getItem("listsSortBy")) {
			setSortBy(JSON.parse(localStorage.getItem("listsSortBy")));
		}
		return () => {
			document.getElementsByTagName("body")[0].classList.remove("lists-page");
		};
	}, []);

	//For observer
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (!isFetching) {
			}
			!isFetching && !isFinished && preloaded && GetLists();
		}
	};
	useEffect(() => {
		const options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0
		};
		// Create observer
		const observer = new IntersectionObserver(handleObserver, options);
		// observe the loader
		if (loader && loader.current) {
			observer.observe(loader.current);
		}
		// clean up on willUnMount
		return () => observer.unobserve(loader.current);
	}, [props.lists]);

	const _sortByChange = (e) => {
		let sortByValue = { name: e, code: e };
		setSortBy(sortByValue);
		store.dispatch({
			type: UPDATE_LISTS,
			payload: {
				isFetching: false,
				isFinished: false,
				nextPage: 1,
				listsArr: [],
				sortBy: sortByValue
			}
		});
		localStorage.setItem("listsSortBy", JSON.stringify(sortByValue));
	};

	const ListsList = useMemo(() => {
		return (listsArr || []).map((post, index) => {
			return <ListItem key={index} listData={post} />;
		});
	}, [listsArr, props.activeTag, props.lists.listsArr]);

	const _setActiveSlide = (slide) => {
		if (localStorage.getItem("userToken")) {
			store.dispatch({ type: "SET_ACTIVE_SLIDE_LISTS", payload: slide });
		} else {
			store.dispatch({ type: "SET_UNAUTH_ACTIVE_SLIDE_LISTS", payload: slide });
		}
	};
	let isMobile = window.innerWidth <= 992;

	return (
		<div className={`p-grid p-fluid dashboard page-list`}>
			<MetaDecorator metaDataPerRoute={metaDecoratorData.lists} />
			{props.settings.bannersMode ? (
				<div
					className={`${isMobile ? "CoverBannerMobile" : "CoverBanner"} ListsBanner`}
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						background: isEmpty(coverBannerData) ? "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)" : "transparent",
						paddingTop: isEmpty(coverBannerData) ? "20px" : "",
						animation: "gradient 15s ease infinite",
						backgroundSize: "400% 400%",
						minHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "30vh") : "",
						maxHeight: isEmpty(coverBannerData) ? (isMobile ? "20vh" : "") : ""
					}}
				>
					<CoverSlider
						isMobile={isMobile}
						darkMode={darkMode}
						activeSlide={localStorage.getItem("userToken") ? props.settings.activeSlideLists : props.settings.unauthActiveSlideLists}
						setActiveSlide={_setActiveSlide}
						cattitle={isEmpty(coverBannerData) ? "Lists" : ""}
						slides={coverBannerData}
					/>
				</div>
			) : null}
			<div className="p-col-12 p-lg-12">
				{(!props.settings.bannersMode || !isEmpty(coverBannerData)) && (
					<div className={`p-col-12 underlined-border`} style={{ marginTop: coverBannerData.length === 1 ? (props.settings.bannersMode ? "-20px" : "0px") : "" }}>
						<b>Lists</b>
					</div>
				)}
			</div>

			{ListsList}
			<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
				{isFetching && !isFinished && <Loader loadingMore={true} />}
				{isFinished && listsArr.length < 1 && (
					<div className="text-centered">
						<b>No Links found</b>
					</div>
				)}
			</div>
		</div>
	);
}

Lists.propTypes = {
	lists: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	lists: state.lists,
	settings: state.settings
});

const mapDispatchToProps = (dispatch) => {
	return {
		// dispatching plain actions
		updateLists: (payload) => dispatch({ type: UPDATE_LISTS, payload })
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Lists)
);
