import React from "react";
import { Icon } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./multiselect.scss";
import _ from "lodash";
import isEmpty from "../../utils/isEmpty";
import { IoMdClose } from "react-icons/io";
import useComponentVisible from "../../utils/isComponentVisible";
import store from "../../redux/store";
import { SET_IS_DROP_ON } from "../../redux/types";
import { GiCheckMark } from "react-icons/gi";

const Tag = (props) => <span className="tag" {...props} />;
const Delete = (props) => <button className="delete" {...props} />;

const LinkTypeInput = (props) => {
	const [checkboxOn, setCheckboxOn] = React.useState(false);
	// const { ref, isComponentVisible } = useComponentVisible(true);

	const _showCheckboxes = () => {
		let checkboxes = document.getElementById("checkboxes");

		if (!props.isMenuOn) {
			checkboxes.style.display = "block";
			checkboxes.style.position = "absolute";
			props.setIsMenuOn(true);
		} else {
			checkboxes.style.display = "none";
			props.setIsMenuOn(false);
		}
	};

	const _deleteLink = (badge) => {
		let checkboxes = document.getElementById("checkboxes");
		props.deleteLinkItem(badge);
		checkboxes.style.display = "none";
	};

	let allActiveLinkTypesWithDetails = [];
	_.values(props.linkTypeOptions).forEach((element) => {
		allActiveLinkTypesWithDetails.push({ term_id: element.term_id, name: element.name });
		_.values(element.children).forEach((childelem) => {
			return allActiveLinkTypesWithDetails.push({ term_id: childelem.term_id, name: childelem.name });
		});
	});

	let allActiveLinkTypes = [...new Set([...props.selectedParentLinks, ...props.selectedChildLinks])];
	// let ParIsChecked = false
	let isOneChildSelected = {};

	props.linkTypeOptions.forEach((element, index) => {
		_.values(element.children).forEach((element2, index2) => {
			//    if(element2.term_id === element)
			let isOneChildActive = props.selectedChildLinks.filter((da, ind) => da === element2.term_id);
			if (isOneChildActive) {
				isOneChildSelected = true;
			} else {
				isOneChildSelected = false;
			}
		});
	});

	return (
		<div class="multiselect">
			<div class="selectBox">
				<div style={{ display: "flex", alignItems: "center", flex: 1 }}>
					<ul
						style={{
							scrollbarColor: props.darkMode ? "#808080 rgba(0,0,0,0)" : "#CCCCCC rgba(0,0,0,0)"
						}}
						className="multi-scroll"
					>
						{allActiveLinkTypes.map((badge, index) => {
							let canBreak = index % 7;
							let activeLink = allActiveLinkTypesWithDetails.filter((dat, ind) => dat.term_id === badge);
							return (
								<>
									<div
										style={{
											display: "flex",
											backgroundColor: props.darkMode ? "gray" : "#CECECE",
											color: "white",
											alignItems: "center",
											justifyContent: "center",
											margin: "2px",
											padding: "2px 5px 2px 2px",
											borderRadius: "3px",
											height: "25px",
											zIndex: 1000

											// padding: "0px 5px 0px 5px"
										}}
										key={index}
									>
										<span className="tag-label" style={{ fontSize: "13px", color: props.darkMode ? "white" : "black", padding: "3px", display: "flex" }}>
											{activeLink && activeLink[0] && activeLink[0].name ? activeLink[0].name : ""}
										</span>
										<IoMdClose
											onClick={() => {
												_deleteLink(badge);
											}}
											style={{ color: "white", padding: "2px", backgroundColor: "rgba(0,0,0,0.3)", borderRadius: "50%", fontSize: "16px", cursor: "pointer", marginLeft: "3px" }}
										/>
									</div>
									{canBreak.toString() === "0" && index !== 0 ? <br /> : null}
								</>
							);
						})}
					</ul>
				</div>
				<div class="overSelect" onClick={_showCheckboxes}>
					{isEmpty(allActiveLinkTypes) ? <span style={{ color: props.darkMode ? "gray" : "lightgray", paddingLeft: "7px" }}>Select Link Type</span> : null}
				</div>
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12px", color: "#858585", paddingRight: "0.9em", paddingTop: "3px" }}>
					<i aria-hidden="true" class="dropdown icon"></i>
				</div>
			</div>
			<div id="checkboxes" style={{ backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5", maxHeight: "300px", overflowY: "scroll", borderColor: "gray" }}>
				{props.linkTypeOptions.map((data, index) => {
					let isParentChecked = !isEmpty(props.selectedParentLinks.filter((pl) => pl === data.term_id));

					return (
						<>
							<label
								for="one"
								id="mainlabel"
								onClick={() => props.onLinkTypeChange({}, data.term_id, data.children)}
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer", borderColor: "gray" }}
							>
								{/* <input checked={isParentChecked} onChange={(e) => props.onLinkTypeChange(e, data.term_id, data.children)} type="checkbox" id={`check-${data.term_id}`} /> */}
								<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<img src={data.url} style={{ width: "18px", height: "18px", margin: "0px 5px 0px 5px" }} />
									<b>{data.name}</b>
								</div>
								{isParentChecked && <GiCheckMark style={{ marginRight: "10px", fontSize: "14px", color: "#8A8A8A" }} />}
							</label>
							{_.values(data.children).map((ch, ind) => {
								let isChildChecked = !isEmpty(props.selectedChildLinks.filter((pl) => pl === ch.term_id));

								return (
									<>
										<label
											onClick={() => props.onChildrenLinkTypeChange({}, ch.term_id, data.term_id, _.values(data.children))}
											id="sublabel"
											for="one"
											style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer", borderColor: "gray" }}
										>
											{/* <input checked={isChildChecked} onChange={(e) => props.onChildrenLinkTypeChange(e, ch.term_id, data.term_id, _.values(data.children))} type="checkbox" id="one" /> */}
											<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
												<img src={ch.url} style={{ width: "18px", height: "18px", margin: "0px 5px 0px 5px" }} />
												{ch.name}
											</div>
											{isChildChecked && <GiCheckMark style={{ marginRight: "10px", fontSize: "14px", color: "#8A8A8A" }} />}
										</label>
									</>
								);
							})}
						</>
					);
				})}
			</div>
		</div>
	);
};

export default LinkTypeInput;
