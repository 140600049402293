import React, { useState } from "react";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import store from "../../redux/store";
import GooglePlay from "../../assets/img/google_play.png";
import AppleStore from "../../assets/img/app_store.png";
import GalaxyStore from "../../assets/img/galaxy_store.png";
import WindowsStore from "../../assets/img/windows_store.png";
import { getPlartforms } from "../../api/api";

function Platforms() {
	const [count, setCount] = useState(0);
	const [plartforms, setPlartforms] = useState([]);
	const isMobile = window.innerWidth < 450;

	React.useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("platforms-page");
		document.getElementsByTagName("body")[0].removeAttribute("id");

		return () => {
			document.getElementsByTagName("body")[0].classList.remove("platforms-page");
		};
	}, []);

	React.useEffect(() => {
		getPlartforms("")
			.then((res) => {
				if (res.response.ok) {
					setPlartforms(res.json);
				}
			})
			.catch((err) => {
				//  handle error
			});
	}, []);

	console.log("plartforms:", plartforms);

	const _click = async (e) => {
		await setCount(() => count + 1);
		await setCount(() => count + 1);
	};

	const darkMode = store.getState().settings.darkMode;

	return (
		<div className="p-grid p-fluid dashboard page-platforms">
			<MetaDecorator metaDataPerRoute={metaDecoratorData.platforms} />
			<div className="p-col-12 p-lg-12">
				<div className="card card-w-title">
					<h1 style={{ fontSize: "18px" }}>Platforms</h1>
					<div className={darkMode ? "plartform-container" : "plartform-container-light"}>
						{plartforms.map((plats) => {
							return (
								<div
									onClick={() => {
										if (plats.url) {
											window.open(plats.url, "_blank");
										}
									}}
									className={`plartform-item ${plats.url ? "enabled" : "disabled"}`}
								>
									{plats.image && <img src={plats.image} />}
									{!plats.image && <span>{plats.title}</span>}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Platforms;
