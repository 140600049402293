import { SET_FAVOURITE_POSTS, SET_AUTH_FAVOURITE_POSTS, SET_CAT_LIST } from "../types";

const initialState = {
	favPosts: [],
	authFavPosts: [],
	catList: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_FAVOURITE_POSTS:
			localStorage.setItem("favouritePosts", JSON.stringify(action.payload));
			return {
				...state,
				favPosts: action.payload
			};
		case SET_AUTH_FAVOURITE_POSTS:
			return {
				...state,
				authFavPosts: action.payload
			};
		case SET_CAT_LIST: {
			return {
				...state,
				catList: action.payload
			};
		}
		default:
			return state;
	}
};
