import { SET_MOST_POPULAR_POSTS,SET_FEATURED_POSTS } from "../types";

const initialState = {
  loading: true,
  postsArr: [],
  featuredPost : []
};

export default (state = initialState, action) => {
  // console.log(action.type)
  switch (action.type) {
    case SET_MOST_POPULAR_POSTS:
      return {
        ...state,
        postsArr: action.payload,
        loading: false,
      };

    case SET_FEATURED_POSTS:
    return {
      ...state,
      featuredPost:action.payload,
      loading:false,
    }
    default:
      return state;
  }
};
