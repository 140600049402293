import { SAVE_INSTALL_PROPOSAL_EVENT, PROMPT_ADD_TO_HOME_SCREEN, PWA_WINDOW_ON, UNINSTALL_PWA_MODAL } from "../types";

const initialState = {
	proposalEvent: undefined,
	pwaWindowOn: false,
	uninstallPwaModal: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UNINSTALL_PWA_MODAL:
			return {
				...state,
				uninstallPwaModal: action.payload
			};
		case PWA_WINDOW_ON:
			return {
				...state,
				pwaWindowOn: action.payload
			};
		case SAVE_INSTALL_PROPOSAL_EVENT:
			return {
				...state,
				proposalEvent: action.payload
			};
		case PROMPT_ADD_TO_HOME_SCREEN:
			return {
				...state,
				proposalEvent: undefined
			};

		default:
			return state;
	}
};
