import React from "react";
import offline from "../../assets/img/offline.png";
import loadingImage from "../../assets/img/main_loading.gif";

function LoaderPage(props) {
	return (
		<div style={{ display: "block" }} className="loading-more-wrapper page-loading-page">
			<div
				className="loader-wrapper"
				style={{
					width: "100%",
					height: "100%",
					paddingTop: "71px"
				}}
			>
				{window.navigator.onLine ? (
					<img src={loadingImage} alt="Loading..." />
				) : (
					<div>
						<img src={offline} alt="Offline..." />
						<div style={{ margin: "auto", textAlign: "center" }}>
							<b>No Connection</b>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default LoaderPage;
