import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { EmailValidation } from "./validations";
import Error from "./ErrorMessage";
import { Link } from "react-router-dom";
import Axios from "axios";
import DotLoader from "../../components/Loader/DotLoader";
import GLOBALS from "../../constants";
import { SiMaildotru } from "react-icons/si";

export default class ForgotPasswordModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailError: false,
			submitEmailLoader: false
		};
	}

	validation = (email) => {
		const validationStatus = EmailValidation(email);

		if (validationStatus) {
			this.submitEmail(email);
		} else {
			this.setState({ emailError: true });
		}
	};

	submitEmail = (email) => {
		this.setState({ submitEmailLoader: true });
		return Axios({
			url: `${GLOBALS.SERVER_URL}wp-json/bdpwr/v1/reset-password`,
			method: "POST",
			data: {
				email: email
			}
		}).then(
			(res) => {
				this.setState({ submitEmailLoader: false });
				this.props.resetCode(email);
			},
			(err) => {
				this.setState({ submitEmailLoader: false });
				// console.log(err);
			}
		);
	};

	render() {
		return (
			<div className={this.props.darkMode ? "modal-container-dark" : "modal-container"}>
				<div className="backdrop" onClick={this.props.forgotPassword}></div>
				<div className="modal-box">
					<div className="modal-header">
						<h2 style={{ marginBottom: 0 }}>Forgot Password</h2>
						<Icon
							name="close"
							style={{
								display: "flex",
								alignSelf: "center",
								cursor: "pointer",
								fontSize: "18px",
								color: this.props.darkMode ? "gray" : "gray"
							}}
							onClick={this.props.forgotPassword}
						/>
					</div>

					<div className="modal-body">
						<div className="textfield-container">
							<div className="textfield">
								<div
									style={{
										display: "flex",
										justifyContent: "space-between"
									}}
								>
									<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>{this.state.emailError ? <Error message="User must provide a valid email address" /> : null}</div>
								</div>
								<div className="form-group">
									<input type="text" id="email" placeholder="Email Address" />
									<SiMaildotru className="input-icon" />
								</div>
							</div>
						</div>

						<div className="auth-btns">
							<a disabled={this.state.submitEmailLoader} className={this.state.submitEmailLoader ? "black-disabled" : "black"} onClick={() => this.validation(document.getElementById("email").value)}>
								{this.state.submitEmailLoader ? (
									<span>
										<DotLoader />
									</span>
								) : (
									<span>SUBMIT</span>
								)}
							</a>
						</div>

						<div className="modal-links">
							<Link
								to="/privacy"
								className="title"
								onClick={() => {
									this.props.hideModal();
								}}
							>
								Privacy Policy
							</Link>
							<div style={{ width: "2px", height: "15px", backgroundColor: "gray", margin: "0px 8px 5px 8px" }} />
							<Link
								to="/terms"
								className="title"
								onClick={() => {
									this.props.hideModal();
								}}
							>
								Terms and Conditions
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
