import { SET_BROWSER_NAME } from "../types";

const initialState = {
  browserName: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BROWSER_NAME:
      return {
        ...state,
        browserName: action.payload,
      };

    default:
      return state;
  }
};
