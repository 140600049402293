import React from "react";
import store from "../../../redux/store";
import "./del.scss";
import { Button } from "semantic-ui-react";

const Del = ({ hideModal, darkMode, deleteAccount, accDeleteStatus, setAccDeleteStatus, accDelLoader }) => {
	React.useEffect(() => {
		// console.log("accDeleteStatus", accDeleteStatus);
		if (accDeleteStatus && accDeleteStatus === "deleted") {
			// setTimeout(function() {
			hideModal();
			setAccDeleteStatus("");
			// }, 0);
		}
	});

	return (
		<div className={darkMode ? "modal-container-dark" : "modal-container"}>
			<div className="backdrop" onClick={hideModal}></div>
			<div className="modal-box" style={{ borderRadius: "5px" }}>
				<div className="modal-header" style={{ display: "flex", alignItems: "center", justifyContent: "center", border: "none", fontFamily: "Montserrat" }}>
					<h2 style={{ marginBottom: 0, fontWeight: "lighter", fontSize: "30px" }}>Are you sure ?</h2>
				</div>
				<div className="modal-content">
					<span>You won't be able to revert this!</span>
				</div>
				<div className="del-button-wrapper">
					<Button disabled={accDelLoader} onClick={hideModal} style={{ margin: "5px", fontFamily: "montserrat", fontWeight: 300, fontSize: 15 }} color="red">
						No, cancle
					</Button>
					<Button disabled={accDelLoader} loading={accDelLoader} onClick={deleteAccount} style={{ margin: "5px", fontFamily: "montserrat", fontWeight: 300, fontSize: 15 }} secondary color="blue">
						{!accDelLoader ? "Yes, delete" : "Loading.."}
					</Button>
				</div>
				<p>{accDeleteStatus}</p>
			</div>
		</div>
	);
};

export default Del;
