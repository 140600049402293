import React from "react";
import Error from "../../layout/TopBar/ErrorMessage";
import { Icon, Popup } from "semantic-ui-react";
import store from "../../redux/store";

const EmailField = ({ label, edit, close, emailError, emailErrorMessage, emailValue, fieldChange, saveEmail }) => {
	const darkMode = store.getState().settings.darkMode;

	return (
		<div className="textfield">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<label style={{ color: darkMode ? "gray" : "black" }} for="email" className="title">
					{label}
				</label>
				{emailError ? <Error message={emailErrorMessage} /> : null}
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<input type="text" id="email" value={emailValue} className={emailError ? "error" : null} onChange={() => fieldChange("email")} disabled />

				<div
					style={{
						width: 53,
						paddingTop: 5,
						textAlign: "center"
					}}
				>
					{saveEmail ? (
						<Popup
							trigger={
								<span>
									<Icon name="check" onClick={() => close("email", document.getElementById("email").value)} />
								</span>
							}
							content="save"
							inverted
						/>
					) : (
						<Popup
							trigger={
								<span>
									<Icon name="edit" onClick={() => edit("email")} />
								</span>
							}
							content="edit email"
							inverted
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmailField;
