import React, { useRef } from "react";
import "./newRandomLink.scss";
import { Icon } from "semantic-ui-react";
import store from "../../redux/store";
import { connect, useSelector } from "react-redux";
import { OpenInBrowser } from "@material-ui/icons";
import { isEmpty } from "../../validation/validation";
import { RiForbidLine } from "react-icons/ri";
import { toast } from "react-toastify";

const NewRandomLink = (props) => {
  let containerRef = useRef();
  let [dMotion, setDmotion] = React.useState(true);
  const darkMode = useSelector((state) => state.settings.darkMode);

  const _flotifyOnHover = () => {
    document.getElementById("floaty").classList.add("is-active");
  };

  const _flotifyOnHoverRemove = () => {
    document.getElementById("floaty").classList.remove("is-active");
  };

  const _containerClick = () => {
    document.getElementById("container").classList.remove("is-active");
  };

  const _floatifyClick = () => {
    document.getElementById("floaty").classList.add("is-active");
    if (document.getElementById("floaty-list").style.opacity === "0") {
      document.getElementById("floaty-list").style.opacity = "1";
    } else {
      document.getElementById("floaty-list").style.opacity = "0";
    }
  };

  const activeteAll_ = () => {
    let allCats = props.categories.categories.map((data) => {
      return data.term_id;
    });
    if (isEmpty(props.isAuth)) {
      store.dispatch({ type: "SET_RANDOM_LINK_CATEGORIES", payload: allCats });
    } else {
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: allCats,
      });
    }
  };

  const deactiveteAll_ = () => {
    let allCats = [];
    if (isEmpty(props.isAuth)) {
      store.dispatch({ type: "SET_RANDOM_LINK_CATEGORIES", payload: allCats });
    } else {
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: allCats,
      });
    }
  };

  const _changeLinkStatus = (cat, item) => {
    setDmotion(false);

    if (isEmpty(props.isAuth)) {
      // for un-auth menu
      let isCatActive = props.randomLinkCategories.find((id) => {
        return id === cat.term_id;
      });
      let updateCatList = [...props.randomLinkCategories];
      if (isCatActive) {
        // remove from cat active list
        updateCatList = [...props.randomLinkCategories].filter((data) => {
          return data !== cat.term_id;
        });
      } else {
        // add new cat active list
        updateCatList.push(cat.term_id);
      }
      store.dispatch({
        type: "SET_RANDOM_LINK_CATEGORIES",
        payload: updateCatList,
      });
    } else {
      // for auth menu
      let isCatActive = props.authRandomLinkCategories.find((id) => {
        return id === cat.term_id;
      });

      let updateCatList = [...props.authRandomLinkCategories];

      if (isCatActive) {
        // remove from cat active list
        updateCatList = [...props.authRandomLinkCategories].filter((data) => {
          return data !== cat.term_id;
        });
      } else {
        // add new cat active list
        updateCatList.push(cat.term_id);
      }
      store.dispatch({
        type: "SET_AUTH_RANDOM_LINK_CATEGORIES",
        payload: updateCatList,
      });
    }
  };

  let soredDraggableArry = localStorage.getItem("draggableArry")
    ? JSON.parse(localStorage.getItem("draggableArry"))
    : props.categories.categories;
  let customersLists =
    Object.keys(props.user).length !== 0
      ? props.categories.authCategories
      : soredDraggableArry;

  if (dMotion) {
    var myDiv = document.getElementById("floaty-list");
    if (myDiv) {
      myDiv.scrollTop = myDiv.scrollHeight;
    }
  }

  let list1 = [...customersLists].filter(
    (data) => data.label !== "All Categories"
  );
  let list2 = (
    isEmpty(props.isAuth)
      ? [...props.randomLinkCategories]
      : [...props.authRandomLinkCategories]
  ).filter((data) => data !== 884886);
  let allCatActive = list1.length === list2.length || false;
  let allCatDeActive = isEmpty(props.isAuth)
    ? isEmpty(props.randomLinkCategories)
    : isEmpty(props.authRandomLinkCategories);

  // let mar = "-5rem";
  // if (window.innerHeight > 650) {
  // 	mar = "-5.2rem";
  // }
  // if (window.innerHeight > 700) {
  // 	mar = "-5.4rem";
  // }
  // if (window.innerHeight > 750) {
  // 	mar = "-5.6rem";
  // }
  // if (window.innerHeight > 800) {
  // 	mar = "-5.8rem";
  // }
  // if (window.innerHeight > 850) {
  // 	mar = "-6rem";
  // }
  // if (window.innerHeight > 900) {
  // 	mar = "-6.2rem";
  // }
  // if (window.innerHeight > 950) {
  // 	mar = "-6.4rem";
  // }
  // if (window.innerHeight > 1000) {
  // 	mar = "-6.6rem";
  // } else {
  // 	mar = "-7.8rem";
  // }
  // if (window.outerHeight > 1000) {
  // 	mar = -95;
  // }
  // if (window.outerHeight > 1020) {
  // 	mar = -100;
  // }
  // if (window.outerHeight > 1060) {
  // 	mar = -105;
  // }
  // if (window.outerHeight > 1100) {
  // 	mar = -110;
  // }
  // if (window.outerHeight > 1240) {
  // 	mar = -120;
  // }
  // if (window.outerHeight > 1280) {
  // 	mar = -125;
  // }
  // if (window.outerHeight > 1320) {
  // 	mar = -130;
  // }
  // if (window.outerHeight > 1360) {
  // 	mar = -135;
  // }
  // if (window.outerHeight > 1400) {
  // 	mar = -140;
  // }
  // if (window.outerHeight > 1440) {
  // 	mar = -145;
  // }
  // if (window.outerHeight > 1480) {
  // 	mar = -150;
  // }
  // if (window.outerHeight > 1520) {
  // 	mar = -155;
  // }
  // if (window.outerHeight > 1560) {
  // 	mar = -160;
  // }
  // if (window.outerHeight > 1600) {
  // 	mar = -165;
  // }
  // if (window.outerHeight > 1640) {
  // 	mar = -170;
  // }
  // if (window.outerHeight > 1680) {
  // 	mar = -175;
  // }
  // if (window.outerHeight > 1720) {
  // 	mar = -180;
  // }
  // if (window.outerHeight > 1760) {
  // 	mar = -190;
  // }
  // if (window.outerHeight > 1800) {
  // 	mar = -200;
  // }

  return (
    <>
      <div class="popup-wrapper">
        <div class="item-popup">
          <div class="container" id="container">
            <div
              id="floaty"
              onMouseEnter={_flotifyOnHover}
              onMouseLeave={_flotifyOnHoverRemove}
              class="floaty"
              onclick="myFunction()"
              style={{
                marginRight: "-10px",
                bottom:
                  window.innerHeight > 1000
                    ? "-8.0rem"
                    : window.innerHeight > 900
                    ? "-7.8rem"
                    : window.innerHeight > 800
                    ? "-6.8rem"
                    : window.innerHeight > 700
                    ? "-5.8rem"
                    : window.innerHeight > 600
                    ? "-4.8rem"
                    : window.innerHeight > 500
                    ? "-3.8rem"
                    : window.innerHeight > 400
                    ? "-2.8rem"
                    : "-1.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <ul
                class="floaty-list"
                id="floaty-list"
                ref={containerRef}
                style={{
                  maxHeight: `${window.innerHeight - 30}px`,
                  overflowY: "scroll",
                  alignSelf: "right",
                  right: 0,
                  bottom: "0px",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  scrollbarWidth: "thin",
                }}
              >
                {/* <div className="random-link-list" style={{ maxHeight: `${window.innerHeight - 30}px` }}> */}
                {[...customersLists]
                  .reverse()
                  .filter((data) => data.label !== "All Categories")
                  .map((data, index) => {
                    let isActive = (
                      isEmpty(props.isAuth)
                        ? props.randomLinkCategories
                        : props.authRandomLinkCategories
                    ).find((id) => id === data.term_id);

                    return (
                      <li
                        key={index}
                        id={`floaty-list-${index}`}
                        onClick={() =>
                          _changeLinkStatus(data, `float-${index}`)
                        }
                        class={`floaty-list-item${
                          isActive ? "" : " deselected"
                        }`}
                        style={{
                          backgroundColor: props.darkMode ? "#3C3C3C" : null,
                          marginLeft: "240px",
                          overflowX: "visible",
                        }}
                      >
                        <span
                          class="floaty-list-item-label"
                          style={{
                            color:
                              data.color && data.color.length > 3
                                ? data.color
                                : "#8A8A8A",
                            backgroundColor: props.darkMode
                              ? "#3C3C3C"
                              : "#EDF0F5",
                            overflowX: "visible",
                            zIndex: 10000001,
                            position: "absolute",
                          }}
                        >
                          {data.label}
                          {isActive ? null : (
                            <span
                              style={{
                                color: "#8a8a8a",
                                marginLeft: "5px",
                                fontWeight: "bold",
                              }}
                            >
                              Excluded
                            </span>
                          )}
                        </span>
                        {data.icon ? (
                          <img class="cat-icon" src={data.icon} />
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <i
                              class="cat-icon"
                              className="flaticon-picture-1 flaticon-icon"
                              style={{
                                color: isActive ? "#8A8A8A" : "#D0D0D0",
                                top: "30%",
                                left: "30%",
                                position: "absolute",
                              }}
                            ></i>
                          </div>
                        )}
                      </li>
                    );
                  })}

                <li
                  class={`floaty-list-item ${allCatActive ? "deselected" : ""}`}
                  onClick={deactiveteAll_}
                  style={{
                    backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    marginLeft: "240px",
                  }}
                >
                  <span
                    class="floaty-list-item-label grey"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    DeActivate All
                  </span>
                  {/* <RiForbidLine className="cat-icon all-icon" /> */}
                  <img
                    class="cat-icon all-icon"
                    src="https://www.freeiconspng.com/uploads/no-image-icon-32.png"
                  />
                </li>
                <li
                  class={`floaty-list-item ${allCatActive ? "" : "deselected"}`}
                  onClick={activeteAll_}
                  style={{
                    backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    marginLeft: "240px",
                  }}
                >
                  <span
                    class="floaty-list-item-label grey"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    Activate All
                  </span>
                  <img
                    class="cat-icon all-icon"
                    src="https://app.pikilinks.com/wp-content/uploads/2021/05/all-cat.png"
                  />
                </li>
                {/* </div> */}
              </ul>
              <div
                id={`control-buttons`}
                style={{
                  height: "100%",
                  borderWidth: "3px",
                  borderColor: "red",
                  display: "flex",
                  width: "100%",
                  alignItems: "cemnter",
                  justifyContent: "center",
                }}
              >
                {props.randomLinkBrowserOpenMode ? (
                  // class="toggle-btn"
                  <div
                    class="toggle-btn"
                    style={{
                      cursor: "pointer",
                      backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    }}
                  >
                    <span
                      class="floaty-btn-label2"
                      style={{
                        backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                      }}
                    >
                      Open in browser
                    </span>
                    <label
                      class="tog"
                      style={{ cursor: "pointer" }}
                      onClick={props.setRandomLinkOpenType}
                    >
                      <span
                        style={{
                          color: "#808080",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <OpenInBrowser style={{ fontSize: "22px" }} />
                      </span>
                    </label>
                  </div>
                ) : (
                  // class="toggle-btn"
                  <div
                    class="toggle-btn"
                    style={{
                      cursor: "pointer",
                      backgroundColor: props.darkMode ? "#3C3C3C" : null,
                    }}
                  >
                    <span
                      class="floaty-btn-label2"
                      style={{
                        backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                      }}
                    >
                      Open by page
                    </span>
                    <label
                      class="tog"
                      style={{ cursor: "pointer" }}
                      onClick={props.setRandomLinkOpenType}
                    >
                      <span>
                        <i
                          style={{ color: "#808080" }}
                          class={`fas fa-external-link-alt`}
                        />
                      </span>
                    </label>
                  </div>
                )}

                <div
                  class="floaty-btn"
                  onClick={_floatifyClick}
                  style={{ backgroundColor: props.darkMode ? "#3C3C3C" : null }}
                >
                  <span
                    class="floaty-btn-label"
                    style={{
                      backgroundColor: props.darkMode ? "#3C3C3C" : "#EDF0F5",
                    }}
                  >
                    Exclude Settings
                  </span>
                  <img
                    class="gear-icon"
                    src="https://app.pikilinks.com/wp-content/uploads/2021/05/gear-grey1.png"
                  />
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div
            class="popup-content"
            onClick={() => {
              if (allCatDeActive) {
                toast.dark(
                  <b style={{ width: "400px" }}>
                    Activate at least one category!
                  </b>,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: true,
                    style: {
                      backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
                    },
                  }
                );
                return;
              }
              props.onRandomClick();
            }}
            style={{ cursor: "pointer" }}
          >
            <a
              style={{
                backgroundColor: props.darkMode ? "#6b6b6b" : null,
                borderColor: props.darkMode ? "#6b6b6b" : null,
              }}
              // onClick={props.onRandomClick}
              title=""
              class="popup-button"
              data-wpel-link="internal"
            >
              {props.randomPageLoading ? (
                <Icon loading name="spinner" style={{ marginLeft: "4px" }} />
              ) : (
                <Icon name="shuffle" style={{ marginLeft: "4px" }} />
              )}
            </a>{" "}
            <p style={{ marginTop: "3px" }} id="random-post-btn">
              RANDOM LINK
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(NewRandomLink);
