import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Label, Form } from "semantic-ui-react";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { askQuestion } from "../../api/api";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { toast } from "react-toastify";

const ErrorBody = (props) => {
	if (!props.children) return null;
	return (
		<Label basic color="red" pointing>
			{props.children}
		</Label>
	);
};

function AskModal(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);
	const notify = (msg) =>
		toast.dark(msg, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }
		});

	const captchaRef = useRef(null);
	const [errors, setErrors] = React.useState({});
	const [qsn, setQsnData] = React.useState({ qsn: "" });
	const [submitting, setSubmit] = React.useState(false);
	const [recaptchaResponse, setRecaptchaResponse] = React.useState("");
	const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);

	const _changeQsn = (e) => {
		setErrors({ ...qsn, qsn: "" });
		setQsnData({
			...qsn,
			qsn: e.target.value
		});
	};

	React.useEffect(() => {
		setErrors({});
		setQsnData({
			qsn: ""
		});
		setRecaptchaResponse("");
		setRecaptchaVerified(false);
	}, []);

	const _showResponse = (res) => {
		setErrors({ ...errors, "g-recaptcha-response": "" });
		setRecaptchaResponse(res);
		setRecaptchaVerified(true);
		setQsnData({
			...qsn,
			"g-recaptcha-response": res
		});
	};

	const _expireRecaptcha = (res) => {
		setRecaptchaVerified(false);
		setRecaptchaResponse("");
	};

	const _submit = () => {
		if (qsn.qsn.length <= 0) {
			setErrors({ ...errors, qsn: "Field cant be empty" });
			return;
		}

		if (!recaptchaVerified) {
			setErrors({ ...errors, "g-recaptcha-response": "failed!!" });
			setRecaptchaResponse("");
			setRecaptchaVerified(false);
			return;
		}
		setSubmit(true);
		askQuestion({ question: qsn.qsn, "g-recaptcha-response": recaptchaResponse })
			.then((res) => {
				setSubmit(false);
				if (res.response.ok) {
					notify("Submitted successfully");
					props.onClose();
				} else {
					notify("Submission Failed");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal
			onClose={() => {
				props.onClose();
			}}
			onOpen={props.onClose}
			open={props.open}
			style={{ height: "90%" }}
			className={darkMode ? "ask-modal-dark" : "ask-modal-light"}
		>
			<Modal.Header className={darkMode ? "ask-modal-head-dark" : "ask-modal-head-light"} style={{ display: "flex", flex: 1 }}>
				<div style={{ display: "flex", flex: 0.5 }}>
					<span style={{ fontSize: "18px" }}>Ask Qusetion</span>
				</div>
				<div style={{ display: "flex", flex: 0.5, alignItems: "flex-end", justifyContent: "flex-end" }}>
					<MdClose style={{ color: darkMode ? "lightgray" : "black", cursor: "pointer" }} onClick={props.onClose} />
				</div>
			</Modal.Header>
			<Modal.Content
				style={{
					display: "flex",
					justifyContent: "space-between",
					backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
					borderRadius: "0px",
					padding: "5px",
					display: "flex",
					flexDirection: "column"
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", padding: "0px 10px 0px 10px" }}>
					{/* <span style={{ fontSize: "15px", padding: "5px", color: darkMode ? "white" : "black" }}>Ask</span> */}
					<textarea
						value={qsn.qsn}
						onChange={_changeQsn}
						rows={8}
						style={{ width: "100%", backgroundColor: "lightgray", borderStyle: "none", borderRadius: "1px", padding: "10px" }}
						placeholder="Write Question here..."
					/>
					<ErrorBody>{errors.qsn}</ErrorBody>
					<div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
						<ReCAPTCHAV2 theme={darkMode ? `dark` : `light`} ref={captchaRef} sitekey={`6LeT7R4aAAAAANggb_D-U_4DQIHo0DIgFpZ07984`} onChange={_showResponse} onExpired={_expireRecaptcha} />
						<ErrorBody>{errors["g-recaptcha-response"]}</ErrorBody>
					</div>
					<div style={{ marginTop: "20px" }}>
						<button className="theme-btn" onClick={_submit} disabled={submitting || qsn.length < 1 || !recaptchaVerified}>
							Submit
						</button>
					</div>
					<div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", padding: "10px" }}>
						<NavLink to="/privacy" onClick={() => props.onClose()}>
							<b>Privacy Policy</b>
						</NavLink>{" "}
						<b style={{ color: "gray" }}>|</b>
						<NavLink to="/terms" onClick={() => props.onClose()}>
							{" "}
							<b>Terms and Conditions</b>
						</NavLink>
					</div>
				</div>
			</Modal.Content>
		</Modal>
	);
}

export default AskModal;
