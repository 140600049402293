import React, { Component } from "react";
import Error from "./ErrorMessage";
import Axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";
import Loader from "../../components/Loader/Loader";
import "./error.scss";
import { getPostsByIds } from "../../api/api";
import _ from "lodash";
import { connect } from "react-redux";
import {
  SET_AUTH_FAVOURITE_POSTS,
  SET_CAT_LIST,
  SET_SUBSCRIBE_BUTTON_STATUS,
  SET_AUTH_USER,
  SET_REGISTERED_EMAIL,
} from "../../redux/types";
import store from "../../redux/store";
import { isEmpty } from "../../validation/validation";
import jwtdecode from "jwt-decode";
import GLOBALS from "../../constants";
import { SiMaildotru } from "react-icons/si";
import { BiLock } from "react-icons/bi";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailError: {
        status: false,
        message: "User must provide a valid email address",
      },
      passwordError: false,
      passwordVisibility: false,
      loading: false,
    };
  }

  handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      this.validate();
    }
  };

  passwordVisibility = () => {
    this.setState({ passwordVisibility: !this.state.passwordVisibility });
    if (document.getElementById("password").type == "password") {
      document.getElementById("password").type = "text";
    } else {
      document.getElementById("password").type = "password";
    }
  };

  validate = () => {
    var error = false;
    const em = document.getElementById("email").value;
    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        document.getElementById("email").value
      )
    ) {
      this.setState((prevState) => ({
        emailError: {
          ...prevState.emailError,
          status: true,
          message: "User must provide a valid email address",
        },
      }));
      error = true;
    } else {
      store.dispatch({ type: SET_REGISTERED_EMAIL, payload: em });
      this.setState((prevState) => ({
        emailError: {
          ...prevState.emailError,
          status: false,
          message: "",
        },
      }));
    }

    if (document.getElementById("password").value.length === 0) {
      this.setState({ passwordError: true });
      error = true;
    } else {
      this.setState({ passwordError: false });
    }

    if (!error) {
      this.authenticate();
    }
  };

  fetchFavData_ = async (token) => {
    return Axios({
      url: `${GLOBALS.SERVER_URL}wp-json/ng/v1/favorite-list`,
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(async (finRes) => {
        if (finRes.status === 200) {
          let ids = [];
          finRes.data.posts.map((data) => {
            if (!isEmpty(data.categories)) {
              ids.push(data.ID);
            }
          });
          const uniqueIds = _.uniq(ids);

          // get fav posts full details
          await getPostsByIds(`ids=${uniqueIds.join(",")}`)
            .then((finRess) => {
              // FavPosts = finRes.json.posts;
              store.dispatch({
                type: SET_AUTH_FAVOURITE_POSTS,
                payload: finRess.json.posts,
              });
              // setPostsArr(finRess.json.posts);
              // setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              // setLoading(false);
            });
        } else {
          return;
          // setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  authenticate = () => {
    this.setState({ loading: true });
    return Axios({
      url: `${GLOBALS.SERVER_URL}wp-json/jwt-auth/v1/token`,
      method: "POST",
      withCredentials: true,
      data: {
        username: document.getElementById("email").value,
        password: document.getElementById("password").value,
      },
    }).then(
      (res) => {
        if (res.status === 200 && res.data.token) {
          localStorage.setItem("userToken", res.data.token);
          localStorage.setItem("userEmail", res.data.user_email);
          localStorage.setItem("userName", res.data.user_display_name);
          localStorage.setItem("isSubVisible", false);
          store.dispatch({
            type: SET_AUTH_USER,
            payload: { email: res.data.user_email, token: res.data.token },
          });
          store.dispatch({ type: SET_SUBSCRIBE_BUTTON_STATUS, payload: false });
          this.props.modalShow();
          this.props.titleVisibility();
          // await this.fetchFavData_();
          // window.location.reload("/");
          this.setState({ loading: false });
          this.props.history.push("/");
          store.dispatch({ type: SET_CAT_LIST, payload: [] });
          this.notify(`Welcome ${res.data.user_display_name}`);
        } else {
          this.notify("Failed to login!!");
          this.setState({ loading: false });
        }
      },
      (err) => {
        if (err.response.data.code === "unconfirmed") {
          this.props.switchModal("verify");
          this.setState({ loading: false });
        }
        if (err.response.data.code.includes("incorrect_password")) {
          this.setState({ loading: false });
          this.setState((prevState) => ({
            emailError: {
              ...prevState.emailError,
              status: true,
              message: "Email not found",
            },
          }));
        } else if (
          err.response.data.code.includes("application_passwords_disabled")
        ) {
          this.setState({ loading: false });
          this.setState({ passwordError: true });
        }
      }
    );
  };

  notify = (user_display_name) =>
    toast.dark(`${user_display_name}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { backgroundColor: this.props.darkMode ? "#3C3C3C" : "#EDF0F5" },
    });

  render() {
    return (
      <>
        {this.state.loading ? (
          <Loader smallLoader={true} loadingModal="Logging In" />
        ) : (
          <div>
            <div className="textfield-container">
              {this.props.modalWarning && this.props.modalWarning.status ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <span
                    style={{
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    {this.props.modalWarning.warning}
                  </span>
                </div>
              ) : null}
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.emailError.status ? (
                    <Error
                      message={this.state.emailError.message}
                      id="emailError"
                    />
                  ) : null}
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    id="email"
                    placeholder="Enter Email"
                    autoComplete="new-password"
                    className={
                      this.state.emailError.status ? "errorField" : null
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                  <SiMaildotru className="input-icon" />
                </div>
              </div>

              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <label htmlFor="password" className="title">
										Password
									</label> */}
                  {this.state.passwordError ? (
                    <Error
                      message="Password and email do not match"
                      id="passwordError"
                    />
                  ) : null}
                </div>
                <div className="passwordField-container">
                  <input
                    type="password"
                    id="password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    style={{ paddingRight: 35 }}
                    className={this.state.passwordError ? "errorField" : null}
                    onKeyDown={this.handleKeyDown}
                  />
                  <BiLock className="input-icon" />
                  <div className="eye-icon">
                    {this.state.passwordVisibility ? (
                      <Icon
                        name="eye slash"
                        onClick={this.passwordVisibility}
                      />
                    ) : (
                      <Icon name="eye" onClick={this.passwordVisibility} />
                    )}
                  </div>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                <a
                  onClick={this.props.forgotPassword}
                  style={{ cursor: "pointer" }}
                  className="title"
                >
                  Forgot Password?
                </a>
              </div>
            </div>

            <div className="auth-btns">
              <a
                className="purple"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.props.switchModal("register");
                  this.props.switchToCard("register");
                }}
              >
                REGISTER
              </a>
              <a className="blue" onClick={this.validate}>
                LOGIN
              </a>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default LoginForm;
