export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const SET_HOME_POSTS = "SET_HOME_POSTS";
export const SET_MOST_RECENT_ONE = "SET_MOST_RECENT_ONE";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_ACTIVE_TAG = "SET_ACTIVE_TAG";

export const SET_AUTH_USER = "SET_AUTH_USER";

export const SET_LIST_MODE = "SET_LIST_MODE";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const SET_TAGS_MODE = "SET_TAGS_MODE";
export const SET_BANNERS_MODE = "SET_BANNERS_MODE";
export const SET_MENU_ICON_MODE = "SET_MENU_ICON_MODE";
export const SET_UPVOTES = "SET_UPVOTE";

export const UPDATE_TAGS = "UPDATE_TAGS";
export const RESET_TAGS = "RESET_TAGS";

export const SET_FAVOURITE_POSTS = "SET_FAVOURITE_POSTS";
export const SET_SINGLE_POST = "SET_SINGLE_POST";
export const SET_AUTH_FAVOURITE_POSTS = "SET_AUTH_FAVOURITE_POSTS";

export const SET_SUBSCRIBE_BUTTON_STATUS = "SET_SUBSCRIBE_BUTTON_STATUS";

export const SET_MOST_RECENT_POSTS = "SET_MOST_RECENT_POSTS";
export const SET_MOST_POPULAR_POSTS = "SET_MOST_POPULAR_POSTS";

export const SET_LISTS = "SET_LISTS";
export const UPDATE_LISTS = "UPDATE_LISTS";

export const SET_CATEGORIES_ARR = "SET_CATEGORIES_ARR";
export const UPDATE_CAT_POSTS = "UPDATE_CAT_POSTS";

export const REFRESH_MENU_ORDER = "REFRESH_MENU_ORDER";

export const SAVE_INSTALL_PROPOSAL_EVENT = "SAVE_INSTALL_PROPOSAL_EVENT";
export const PROMPT_ADD_TO_HOME_SCREEN = "PROMPT_ADD_TO_HOME_SCREEN";

export const SET_BROWSER_NAME = "SET_BROWSER_NAME";
export const CHANGE_TAGS_BAR_FIXED = "CHANGE_TAGS_BAR_FIXED";

export const SEARCH_INPUT_SHOWING = "SEARCH_INPUT_SHOWING";

export const SET_FEATURED_POSTS = "SET_FEATURED_POSTS";

export const SET_SEARCH_RESULTS_PAGE = "SET_SEARCH_RESULTS_PAGE";

export const SET_CAT_LIST = "SET_CAT_LIST";

export const SET_AUTH_CAT_MENU_LIST = "SET_AUTH_CAT_MENU_LIST";

export const SET_REGISTERED_EMAIL = "SET_REGISTERED_EMAIL";

export const SET_LINK_MODE = "SET_LINK_MODE";

export const UPDATE_WHOLE_TAGS = "UPDATE_WHOLE_TAGS";

export const SET_TAGS_BY_POST_ORDER = "SET_TAGS_BY_POST_ORDER";

export const SET_TAGS_BY_FAV_POSTS_ORDER = "SET_TAGS_BY_FAV_POSTS_ORDER";

export const SET_TAGS_BY_CATEGORY_POSTS_ORDER = "SET_TAGS_BY_CATEGORY_POSTS_ORDER";

export const SET_TAGS_BY_ICON_POSTS_ORDER = "SET_TAGS_BY_ICON_POSTS_ORDER";

export const SET_NEW_WINDOW = "SET_NEW_WINDOW";

export const SET_RANDOM_LINK_CATEGORIES = "SET_RANDOM_LINK_CATEGORIES";

export const UPDATE_SUBSCRIBED_CATEGORIES = "UPDATE_SUBSCRIBED_CATEGORIES";

export const SET_DELETE_MODAL = "SET_DELETE_MODAL";

export const SET_RANDOM_LINK_BROWSER_OPEN_MODE = "SET_RANDOM_LINK_BROWSER_OPEN_MODE";

export const SET_ACTIVE_SLIDE = "SET_ACTIVE_SLIDE";

export const SET_ACTIVE_SLIDE_LISTS = "SET_ACTIVE_SLIDE_LISTS";

export const SET_ACTIVE_SLIDE_CATEGORY = "SET_ACTIVE_SLIDE_CATEGORY";
export const SET_UNAUTH_ACTIVE_SLIDE = "SET_UNAUTH_ACTIVE_SLIDE";
export const SET_UNAUTH_ACTIVE_SLIDE_LISTS = "SET_UNAUTH_ACTIVE_SLIDE_LISTS";
export const SET_UNAUTH_ACTIVE_SLIDE_CATEGORY = "SET_UNAUTH_ACTIVE_SLIDE_CATEGORY";

export const SET_WINDOW_MODE = "SET_WINDOW_MODE";

export const SET_POST_CARD_DATA_MODAL = "SET_POST_CARD_DATA_MODAL";
export const SET_POST_MODAL_DATA = "SET_POST_MODAL_DATA";
export const SET_FILTER_BAR = "SET_FILTER_BAR";
export const SET_AUTH_MODAL_CHANGE = "SET_AUTH_MODAL_CHANGE";
export const SET_FEEDBACK_MODAL = "SET_FEEDBACK_MODAL";
export const PWA_WINDOW_ON = "PWA_WINDOW_ON";
export const SET_LINK_TYPES = "SET_LINK_TYPES";
export const SET_UNCHECKED_LINKS = "SET_UNCHECKED_LINKS";
export const UNINSTALL_PWA_MODAL = "UNINSTALL_PWA_MODAL";
export const SET_HOME_POSTS_ARRAY = "SET_HOME_POSTS_ARRAY";
export const SET_IS_DROP_ON = "SET_IS_DROP_ON";
export const SET_AUTH_RANDOM_LINK_CATEGORIES = "SET_AUTH_RANDOM_LINK_CATEGORIES";
export const SET_MODAL_REF = "SET_MODAL_REF";

export const SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR = "SET_UNAUTH_ACTIVE_SLIDE_MOST_POPULAR";
export const SET_ACTIVE_SLIDE_MOST_POPULAR = "SET_ACTIVE_SLIDE_MOST_POPULAR";
export const SET_ACTIVE_SLIDE_MOST_RECENT = "SET_ACTIVE_SLIDE_MOST_RECENT";
export const SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT = "SET_UNAUTH_ACTIVE_SLIDE_MOST_RECENT";

export const SET_ICONS_LIBRARY_MODAL = "SET_ICONS_LIBRARY_MODAL";

export const SET_COOKIE = "SET_COOKIE";

export const SET_LOGO = "SET_LOGO";
