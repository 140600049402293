import React, { useEffect, useState } from "react";
import LoaderPage from "../../components/Loader/LoaderPage";
import { toast } from "react-toastify";
import { subscribe } from "../../api/api";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function SubscribeConfirmation(props) {
  const darkMode = useSelector((state) => state.settings.darkMode);
  const [verificationStatus, setVerificationStatus] = useState(false);
  function getJsonFromUrl(query) {
    var result = {};
    query.split("&").forEach(function (part) {
      if (!part) return;
      part = part.split("+").join(" "); // + to space, regexp-free version
      var eq = part.indexOf("=");
      var key = eq > -1 ? part.substr(0, eq) : part;
      var val = eq > -1 ? decodeURIComponent(part.substr(eq + 1)) : "";
      var from = key.indexOf("[");
      if (from == -1) result[decodeURIComponent(key)] = val;
      else {
        var to = key.indexOf("]", from);
        var index = decodeURIComponent(key.substring(from + 1, to));
        key = decodeURIComponent(key.substring(0, from));
        if (!result[key]) result[key] = [];
        if (!index) result[key].push(val);
        else result[key][index] = val;
      }
    });
    return result;
  }

  const _subscribe = (subdata) => {
    subscribe({
      email: subdata.email,
      subscription_id: subdata.subscription_id,
    })
      .then((res) => {
        if (res.json.status) {
          toast.dark(<b style={{ width: "400px" }}>Successfully verified!</b>, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            hideProgressBar: true,
            style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
          });
          setVerificationStatus(true);
        } else {
          if (res.json.message) {
            toast.dark(<b style={{ width: "400px" }}>{res.json.message}</b>, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
              hideProgressBar: true,
              style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
            });
            setVerificationStatus(true);
          }
          throw res.json.message;
        }
      })
      .catch((err) => {
        toast.dark(<b style={{ width: "400px" }}>Confirmation failed!</b>, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
        });
        setVerificationStatus(true);
      });
  };

  useEffect(() => {
    //props here
    if (props.location.search) {
      let paramsJson = getJsonFromUrl(props.location.search.substring(1));
      _subscribe(paramsJson);
    } else {
      // redirect to home page
      toast.dark(<b style={{ width: "400px" }}>Confirmation failed!</b>, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        style: { backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" },
      });
      setVerificationStatus(true);
    }
  }, [props.location]);

  return verificationStatus ? <Redirect to="/" /> : <LoaderPage />;
}

export default SubscribeConfirmation;
