import React from "react";
import "./glass.scss";

function Glass(props) {
	return (
		<div className={"bg frosted-glass " + (props.className ? props.className : "")}>
			<div className="frosted-glass-content">{props.children}</div>
		</div>
	);
}

export default Glass;
