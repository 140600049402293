import React from "react";
import "./accountloader.scss";

export default function AccountLoader({ bcolor }) {
	return (
		<div class="lds-ring">
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
			<div style={{ borderColor: `${bcolor} transparent transparent transparent` }}></div>
		</div>
	);
}
