import React, { useState } from "react";
import { BiCookie } from "react-icons/bi";
import { FaCookieBite } from "react-icons/fa";
//from packages
import { Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

function AcceptCookies() {
	const [key, setKey] = useState(new Date());
	let cookieContent = store.getState().world.cookieContent;
	const _acceptCookies = (e) => {
		localStorage.setItem("disclaimerResponded", "accepted");
		setKey(new Date());
	};
	const darkmode = useSelector((state) => state.settings.darkMode);

	let showingAcceptCookiesBanner = true;
	if (localStorage.getItem("disclaimerResponded")) {
		showingAcceptCookiesBanner = false;
	}

	if (!showingAcceptCookiesBanner) {
		return null;
	}
	let isMobile = window.innerWidth <= 700;
	if (!cookieContent) return null;
	return (
		<div className={`disclaimer-container ${darkmode ? "c-dark" : "c-light"} ${isMobile && `disclaimer-container--full`}`}>
			<div className="container">
				<div className="disclaimer-text">
					<FaCookieBite />
					<br />
					<span style={{ lineHeight: "1px" }}>{cookieContent ? parse(cookieContent) : ""}</span>
					<br />
					<Button
						className="accept-button"
						size="tiny"
						positive
						style={{ backgroundColor: "black", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
						onClick={_acceptCookies}
					>
						<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Accept</span>
					</Button>
					<div className="pp">
						<NavLink to="/privacy">
							<b style={{ borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "#8a8a8a", paddingBottom: "0px" }}>Privacy Policy</b>
						</NavLink>{" "}
						<b>|</b>
						<NavLink to="/terms">
							{" "}
							<b style={{ borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "#8a8a8a", paddingBottom: "0px" }}>Terms and Conditions</b>
						</NavLink>
					</div>
					{/* <p style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
						We use cookies to give you the best possible experience on our website. By clicking OK, you agree to our{" "}
						<NavLink to="/privacy-policy" style={{ paddingLeft: "3px" }}>
							Policy
						</NavLink>
						. If you would like to see terms and conditions, have a look
						<span className="link-special" style={{ paddingLeft: "3px" }}>
							<NavLink to="/terms-and-conditions">here</NavLink>
						</span>
						.
						<Button className="accept-button" size="tiny" positive style={{ backgroundColor: "black", textAlign: "center", display: "flex" }} onClick={_acceptCookies}>
							<span style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3px" }}>Accept</span>
							<BiCookie style={{ fontSize: "18px", marginLeft: "5px" }} />
						</Button>
					</p> */}
				</div>
			</div>
		</div>
	);
}

export default AcceptCookies;
