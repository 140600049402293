import React, { useState, useEffect, useMemo, useRef } from "react";

//from packages
import PropTypes from "prop-types";
import { Dropdown } from "primereact/dropdown";

//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

//apis
import { getPostsByTag } from "../../api/api";
import store from "../../redux/store";
import { UPDATE_TAGS, SET_POST_CARD_DATA_MODAL } from "../../redux/types";
import { connect } from "react-redux";
import { DynamicMetaDecorator } from "../../components/MetaDecorator/DynamicMetaDecorator";
import { isEmpty } from "../../validation/validation";
import findCommonElements from "../../utils/findCommon";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const sortByOptions = [
	{ name: "Random", code: "Random", index: 1 },
	{ name: "Popularity", code: "Popularity", index: 2 },
	{ name: "Newest", code: "Newest", index: 3 },
	{ name: "Oldest", code: "Oldest", index: 4 }
];

function Tag(props) {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [tag, setTag] = useState("");
	const [sortBy, setSortBy] = useState(sortByOptions[0]);
	const [seoData, setSeoData] = useState({});
	const loader = useRef(null);
	const [pageNo, setPageNo] = useState(0);
	const [isFinished, setIsFinished] = useState(false);

	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("tags-page");
		document.getElementsByTagName("body")[0].removeAttribute("id");

		if (localStorage.getItem("tagSortBy")) {
			setSortBy(JSON.parse(localStorage.getItem("tagSortBy")));
		}
		const controller = new AbortController();
		const signal = controller.signal;

		if (pageNo > 0 && !isFinished) {
			// setLoading(true);

			getPostsByTag(`tag=${props.match.params.tagSlug}&paged=${pageNo}&sort_by=${sortBy.code ? sortBy.code.toLowerCase() : ""}`, signal)
				.then((res) => {
					res = res.json;
					setSeoData(res.tag.seo);
					setPosts([...posts, ...res.posts]);
					setTag(res.tag);
					if (res.posts.length < 10) {
						setIsFinished(true);
					}
				})
				.catch((err) => {
					console.log(err);
				});
			// .then(() => setLoading(false));
		}
		return () => {
			controller.abort();
			document.getElementsByTagName("body")[0].classList.add("tags-page");
		};
	}, [props.match.params.tagSlug, pageNo]);

	useEffect(()=>{
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({'piki_page':'tags'})
	  },[props.history])

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		if (pageNo > 1 && !isFinished) {
			getPostsByTag(`tag=${props.match.params.tagSlug}&paged=${pageNo}&sort_by=${sortBy.code ? sortBy.code.toLowerCase() : ""}`, signal)
				.then((res) => {
					res = res.json;
					setPosts([...posts, ...res.posts]);

					if (res.posts.length < 10) {
						setIsFinished(true);
					}
				})
				.catch((err) => {
					console.log(err);
					throw err;
				});
		}
		return () => {
			controller.abort();
			document.getElementsByTagName("body")[0].classList.add("tags-page");
		};
	}, [props.match.params.tagSlug, pageNo]);

	//update the tags on load
	useEffect(() => {
		posts.forEach((post) => {
			store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
		});
	}, [posts]);

	//for observer
	React.useEffect(() => {
		var options = {
			root: null,
			rootMargin: "20px",
			threshold: 1.0
		};
		const observer = new IntersectionObserver(handleObserver, options);
		if (loader.current) {
			observer.observe(loader.current);
		}
	}, []);
	const handleObserver = (entities) => {
		const target = entities[0];
		if (target.isIntersecting) {
			// setPageNo((pageNo) => pageNo + 1);
			setPageNo((pageNumber) => pageNumber + 1);
		}
	};

	const _openPostModal = (id) => {
		store.dispatch({ type: SET_POST_CARD_DATA_MODAL, payload: { postCardModal: true, activePostCardModal: id } });
	};

	const _sortByChange = (e) => {
		setSortBy({ name: e, code: e });
		if (e === "Random") {
			const FilteredPost = [...posts].sort((a, b) => {
				return Math.random() - 0.5;
			});
			setPosts(FilteredPost);
		}
		// setPageNo(0);
		// setIsFinished(false);
		localStorage.setItem("tagSortBy", JSON.stringify({ name: e, code: e }));
	};

	const PostsList = useMemo(() => {
		return [...posts]
			.filter((post) => {
				return props.activeTag ? post.tags.find((myTag) => myTag.term_id === props.activeTag.term_id) : true;
			})
			.sort((a, b) => {
				switch (sortBy.code) {
					case "Popularity":
						return b.up_vote_count - a.up_vote_count;
					case "Random":
						return;
					// Math.random() - 0.5;
					case "Newest":
						return new Date(b.post_date) - new Date(a.post_date);
					case "Oldest":
						return new Date(a.post_date) - new Date(b.post_date);
					default:
						return new Date(b.post_date) - new Date(a.post_date);
				}
			})
			.map((post, index) => {
				return (
					<PostCard
						postsArr={[...posts]}
						onOpen={() => _openPostModal(post.ID)}
						key={index}
						upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === post.ID)}
						postData={post}
						postId={index}
					/>
				);
			});
	}, [posts, props.activeTag, props.match.params.tagSlug, sortBy]);

	return (
		<div key={props.key} className="p-grid p-fluid dashboard page-most-recents">
			{!isEmpty(seoData) && <DynamicMetaDecorator metaDataPerRoute={seoData} />}
			<div className="p-col-12 p-lg-12" style={{ paddingTop: "0px" }}>
				<div className="p-col-12 underlined-border" style={{ paddingTop: "20px" }}>
					<i className={`glyph-icon flaticon-tag active`}></i>
					<b style={{ fontSize: "1.5em" }}>{tag.name}</b>
				</div>
				<div className="p-col-12 post-filter d-flex-end">
					<span>
						<CustomDropdown handleOnClick={_sortByChange} sortByValue={sortBy.code ? sortBy.code : "Sort By"} dropdownName="Sort By" dropdownOptions={sortByOptions} />
						{/* <Dropdown optionLabel="name" placeholder="Sort By" onChange={_sortByChange} options={sortByOptions} value={sortBy} /> */}
					</span>
				</div>
			</div>

			{/* {posts.length > 0 ? ( */}
			{PostsList}
			{/* ) : ( */}
			{/* {isFinished ? (
				<div className="p-col-12 p-lg-12">
					<div style={{ textAlign: "center" }} className="p-text-center card summary">
						No Results
					</div>
				</div>
			) : (
				<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
					<Loader loadingMore={true} />
				</div>
			)} */}

			{!isFinished ? (
				<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
					<Loader loadingMore={true} />
				</div>
			) : null}
		</div>
	);
}

Tag.propsTypes = {
	activeTag: PropTypes.object,
	unCheckedLinks: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	activeTag: state.world.activeTag,
	unCheckedLinks: state.world.unCheckedLinks
});

export default connect(
	mapStateToProps,
	{}
)(Tag);
