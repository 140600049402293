import React, { useState } from "react";
import Error from "../../layout/TopBar/ErrorMessage";
import { Icon, Popup } from "semantic-ui-react";
import store from "../../redux/store";

const PasswordField = ({ closeFields, label, edit, close, passwordError, passwordValue, fieldChange, savePassword, visiblity }) => {
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const darkMode = store.getState().settings.darkMode;

	const seePassword = () => {
		setPasswordVisibility(!passwordVisibility);
		if (document.getElementById("password").type == "password") {
			document.getElementById("password").type = "text";
		} else {
			document.getElementById("password").type = "password";
		}
	};

	return (
		<div className="textfield">
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<label style={{ color: darkMode ? "gray" : "black" }} for="password" className="title">
					{label}
				</label>
				{passwordError ? <Error message="Password must contain min 11 characters, at least 1 letter, 1 number and 1 special character" /> : null}
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<div className="passwordField-container">
					<input
						type="password"
						placeholder={visiblity ? "Enter Password" : "************"}
						id="password"
						value={passwordValue}
						className={passwordError ? "error" : null}
						onChange={() => fieldChange("password")}
						disabled
					/>

					{visiblity && (
						<div className="eye-icon">
							{passwordVisibility ? (
								<Icon style={{ color: darkMode ? "gray" : "black" }} name="eye slash" onClick={seePassword} />
							) : (
								<Icon style={{ color: darkMode ? "gray" : "black" }} name="eye" onClick={seePassword} />
							)}
						</div>
					)}
				</div>

				<div
					style={{
						width: 53,
						paddingTop: 5,
						textAlign: "center"
					}}
				>
					{savePassword ? (
						<Popup
							trigger={
								<span>
									<Icon name="close" onClick={() => closeFields("password")} />
								</span>
							}
							content="cancel"
							inverted
						/>
					) : (
						<Popup
							trigger={
								<span>
									<Icon name="edit" onClick={() => edit("password")} />
								</span>
							}
							content="edit password"
							inverted
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PasswordField;
