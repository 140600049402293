import React, { useState, useEffect } from "react";
import { MetaDecorator } from "../../components/MetaDecorator/MetaDecorator";
import metaDecoratorData from "../../metaDecoratorData.json";
import { FaRegPlayCircle } from "react-icons/fa";
import { getHowTo } from "../../api/api";
import parse from "html-react-parser";

function Howto() {
  const [count, setCount] = useState(0);
  const [howTo, setHowTo] = useState("");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("howto-page");
    document.getElementsByTagName("body")[0].removeAttribute("id");

    return () => {
      document.getElementsByTagName("body")[0].classList.remove("howto-page");
    };
  }, []);

  useEffect(() => {
    let controller = new AbortController();
    setLoading(true);
    getHowTo()
      .then((resp) => {
        setLoading(false);
        if (resp.response.ok) {
          setHowTo(resp.json[0].content.rendered);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => controller.abort();
  }, []);

  return (
    <div className="p-grid p-fluid dashboard page-about">
      <MetaDecorator metaDataPerRoute={metaDecoratorData.howto} />
      <div className="p-col-12 p-lg-12">
        <div className="card card-w-title how-to-media">
          {howTo ? parse(howTo) : !loading && "No any data available!!"}
          {/* If you're seeing this, PikiLinks has recently launched and currently undergoing a live beta test. Stay tuned for more info to fill in this section in the near future. */}
        </div>
      </div>
    </div>
  );
}

export default Howto;
