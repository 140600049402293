import React from "react";
import { Helmet } from "react-helmet";
import metaDecoratorData from "../../metaDecoratorData.json";
import { isEmpty } from "../../validation/validation";

export const DynamicMetaDecorator = (props) => {
	let homeMetaData = metaDecoratorData.home;
	let { metaDataPerRoute } = props;
	let originalUrl = window.location.origin;
	let pikiLinksImageUrl = originalUrl + "/icon192.png";
	let robotsAry = [];

	for (let [key, value] of Object.entries(metaDataPerRoute.robots)) {
		robotsAry.push(value);
	}

	return (
		<div>
			<Helmet>
				<title>{metaDataPerRoute.meta_title ? metaDataPerRoute.meta_title : homeMetaData.meta_title}</title>
				{/* <link rel="canonical" href={metaDataPerRoute.meta_title} /> */}
				<meta name="description" content={metaDataPerRoute.meta_description ? metaDataPerRoute.meta_description : homeMetaData.meta_description} />
				<meta name="robots" content={!isEmpty(robotsAry) ? robotsAry.join() : homeMetaData.meta_robots} />
				{/* <link rel="canonical" href={metaDataPerRoute.canonical} /> */}

				<meta property="og:title" content={metaDataPerRoute.facebook.og_title ? metaDataPerRoute.facebook.og_title : homeMetaData.meta_title} />
				<meta property="og:description" content={metaDataPerRoute.facebook.og_description ? metaDataPerRoute.facebook.og_description : homeMetaData.meta_description} />
				<meta property="og:url" content={metaDataPerRoute.facebook.og_url ? metaDataPerRoute.facebook.og_url : originalUrl} />
				<meta property="og:image" content={metaDataPerRoute.facebook.og_image ? metaDataPerRoute.facebook.og_image : pikiLinksImageUrl} />

				<meta name="twitter:title" content={metaDataPerRoute.twitter.twitter_title ? metaDataPerRoute.twitter.twitter_title : homeMetaData.meta_title} />
				<meta name="twitter:description" content={metaDataPerRoute.twitter.twitter_description ? metaDataPerRoute.twitter.twitter_description : homeMetaData.meta_description} />
				<meta name="twitter:image" content={metaDataPerRoute.twitter.twitter_image ? metaDataPerRoute.twitter.twitter_image : pikiLinksImageUrl} />
			</Helmet>
		</div>
	);
};
