import React from "react";
import { Modal } from "semantic-ui-react";
import { useSelector } from "react-redux";

function UninstallPwaModal(props) {
	const darkMode = useSelector((state) => state.settings.darkMode);

	return (
		<Modal
			onClose={() => {
				props.onClose();
			}}
			onOpen={props.onClose}
			open={props.open}
			style={{ backgroundColor: "gray" }}
			className={darkMode ? "pwa-uni-modal-dark" : "pwa-uni-modal-light"}
		>
			<p>
				To uninstall from computer copy and paste{" "}
				<span
					title="Copy"
					onClick={() => {
						navigator.clipboard.writeText("chrome://apps");
					}}
					style={{ fontWeight: "bold", fontStyle: "italic", padding: "5px", backgroundColor: "rgba(0,0,0,0.3)", cursor: "pointer", marginLeft: "5px", marginRight: "5px" }}
				>
					chrome://apps
				</span>{" "}
				into browser window then right click on Pikilinks logo snd select Remove from Chrome
			</p>
		</Modal>
	);
}

export default UninstallPwaModal;
