import React, { useEffect } from 'react'
import offline from '../../assets/img/offline.png'

function OfflinePage(props) {

    useEffect(() => {
        if (window.navigator.onLine) {
            props.history.push('/')
        }
    }, [])

    return (
        < div style={{ display: 'block' }} className='loading-more-wrapper page-loading-page'>
            <div className="loader-wrapper" style={{
                width: '100%',
                height: '100%',
                paddingTop: '71px'
            }}>
                <div>
                    <img src={offline} alt="Offline..." />
                    <div style={{ margin: 'auto', textAlign: 'center' }}><b>No Connection</b></div>
                </div>
            </div>
        </div >

    )
}

export default OfflinePage;
