import React, { useEffect, useRef, useState } from "react";
import { getIcons } from "../../api/api";
import { isEmpty } from "../../validation/validation";
import loadingGif from "../../assets/img/main_loading.gif";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { UPDATE_TAGS } from "../../redux/types";
import store from "../../redux/store";
import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from "react-responsive";

const Tags = (props) => {
	const [iconArry, setIconArry] = useState([]);
	const [loading, setLoading] = useState(true);
	let mostRecentOne = useSelector((state) => state.mostRecents.postsArr[0]);
	let mostPopularOne = useSelector((state) => state.mostPopulars.postsArr[0]);
	let homePostsArr = useSelector((state) => state.world.homePosts);

	const loader = useRef(null);
	const [isFetching, setIsFetching] = useState(false);
	const [isFinished, setIsFinished] = useState(false);
	const [nextPage, setNextPage] = useState(1);
	const darkmode = useSelector((state) => state.settings.darkMode);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	useEffect(()=>{
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({'piki_page':'tags'})
	  },[props.history])

	//api call, with pagination
	const getIconData = () => {
		setIsFetching(true);
		getIcons(`?per_page=100&paged=${nextPage}`)
			.then((res) => {
				const lengthNow = iconArry.length + res.json.length;
				if (lengthNow >= res.response.headers.get("x-wp-total")) {
					setIsFinished(true);
				}

				if (res.response.status === 200) {
					setLoading(false);
				}

				if (!isEmpty(res.json)) {
					let alphabeticallySortedTags = res.json;

					alphabeticallySortedTags.sort(function(a, b) {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});

					setIconArry([...iconArry, ...alphabeticallySortedTags]);
					store.dispatch({ type: UPDATE_TAGS, payload: [...iconArry, ...alphabeticallySortedTags] });
					store.dispatch({ type: "SET_TAGS_BY_ICON_POSTS_ORDER", payload: [...iconArry, ...alphabeticallySortedTags] });
					setIsFetching(false);
					setNextPage(nextPage + 1);
				}
			})
			.catch((err) => {
				console.log(err);
				setIsFinished(true);
			});
	};

	useEffect(() => {
		getIconData();
	}, []);

	//add tags of most recent one and trending one
	useEffect(() => {
		if (mostPopularOne && mostRecentOne && homePostsArr) {
			store.dispatch({ type: UPDATE_TAGS, payload: mostPopularOne.tags });
			store.dispatch({ type: "SET_TAGS_BY_ICON_POSTS_ORDER", payload: mostPopularOne.tags });
			store.dispatch({ type: UPDATE_TAGS, payload: mostRecentOne.tags });
			store.dispatch({ type: "SET_TAGS_BY_ICON_POSTS_ORDER", payload: mostRecentOne.tags });
			homePostsArr.forEach((categoryData) => {
				categoryData.posts.forEach((post) => {
					store.dispatch({ type: UPDATE_TAGS, payload: post.tags });
					store.dispatch({ type: "SET_TAGS_BY_ICON_POSTS_ORDER", payload: post.tags });
				});
			});
		}
	}, [mostPopularOne, mostRecentOne, homePostsArr]);

	//For observer
	const handleObserver = (entries) => {
		const target = entries[0];
		if (target.isIntersecting) {
			if (!isFetching) {
			}
			!isFetching && !isFinished && getIconData();
		}
	};

	useEffect(() => {
		//use IntersectionObserver only after receiving data from API to avoid error in "unobserve"
		if (!loading) {
			const options = {
				root: null,
				rootMargin: "20px",
				threshold: 1.0
			};
			// Create observer
			const observer = new IntersectionObserver(handleObserver, options);
			// observe the loader
			if (loader && loader.current) {
				observer.observe(loader.current);
			}
			// clean up on willUnMount
			return () => observer.unobserve(loader.current);
		}
	}, [loader, handleObserver]);

	return (
		<div>
			<div className={darkmode ? "iconLibrary_title dark" : "iconLibrary_title"}>Icon Library</div>
			<div className={darkmode ? "iconLibrary dark-background" : "iconLibrary"}>
				<ul className={isTabletOrMobile ? "iconLibrary_list spaceAround" : "iconLibrary_list"}>
					{loading ? (
						<div className="iconLibrary_load-img">
							<img src={loadingGif} alt="" className={isTabletOrMobile ? "loading-icon-mobile" : ""} />
						</div>
					) : (
						<>
							{!loading &&
								iconArry &&
								iconArry
									.filter((icon) => icon.url)
									.map((icon, i) => (
										<li
											className={isTabletOrMobile ? "iconLibrary_item mobile-item" : "iconLibrary_item"}
											onClick={() => {
												props.history.push(`tags/${icon.slug}`);
											}}
											key={i}
										>
											<img src={icon.url} alt="" className="iconLibrary_img" />

											<div className={isTabletOrMobile ? "iconLibrary_name mobile-name" : "iconLibrary_name"}>{icon.name}</div>
										</li>
									))}

							<div ref={loader} style={{ display: "block" }} className="loading-more-wrapper">
								{isFetching && !isFinished && <Loader loadingMore={true} />}
								{isFinished && iconArry.length < 1 && (
									<div className="text-centered">
										<b>No Tags found</b>
									</div>
								)}
							</div>
						</>
					)}
				</ul>
			</div>
		</div>
	);
};

export default withRouter(Tags);
