import React from "react";
import offline from "../../assets/img/offline.png";
import loadingImage from "../../assets/img/main_loading.gif";

function Loader(props) {
	let paddingT = "30%";
	if (props.loadingMore) {
		paddingT = "20px";
	} else if (props.smallLoader) {
		paddingT = "5px";
	}
	return (
		<div
			className="loader-wrapper"
			style={{
				width: "100%",
				height: "100%",
				paddingTop: paddingT
			}}
		>
			{window.navigator.onLine ? (
				<>
					{props.loadingModal ? <h3 style={{ textAlign: "center" }}>{props.loadingModal}</h3> : null}
					<img src={loadingImage} alt="Loading..." />
				</>
			) : (
				<div>
					<img src={offline} alt="Offline..." />
					<div style={{ margin: "auto", textAlign: "center" }}>
						<b>No Connection</b>
					</div>
				</div>
			)}
		</div>
	);
}

export default Loader;
