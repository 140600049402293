export const listSortOptions = [
  { name: "Popularity", code: "popularity" },
  { name: "Random", code: "random" },
  { name: "Newest", code: "newest" },
  { name: "Oldest", code: "oldest" },
];

export const categorySortOptions = [
  { name: "Random", code: "random" },
  { name: "Newest", code: "newest" },
  { name: "Oldest", code: "oldest" },
];
