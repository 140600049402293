import React from "react";
import { Dropdown } from "semantic-ui-react";
import {
  FaSortAlphaDown,
  FaSortAlphaUpAlt,
  FaSortAmountDownAlt,
} from "react-icons/fa";
import { FcAlphabeticalSortingZa } from "react-icons/fc";
import { AiOutlineSortAscending } from "react-icons/ai";
import { useSelector } from "react-redux";
import { TiFilter } from "react-icons/ti";
import { GoSearch } from "react-icons/go";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

function SubmissionTableHeader(props) {
  let isMobile = window.innerWidth < 600;
  const darkMode = useSelector((state) => state.settings.darkMode);
  const { sortBy, setSortBy, filter, setFilter, search, setSearch, tableType } =
    props;
  return (
    <div
      className={`submission-table-container${
        props.isMobile ? "-mobile" : ""
      }__header`}
    >
      <div className="submission-title">
        {/* <h3>Submissions : </h3> */}
        <CustomDropdown
          handleOnClick={props.onDropdownChange}
          sortByValue={props.sortByValue}
          dropdownName={props.dropdownName}
          dropdownOptions={props.dropdownOptions}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className={`submission-table-container${
            props.isMobile ? "-mobile" : ""
          }__header__options`}
        >
          <div
            className={`submission-table-container${
              props.isMobile ? "-mobile" : ""
            }__header__options__item`}
            style={{
              backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
            }}
          >
            <Dropdown
              icon={
                <FaSortAmountDownAlt
                  style={{
                    fontSize: "14px",
                    color: props.darkMode ? "#808080" : "#8A8A8A",
                    marginLeft: isMobile ? "0px" : "0px",
                  }}
                />
              }
              text={null}
              direction={isMobile ? "right" : "left"}
              floating
              labeled
              button
              className="dropdown"
            >
              <Dropdown.Menu
                className="sub-menu"
                style={{
                  width: "150px",
                  marginRight: isMobile ? "0px" : "-10px",
                  padding: "0px",
                }}
              >
                <Dropdown.Item
                  className="sub-item"
                  onClick={() => {
                    setSortBy("newest");
                  }}
                  text={
                    <span
                      style={{
                        color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                        padding: "2px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0px",
                        borderColor: "rgba(138, 138, 138, 0.5)",
                        paddingBottom: "7px",
                      }}
                    >
                      Newest
                    </span>
                  }
                />
                <Dropdown.Item
                  className="sub-item"
                  onClick={() => {
                    setSortBy("oldest");
                  }}
                  text={
                    <span
                      style={{
                        width: "100%",
                        color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      Oldest
                    </span>
                  }
                />
                <Dropdown.Item
                  className="sub-item"
                  onClick={() => {
                    setSortBy("asc");
                  }}
                  text={
                    <span
                      style={{
                        color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                        padding: "2px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      A-Z Asc
                    </span>
                  }
                />
                <Dropdown.Item
                  className="sub-item"
                  onClick={() => {
                    setSortBy("desc");
                  }}
                  text={
                    <span
                      style={{
                        color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                        padding: "2px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "0px",
                        borderColor: "rgba(138, 138, 138, 0.5)",
                        paddingBottom: "7px",
                      }}
                    >
                      Z-A Des
                    </span>
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            className={`submission-table-container${
              props.isMobile ? "-mobile" : ""
            }__header__options__item`}
            style={{
              backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5",
            }}
          >
            <Dropdown
              icon={
                <TiFilter
                  style={{
                    fontSize: "20px",
                    color: props.darkMode ? "#808080" : "#8A8A8A",
                    marginLeft: isMobile ? "0px" : "0px",
                  }}
                />
              }
              text={null}
              direction={isMobile ? "right" : "left"}
              floating
              labeled
              button
              className="dropdown"
            >
              <Dropdown.Menu
                className="sub-menu"
                style={{ width: "150px", marginRight: "-10px", padding: "0px" }}
              >
                {tableType === "submission" ? (
                  <>
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("all");
                      }}
                      text={
                        <span
                          style={{
                            width: "100%",
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          All
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("submitted");
                      }}
                      text={
                        <span
                          style={{
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          Submitted
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("published");
                      }}
                      text={
                        <span
                          style={{
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "0px",
                            borderColor: "rgba(138, 138, 138, 0.5)",
                            paddingBottom: "7px",
                          }}
                        >
                          Published
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("rejected");
                      }}
                      text={
                        <span
                          style={{
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "0px",
                            borderColor: "rgba(138, 138, 138, 0.5)",
                            paddingBottom: "7px",
                          }}
                        >
                          Rejected
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("deleted");
                      }}
                      text={
                        <span
                          style={{
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "0px",
                            borderColor: "rgba(138, 138, 138, 0.5)",
                            paddingBottom: "7px",
                          }}
                        >
                          Deleted
                        </span>
                      }
                    />
                  </>
                ) : (
                  <>
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("all");
                      }}
                      text={
                        <span
                          style={{
                            width: "100%",
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          All
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("upvote");
                      }}
                      text={
                        <span
                          style={{
                            width: "100%",
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          Upvotes
                        </span>
                      }
                    />
                    <Dropdown.Item
                      className="sub-item"
                      onClick={() => {
                        setFilter("super_vote");
                      }}
                      text={
                        <span
                          style={{
                            width: "100%",
                            color: darkMode ? "rgb(150, 149, 149)" : "#3C3C3C",
                            padding: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          Super Votes
                        </span>
                      }
                    />
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div>
          <div
            className={`submission-table-container${
              props.isMobile ? "-mobile" : ""
            }__header__search`}
          >
            <input
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              className="input"
              type="text"
              placeholder="search"
            />
            <GoSearch />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmissionTableHeader;
