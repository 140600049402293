import React from "react";
import { Dropdown } from "primereact/dropdown";

function SortBy(props) {
  const options = [
    { name: "Popularity", code: "2" },
    { name: "Newest", code: "1" },
    { name: "Oldest", code: "0" },
  ];

  //   const _sortByChange = (e) => {
  //     console.log(e.target.value.code);
  //   };

  return (
    <Dropdown
      optionLabel="name"
      placeholder="Sort By"
      onChange={(e) => props._sortByChange(e.target.value.code)}
      options={options}
      value={props.byNewest ? options[0] : options[1]}
    />
  );
}

export default SortBy;
