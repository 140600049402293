import { unauthWrapper, wrapper } from "./wrapper";

// to get the list of all categories only
export const getCategoriesList = async (queryString) => {
  try {
    return await wrapper("wp-json/ng/v1/categories-list?" + queryString, "GET");
  } catch (e) {
    throw e;
  }
};

// apiEndpoint = "https://dev.app.pikilinks.com/wp-json/ng/v1/categories-list?paged=1,headers: {"Content-Type": "application/json"}"
// getMostPopular = "https://dev.app.pikilinks.com/wp-json/ng/v1/popular-posts"
// url, method, signal, body, header
//to get the recent post of dashboard page
export const getHomePosts = async (queryString, signal) => {
  try {
    return await wrapper(
      `wp-json/ng/v1/categories?${queryString}`,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const getUpvotes = async (signal) => {
  try {
    return await wrapper(`wp-json/ng/v1/voted-list`, "GET", signal);
  } catch (e) {
    alert(e);
    throw e;
  }
};

//to get the most recent ten posts
export const getMostRecents = async (query, signal) => {
  try {
    return await wrapper(
      `wp-json/ng/v1/recent-posts${query && query}`,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//to get the most popular ten posts
export const getMostPopulars = async (query, signal) => {
  try {
    return await wrapper(
      `wp-json/ng/v1/popular-posts${query && query}`,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//to get the search results of posts
export const searchPosts = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/posts-search?" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//to get posts by category id
export const getPostsByCategory = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/category-post?" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//get similar posts
export const getSimilarPosts = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/related-posts?" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//get posts by id
export const getPostsByIds = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/posts-by-ids?" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//get random post
export const getRandomPost = async (signal) => {
  try {
    return await wrapper("wp-json/ng/v1/random-posts", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getCatRandomPost = async (query) => {
  try {
    return await unauthWrapper(
      `wp-json/ng/v1/random-posts?${query}`,
      "GET",
      null
    );
  } catch (e) {
    throw e;
  }
};

export const getSubscribedCat = async (signal) => {
  try {
    return await wrapper(
      `wp-json/ng/v1/subscribed-category-list`,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const changeCatSubscribeStatus = async (query, signal) => {
  try {
    return await wrapper(
      `wp-json/ng/v1/subscribe-category?` + query,
      "POST",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//get single post by ID
export const getSinglePost = async (queryString, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/post/" + queryString, "GET", signal);
  } catch (e) {
    throw e;
  }
};

//get posts by tag
export const getPostsByTag = async (queryString, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/tags?" + queryString, "GET", signal);
  } catch (e) {
    throw e;
  }
};

//upvote the post
export const upvotePost = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/vote" + queryString,
      "POST",
      signal,
      {},
      "Bearer " + localStorage.getItem("userToken")
    );
  } catch (e) {
    throw e;
  }
};

//getLists
export const getLists = async (queryString, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/series?" + queryString, "GET", signal);
  } catch (e) {
    throw e;
  }
};

//getSingleList
export const getSingleList = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/series-post?" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

//add new post
export const addPost = async (body, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/submit-post", "POST", signal, body);
  } catch (e) {
    throw e;
  }
};

//send feedback
export const sendFeedback = async (body, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/submit-feedback", "POST", signal, body);
  } catch (e) {
    throw e;
  }
};

//subscribe
export const subscribe = async (body, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/mail/subscribe", "POST", signal, body);
  } catch (e) {
    throw e;
  }
};

//getIcons
export const getIcons = async (queryString, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/tags-list" + queryString,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const getCategoryOrderRefresh = async (signal, query) => {
  try {
    return await wrapper("wp-json/ng/v1/category-order" + query, "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getCategoryOrder = async (signal) => {
  try {
    return await wrapper("wp-json/ng/v1/category-order", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const setCategoryOrder = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/update-category-order" + query,
      "POST",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const clearCategoryOrder = async (body, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/clear-category-order",
      "POST",
      signal,
      body
    );
  } catch (e) {
    throw e;
  }
};

// POSt: https://dev.app.pikilinks.com/wp-json/ng/v1/confirm-email

export const sendVerificationCode = async (code, email, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/confirm-email" + `?code=${code}&email=${email}`,
      "POST",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const resendCode = async (email, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/resend-verification-email" + `?email=${email}`,
      "POST",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const getFavIcons = async (signal, link) => {
  try {
    return await wrapper(
      "http://favicongrabber.com/api/grab/" + `${link}`,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const deleteAcc = async (signal) => {
  try {
    return await wrapper("wp-json/ng/v1/delete-account", "POST", signal);
  } catch (e) {
    throw e;
  }
};

export const checkUrlValidation = async (query) => {
  try {
    return await wrapper("wp-json/ng/v1/submit-post" + query, "POST");
  } catch (e) {
    throw e;
  }
};

export const changeUsername = async (data, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/change-username", "POST", signal, data);
  } catch (e) {
    throw e;
  }
};

export const askQuestion = async (data, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/submit-question", "POST", signal, data);
  } catch (e) {
    throw e;
  }
};

export const getLinkTypeData = async (data, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/link-types", "GET", signal, data);
  } catch (e) {
    throw e;
  }
};

export const getAutoCompleteTags = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/tag-autocomplete" + query,
      "GET",
      signal
    );
  } catch (e) {
    throw e;
  }
};

export const getPrivacyPolicy = async (signal) => {
  try {
    return await wrapper("wp-json/ng/v1/privacy-policy", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getTermsAndConditions = async (signal) => {
  try {
    return await wrapper("wp-json/ng/v1/terms-and-conditions", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getGenricPage = async (query) => {
  try {
    return await wrapper("wp-json/wp/v2/pages?slug=" + query, "GET", null);
  } catch (e) {
    throw e;
  }
};

export const getAboutUs = async (data, signal) => {
  try {
    return await wrapper("wp-json/wp/v2/pages?slug=about", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getHowTo = async (data, signal) => {
  try {
    return await wrapper("wp-json/wp/v2/pages?slug=how-to", "GET", signal);
  } catch (e) {
    throw e;
  }
};

export const getBanners = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/banners" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getFaqsData = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/faqs" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getLinksData = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/menus" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getPointsData = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/submission-status" + query,
      "GET",
      signal
    );
  } catch (err) {
    throw err;
  }
};

export const getSubmissionTableData = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/submissions" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const verifyPublishedLinks = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/get-published-link-count" + query,
      "GET",
      signal
    );
  } catch (err) {
    throw err;
  }
};

export const getSubmittedPost = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/get-submitted-post" + query,
      "GET",
      signal
    );
  } catch (err) {
    throw err;
  }
};

export const updateSubmittedPost = async (data, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/update-submitted-post",
      "POST",
      signal,
      data
    );
  } catch (err) {
    throw err;
  }
};

export const getDefaultPoints = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/point-status" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getSubPoints = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/submission-status" + query,
      "GET",
      signal
    );
  } catch (err) {
    throw err;
  }
};

export const doUnsubscribe = async (data, query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/mail/unsubscribe" + query,
      "POST",
      signal,
      data
    );
  } catch (err) {
    throw err;
  }
};

export const getPlartforms = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/platforms" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getIconLibraryContent = async (query, signal) => {
  try {
    return await wrapper(
      "wp-json/ng/v1/icons-library-content" + query,
      "GET",
      signal
    );
  } catch (err) {
    throw err;
  }
};

export const getSocialIcons = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/social-medias" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

// https://dev.app.pikilinks.com/wp-json/ng/v1/cookie-notice
export const getCookie = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/cookie-notice" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};

export const getLogo = async (query, signal) => {
  try {
    return await wrapper("wp-json/ng/v1/logos" + query, "GET", signal);
  } catch (err) {
    throw err;
  }
};
