import {
  SET_UPVOTES,
  SET_SEARCH_RESULTS_PAGE,
  SET_ACTIVE_TAG,
  SET_MENU_ITEMS,
  SET_MOST_RECENT_ONE,
  SET_HOME_POSTS,
  SET_SEARCH_RESULTS,
  SET_SEARCH_VALUE,
  SEARCH_INPUT_SHOWING,
  SET_TAGS_BY_POST_ORDER,
  SET_TAGS_BY_FAV_POSTS_ORDER,
  SET_TAGS_BY_CATEGORY_POSTS_ORDER,
  SET_TAGS_BY_ICON_POSTS_ORDER,
  SET_NEW_WINDOW,
  SET_RANDOM_LINK_CATEGORIES,
  SET_POST_CARD_DATA_MODAL,
  SET_POST_MODAL_DATA,
  SET_AUTH_MODAL_CHANGE,
  SET_FEEDBACK_MODAL,
  SET_LINK_TYPES,
  SET_UNCHECKED_LINKS,
  SET_HOME_POSTS_ARRAY,
  SET_IS_DROP_ON,
  SET_MODAL_REF,
  SET_ICONS_LIBRARY_MODAL,
  SET_COOKIE,
  SET_LOGO,
} from "../types";

const initialState = {
  searchResults: [],
  searchResultsPage: 1,
  menuItems: [],
  homePosts: [],
  upvotedPosts: [],
  searchKey: "",
  mostRecentOne: null,
  activeTag: "",
  searchInputShowing: false,
  tagsByPostsOrder: [],
  tagsByFavPosts: [],
  tagsByCatPosts: [],
  tagsByIconPosts: [],
  isNewWindowOpen: {},
  postCardModal: false,
  activePostCardModal: null,
  authModalChange: false,
  feedbackModal: false,
  postModalData: {},
  linkTypes: [],
  unCheckedLinks: [],
  homePostsArray: [],
  isDropOn: false,
  modalRef: null,
  iconsLibraryModal: false,
  cookieContent: "",
  logo: { logo: "", splash: "", favicon: "" },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGO:
      if (action.payload && action.payload.splash)
        localStorage.setItem("splash_logo", action.payload.splash);
      return { ...state, logo: action.payload };
    case SET_COOKIE:
      return { ...state, cookieContent: action.payload };
    case SET_ICONS_LIBRARY_MODAL:
      return { ...state, iconsLibraryModal: action.payload };
    case SET_MODAL_REF:
      return { ...state, modalRef: action.payload };
    case SET_IS_DROP_ON:
      return { ...state, isDropOn: false };
    case SET_HOME_POSTS_ARRAY:
      return { ...state, homePostsArray: action.payload };
    case SET_UNCHECKED_LINKS:
      localStorage.setItem("unCheckedFilters", JSON.stringify(action.payload));
      return { ...state, unCheckedLinks: action.payload };
    case SET_LINK_TYPES:
      return { ...state, linkTypes: action.payload };
    case SET_FEEDBACK_MODAL:
      return { ...state, feedbackModal: action.payload };
    case SET_AUTH_MODAL_CHANGE:
      return { ...state, authModalChange: action.payload };
    case SET_POST_MODAL_DATA:
      return { ...state, postModalData: action.payload };
    case SET_POST_CARD_DATA_MODAL:
      return {
        ...state,
        postCardModal: action.payload.postCardModal,
        activePostCardModal: action.payload.activePostCardModal,
      };
    case SET_TAGS_BY_POST_ORDER:
      var toBeAddeds = [];
      for (let newTag of action.payload || []) {
        if (
          !state.tagsByPostsOrder.find((tag) => tag.term_id === newTag.term_id)
        ) {
          toBeAddeds.push(newTag);
        }
      }
      return {
        ...state,
        tagsByPostsOrder: [...state.tagsByPostsOrder, ...toBeAddeds],
      };
    case SET_TAGS_BY_FAV_POSTS_ORDER:
      var toBeAddeds = [];
      for (let newTag of action.payload || []) {
        if (
          !state.tagsByFavPosts.find((tag) => tag.term_id === newTag.term_id)
        ) {
          toBeAddeds.push(newTag);
        }
      }
      return {
        ...state,
        tagsByFavPosts: [...state.tagsByFavPosts, ...toBeAddeds],
      };
    case SET_TAGS_BY_CATEGORY_POSTS_ORDER:
      return { ...state, tagsByCatPosts: [...action.payload] };
    case SET_TAGS_BY_ICON_POSTS_ORDER:
      var toBeAddeds = [];
      for (let newTag of action.payload || []) {
        if (
          !state.tagsByIconPosts.find((tag) => tag.term_id === newTag.term_id)
        ) {
          toBeAddeds.push(newTag);
        }
      }
      return {
        ...state,
        tagsByIconPosts: [...state.tagsByIconPosts, ...toBeAddeds],
      };
    case SET_MENU_ITEMS:
      return {
        ...state,
      };
    case SET_HOME_POSTS:
      return {
        ...state,
        homePosts: action.payload,
      };
    case SET_MOST_RECENT_ONE:
      return {
        ...state,
        mostRecentOne: action.payload,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
      };

    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchKey: action.payload,
      };

    case SET_ACTIVE_TAG:
      if (state.activeTag === action.payload) {
        return {
          ...state,
          activeTag: "",
        };
      }
      return {
        ...state,
        activeTag: action.payload,
      };

    case SEARCH_INPUT_SHOWING:
      return {
        ...state,
        searchInputShowing: action.payload,
      };

    case SET_SEARCH_RESULTS_PAGE:
      return {
        ...state,
        searchResultsPage: action.payload,
      };
    case SET_UPVOTES:
      return {
        ...state,
        upvotedPosts: action.payload,
      };
    case SET_NEW_WINDOW:
      return { ...state, isNewWindowOpen: action.payload };
    default:
      return state;
  }
};
