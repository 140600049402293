import React, { useEffect } from "react";

//from packages
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon, Label } from "semantic-ui-react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Dropdown } from "semantic-ui-react";
//utils
import store from "../../redux/store";
import { isEmpty } from "../../validation/validation";
import { MdTune } from "react-icons/md";
//types
import { SET_ACTIVE_TAG } from "../../redux/types";
import { AiOutlineSortAscending } from "react-icons/ai";
import { FcAlphabeticalSortingZa } from "react-icons/fc";
import { FaSortAlphaDown, FaSortAlphaUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import { SET_POST_CARD_DATA_MODAL } from "../../redux/types";

function TagsBar(props) {
	const [activeSort, setActiveSort] = React.useState("postOrder");

	//reset tags on changing of path name
	useEffect(() => {
		store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
	}, [props.location.pathname]);

	const _clickAllTags = () => {
		store.dispatch({ type: SET_ACTIVE_TAG, payload: "" });
	};

	const _clickTag = (tag) => {
		let newPath = props.history.location.pathname.split("/");
		let fin = "/" + newPath[1];
	};
	if (!props.isIconModal) {
		return null;
	}

	const updatedTags = [...props.tags];
	if (!isEmpty(props.activeTag)) {
		updatedTags.unshift({
			term_id: "allTags",
			name: "All Tags"
		});
	}

	const isMobile = window.innerWidth < 450;

	let circularTags = true;
	if (props.history.location.pathname === "/" || props.history.location.pathname === "/icon-library" || props.history.location.pathname === "/link") {
		circularTags = false;
	}
	const isTagsBarFixed = props.location.pathname === "";

	const darkMode = store.getState().settings.darkMode;

	return (
		<>
			<div
				className={`tags-bar ${props.postsModal ? "remove-padding fit-modal" : ""} ${props.darkMode ? "tags-dark-mode" : "tags-light-mode"} ${isTagsBarFixed ? "fixed" : ""} ${
					isTagsBarFixed && window.innerWidth > 1024 ? (props.iconMode ? "DesktopTagsBar TagsBarOnIconMode" : "DesktopTagsBar") : "MobileTagsBar"
				}`}
				id="tags-dropdown-parent"
				style={{ width: props.location.pathname === "/icon-library" ? "100%" : "100%" }}
			>
				{
					<ScrollMenu
						style={{ width: "100%", overflow: "hidden" }}
						arrowLeft={!isMobile ? <i className={`glyph-icon flaticon-left-arrow-1`}></i> : undefined}
						arrowRight={!isMobile ? <i className={`glyph-icon flaticon-right-arrow-1`}></i> : undefined}
						alignCenter={false}
						dragging={true}
						data={props.modalTags.map((tag, index) => {
							if (index === 0 && props.activeTag) {
								return (
									<Label key={index} className="custom-label" onClick={_clickAllTags}>
										<span className="tags-name">
											<Icon name="tag" />
											{tag.name}
										</span>
									</Label>
								);
							}

							return (
								<Label
									key={index}
									className="custom-label"
									onClick={() => {
										_clickTag(tag);
										if (props.postsModal) {
											store.dispatch({ type: SET_POST_CARD_DATA_MODAL, payload: { postCardModal: false, activePostCardModal: null } });
										}
									}}
									// circular={circularTags}
									color={props.activeTag === tag ? "grey" : null}
									style={{ backgroundColor: darkMode ? "hsla(0,0%,50.2%,.1)" : "hsla(0,0%,100%,.35)" }}
								>
									<span className={props.activeTag === tag ? "tag-span" : "tag-span"}>{tag.name}</span>
								</Label>
							);
						})}
						hideArrows={true}
						hideSingleArrow={true}
					/>
				}
			</div>
		</>
	);
}

TagsBar.propTypes = {
	tags: PropTypes.array.isRequired,
	activeTag: PropTypes.any.isRequired,
	tagsByPostsOrder: PropTypes.array.isRequired,
	tagsByFavPosts: PropTypes.array.isRequired,
	tagsByCatPosts: PropTypes.array.isRequired,
	tagsByIconPosts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
	tags: state.tags,
	activeTag: state.world.activeTag,
	tagsByPostsOrder: state.world.tagsByPostsOrder,
	tagsByFavPosts: state.world.tagsByFavPosts,
	tagsByCatPosts: state.world.tagsByCatPosts,
	tagsByIconPosts: state.world.tagsByIconPosts,
	postsModalStatus: state.world.postCardModal
});

export default withRouter(connect(mapStateToProps)(TagsBar));
