import React, { useState, useEffect } from "react";

//from packages
import { withRouter } from "react-router-dom";
import { Icon, Input, Label, Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";

//components
import Loader from "../../components/Loader/Loader";
import PostCard from "../../components/PostCard/PostCard";

//utils
import store from "../../redux/store";
import { popWindow } from "../../utils/manageShare";

//apis
import { getSinglePost, getLinkTypeData } from "../../api/api";

//types
import { RESET_TAGS, UPDATE_TAGS } from "../../redux/types";
import { isEmpty } from "../../validation/validation";
import { DynamicMetaDecorator } from "../../components/MetaDecorator/DynamicMetaDecorator";
import PostModal from "./PostModal";
import _ from "lodash";
// import captureWebsite from "capture-website";

function Post(props) {
	const [postData, setPostData] = useState({});
	const [loading, setLoading] = useState(true);
	const [isMobile, setIsMobile] = useState(true);
	const [shareBlockShowing, setShareBlockShowing] = useState(false);
	const [linkCopied, setLinkCopied] = useState(false);
	const [seoData, setSeoData] = useState({});
	const categories = useSelector((state) => state.categories.categories);
	const linkTypess = useSelector((state) => state.world.linkTypes);
	const darkMode = useSelector((state) => state.settings.darkMode);

	//onpost id change
	useEffect(() => {
		(async () => {
			store.dispatch({ type: RESET_TAGS, payload: "" });
			getSinglePost(new URLSearchParams(props.location.search).get("L"))
				.then((res) => {
					setLoading(false);
					setSeoData(res.json.seo);
					setPostData(res.json);
					document.getElementById("app-hue-bar").style.background = res.json.category_color;
				})
				.catch((err) => {
					console.log(err);
				});
		})();
	}, [new URLSearchParams(props.location.search).get("L")]);

	// const captureSS = async () => {
	// 	let screenCaptureImage = await captureWebsite.file("https://google.com", "screenshot.png");
	// 	console.log("captured image for website:", postData.user_submit_url, "-is-", screenCaptureImage);
	// };

	// useEffect(() => {
	// 	if (postData) {
	// 		captureSS();
	// 	}
	// }, [postData]);

	//did mount
	useEffect(() => {
		document.getElementsByTagName("body")[0].classList.add("single-post-page");
		if (postData.ID) document.getElementsByTagName("body")[0].id = `post-${postData.ID}`;

		if (window.innerWidth <= 992) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}

		window.addEventListener("resize", () => {
			if (window.innerWidth <= 992) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		});

		return () => {
			document.getElementsByTagName("body")[0].classList.remove("single-post-page");
			document.title = "PikiLinks";
			document.getElementById("app-hue-bar").style.background = "linear-gradient(#0040ff, #8000ff, #ff00bf, #ff0000, #ff8000, #40ff00, #00ffbf)";
		};
	}, []);

	//on getting postdata
	useEffect(() => {
		document.title = `PikiLinks | ${postData.post_title ? postData.post_title : ""}`;
	}, [postData]);

	//update the tags list in header
	useEffect(() => {
		if (postData.tags) {
			store.dispatch({ type: UPDATE_TAGS, payload: postData.tags });
		}
	}, [postData]);

	const _pop = (type) => {
		popWindow(type, postData.post_title, window.location.href);
	};

	// const _closePostModal = () => {
	// 	setPostModalData({
	// 		showModal: false,
	// 		id: null
	// 	});
	// };

	// const _openPostModal = (id) => {
	// 	setPostModalData({
	// 		showModal: true,
	// 		id: id
	// 	});
	// };

	let postCatTitle = "";
	let postCatSlug = "";
	if (postData && postData.categories && postData.categories[0]) {
		let postCategory = categories.filter((cat, ind) => cat.term_id === postData.categories[0]);
		if (!isEmpty(postCategory)) {
			postCatTitle = postCategory[0].name;
			postCatSlug = postCategory[0].slug;
		}
	}

	const _onShareClick = () => {
		if (navigator.share || navigator.canShare) {
			try {
				navigator
					.share({ title: "PikiLinks", url: `https://${window.location.host}/link?L=${postData.ID}` })
					.then(() => console.log("Hooray! Your content was shared to tha world"))
					.catch((err) => {
						// alert("navigator.share catch", err);
					});
			} catch (error) {
				console.log(`Oops! Link couldnt be shared: ${error}`);
			}
		} else {
			setShareBlockShowing(true);
		}
	};

	if (loading) return <Loader />;
	return (
		<div className="p-grid p-fluid dashboard page-post" id="post-page-23">
			{!isEmpty(seoData) && <DynamicMetaDecorator metaDataPerRoute={seoData} />}
			<div className="p-col-12 p-lg-12" style={{ display: "flex", justifyContent: "space-between" }}>
				<div className="p-col-12 p-md-12 underlined-border" style={{ display: "flex", flex: 1 }}>
					{!isEmpty(postData.categories) && (
						<b style={{ cursor: "pointer", flex: 0.95 }}>
							<span
								onClick={() => {
									props.history.push(`/category/${postCatSlug}`);
								}}
							>
								{postCatTitle || ""}
							</span>
						</b>
					)}
					<Popup
						content={
							<span className="hideShareOption list-hideShareOption" style={{ backgroundColor: darkMode ? "#3c3c3c" : "#EDF0F5", color: "#8A8A8A" }}>
								<span>
									<div>
										<span className="icon-con-social" onClick={() => _pop("linkedin")}>
											<i className="flaticon-linkedin"></i> &nbsp;
											<b>LinkedIn</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("reddit")}>
											<i className="flaticon-reddit-1"></i> &nbsp;
											<b>Reddit</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("twitter")}>
											<i className="flaticon-twitter"></i> &nbsp;
											<b>Twitter</b>
										</span>
									</div>
									<div>
										<span className="icon-con-social" onClick={() => _pop("facebook")}>
											<i className="flaticon-facebook"></i> &nbsp;
											<b>Facebook</b>
										</span>

										<span className="icon-con-social" onClick={() => _pop("messenger")}>
											<i className="flaticon-messenger"></i> &nbsp;
											<b>Messenger</b>
										</span>
										<span className="icon-con-social" onClick={() => _pop("gmail")}>
											<i className="flaticon-email-1"></i> &nbsp;
											<b>Email</b>
										</span>
									</div>
								</span>
								<span
									className="icon-con-social"
									onClick={() => {
										_pop("link");
										setLinkCopied(true);
									}}
								>
									<div className="copy-share-input">
										<Input
											size="mini"
											action={{
												labelPosition: "right",
												icon: "copy",
												content: linkCopied ? "Copied" : "Copy Link"
											}}
											defaultValue={`https://${window.location.host}/link?L=${postData.ID}`}
										/>
									</div>
								</span>
							</span>
						}
						on="click"
						className="tags-popup share-popup"
						onClose={() => {
							setShareBlockShowing(false);
							setLinkCopied(false);
						}}
						onOpen={() => {
							_onShareClick();
						}}
						open={shareBlockShowing}
						basic
						trigger={
							<Label
								style={{
									marginRight: "8px",
									cursor: "pointer",
									display: "flex",
									flex: 0.1,
									alignItems: "center",
									justifyContent: "center",
									color: "#8A8A8A",
									maxWidth: "100px",
									minWidth: "100px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<Icon name="share alternate" />
								Share
							</Label>
						}
					/>
				</div>
			</div>
			{/* {!isEmpty(postData.link_types) ? (
				<div className="postLinkTypes">
					{postData.link_types.map((data, ind) => {
						return <span>{data.name}</span>;
					})}
				</div>
			) : null} */}

			<PostCard forSingle={true} upvotedMe={(store.getState().world.upvotedPosts || []).find((postt) => postt.ID === postData.ID)} postData={postData} />
		</div>
	);
}

export default withRouter(Post);
