import { UPDATE_TAGS, RESET_TAGS, UPDATE_WHOLE_TAGS } from "../types";

const initialState = [];

export default (state = initialState, action) => {
	switch (action.type) {
		case RESET_TAGS:
			return [];
		case UPDATE_WHOLE_TAGS:
			return [...action.payload];
		case UPDATE_TAGS:
			// console.log("updatedTags [tags issue]", action.payload);
			//only add the non duplicate tags
			var toBeAddeds = [];
			for (let newTag of action.payload || []) {
				if (!state.find((tag) => tag.term_id === newTag.term_id)) {
					toBeAddeds.push(newTag);
				}
			}
			// console.log("updatedTags [tags issue]2", toBeAddeds);
			return [...state, ...toBeAddeds];
		default:
			return state;
	}
};
