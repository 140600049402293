import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalContent } from "semantic-ui-react";
import { IoCloseSharp } from "react-icons/io5";
import IconLibrary from "./IconLibrary";
import TagsBar from "../../layout/TagsBar/TagsBar";
import store from "../../redux/store";
import { getIconLibraryContent } from "../../api/api";
import parse from "html-react-parser";
import isEmpty from "../../utils/isEmpty";
import { withRouter } from "react-router-dom";

const IconLibraryModal = (props) => {
	let darkMode = useSelector((state) => state.settings.darkMode);
	let iconModalOpen = useSelector((state) => state.world.iconsLibraryModal);
	const [info, setInfo] = useState("");
	const [redirectUrl, setRedirectUrl] = useState("");

	useEffect(()=>{
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({'piki_page':'tags'})
	  },[props.history])  

	useEffect(() => {
		getIconLibraryContent("")
			.then((res) => {
				if (res.response.ok) {
					setInfo(res.json.content);
					setRedirectUrl(res.json.url);
				}
			})
			.catch((err) => {
				//   handle error
			});
	}, []);

	return (
		<Modal
			onClose={() => {
				store.dispatch({ type: "SET_ICONS_LIBRARY_MODAL", payload: false });
			}}
			onOpen={() => {
				store.dispatch({ type: "SET_ICONS_LIBRARY_MODAL", payload: true });
			}}
			open={iconModalOpen}
			className={darkMode ? "icon-library-modal-dark" : "icon-library-modal-light"}
			style={{ backgroundColor: darkMode ? "#3C3C3C" : "#EDF0F5" }}
		>
			<ModalContent style={{ backgroundColor: darkMode ? "#3c3c3c" : "#edf0f5", padding: "0px" }}>
				<div className="subs-close" style={{ marginTop: "15px" }}>
					<IoCloseSharp
						onClick={() => {
							store.dispatch({ type: "SET_ICONS_LIBRARY_MODAL", payload: false });
						}}
					/>
				</div>
				<div className="icon-modal-content">
					{/* icon library content */}
					<IconLibrary />
				</div>
				{/* icon library info dialog */}
				{!isEmpty(redirectUrl) && (
					<div className="info-dialog" style={{ backgroundColor: darkMode ? "#3c3c3c" : "#edf0f5" }}>
						<div
							className="info-dialog__container"
							onClick={() => {
								window.open(redirectUrl, "_blank");
							}}
						>
							<span style={{ color: darkMode ? "gray" : "black" }}>{parse(info)}</span>
						</div>
					</div>
				)}
			</ModalContent>
		</Modal>
	);
};

export default withRouter(IconLibraryModal);
